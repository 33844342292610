import { LifeCycleStage, ProductClass } from '../../../../api';

interface Props {
  stage: LifeCycleStage;
  productClass: string;
}

export const LifecycleStagePlaceholder = (props: Props) =>
  ({
    [LifeCycleStage.RawMaterials]:
      props.productClass === ProductClass.Food ? (
        <>Add all the ingredients and packaging you source to make your product. As many as you need.</>
      ) : (
        <>Add all the materials you source to make your product. As many as you need.</>
      ),
    [LifeCycleStage.Production]: <>Where is your product getting produced? Whether it’s all happening in one place or more, add it in!</>,
    [LifeCycleStage.Distribution]: (
      <>Once your final product is ready for distribution, what are the various stops it makes on its way to the end consumer?</>
    ),
    [LifeCycleStage.Use]: (
      <>
        The region where your product gets consumed affects its impact. Add the area you want to assess, or even several if your product is
        sold in various locations!
      </>
    ),
    [LifeCycleStage.EndOfLife]: (
      <>
        End of life impact only considers the disposal of packaging. As such, elements will automatically appear when you add packaging as
        raw materials.
      </>
    ),
  }[props.stage]);
