import { ModalForm, ModalFormApi, ModalFormSaveCallback } from '../../../../components/ModalForm';
import { forwardRef, Fragment, PropsWithChildren, RefObject, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { ModalHeaderRightBar } from './ModalHeaderRightBar';
import { Field, FieldArrayRenderProps, FieldProps, FormikContextType, useFormikContext } from 'formik';
import {
  Amount,
  Entity,
  getSuppliers,
  MaterialNode,
  MaterialSupplierNode,
  NodeType,
  PackagingNode,
  PackagingNodeMaterial,
  PackagingSupplierNode,
  ProductState,
  ProductV3,
  Supplier,
  SupplierService,
} from '../../../../api';
import {
  adjustSupplierSplits,
  commentSchema,
  getProductionFacilities,
  is100Percent,
  newId,
  newNodeId,
  shouldAutoAdjustSplit,
  StepInputAmountSideEffect,
  UpdateSideEffects,
} from './dataModel';
import { TaggableField, TaggableFieldsContainer, TouchedAwareFieldArray } from './TaggableFields';
import { SelectV3 } from '../../../../components/SelectV3';
import { useLists } from '../../../../hooks/useLists';
import { UnitInputV3 } from '../../../../components/UnitInputV3';
import { InputV3 } from '../../../../components/InputV3';
import { NewSupplierForm } from '../../Manage/Suppliers/NewSupplierForm';
import { SelectFooterAddButton } from '../../../../components/SelectFooterAddButton';
import { DefaultBadge, renderBadges } from './Badges';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useEffectOnNextRenders } from '../../../../hooks/useEffectOnNextRenders';
import cloneDeep from 'lodash/cloneDeep';
import { LocationSelect } from './LocationSelect';
import { useId } from '@floating-ui/react-dom-interactions';
import { ExtractedData } from './ExtractedData';
import * as yup from 'yup';
import uniqBy from 'lodash/uniqBy';

const toSupplierOption = (supplier: Supplier, config?: { noId?: boolean }): SupplierNode => ({
  id: config?.noId ? '' : newNodeId(),
  type: NodeType.MaterialSupplier,
  displayName: supplier.name,
  flagged: false,
  edges: new Array<string>(),
  supplier,
  location: null as any as Entity,
  splitPercent: null as any as number,
  autoAdjustSplit: true,
});

interface SupplierNode extends MaterialSupplierNode {
  autoAdjustSplit: boolean;
}

type Props = PropsWithChildren<{
  modalRef?: RefObject<ModalFormApi>;
  data?: MaterialNode;
  onSave: ModalFormSaveCallback<MaterialNode, { sideEffects: UpdateSideEffects }>;
  onOpenChange?: (open: boolean) => void;
}>;

export const MaterialDetails = (props: Props) => {
  const formRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext<ProductV3>();
  const bodyRef = useRef<BodyApi>(null);

  return (
    <ModalForm<Partial<MaterialNode>>
      size='wide'
      title={props.data ? `${props.data.amount.value}g of ${props.data.displayName}` : 'New material'}
      entityName='material'
      ref={props.modalRef}
      formRef={formRef}
      onSave={({ values, ...rest }) => {
        props.onSave({
          values: values as MaterialNode,
          sideEffects: { stepInputAmounts: bodyRef.current!.getSideEffects() },
          ...rest,
        });
      }}
      body={<Body productFormik={formik} ref={bodyRef} formRef={formRef} edit={!!props.data} />}
      onOpenChange={props.onOpenChange}
      metadata={formik.values.metadata}
      headerRight={<ModalHeaderRightBar />}
      instructions={
        <div className='flex flex-col gap-4 p-2'>
          <div>
            What material(s) is your packaging product made out of? Select the material category and its sub-type, choosing between their
            virgin and recycled counterparts, specify the amount you are procuring, as well as which supplier(s) you’re getting it from.
          </div>
          <div>
            We know that you don’t always get raw materials from the same supplier year round so here you can specify what we call the
            split, or the percentage of time you got this material from one supplier versus another when looking 3 years back.
          </div>
          <div>
            On the other hand, if you always get a different amount of the packaging from different suppliers, just add it again in the
            graph, as many times as you need.
          </div>
        </div>
      }
      saveLabel={formik.values.state === ProductState.Complete ? 'Confirm changes' : undefined}
      getCustomErrors={() => []}
      validationSchema={yup.object({
        material: yup
          .object(commentSchema())
          .shape({
            subType: yup.object().when('placeholder', {
              is: true,
              otherwise: (schema) => schema.required(),
            }),
            comment: yup.string().when(['placeholder', 'subType.placeholder'], {
              is: (placeholder: boolean, subTypePlaceholder: boolean) => placeholder || subTypePlaceholder,
              then: (schema) => schema.required(),
            }),
          })
          .required(),
        amount: yup.object().shape({
          value: yup.number().required().positive(),
        }),
        nodes: yup
          .array()
          .min(1)
          .of(
            yup.object().shape({
              location: yup.object().required(),
              splitPercent: yup.number().positive().max(100).required(),
            }),
          )
          .test('', 'splitsNot100', function () {
            const parent = this.parent as PackagingNode;
            return (
              parent.nodes.length === 0 ||
              (parent.nodes.every(({ splitPercent }) => typeof splitPercent === 'number') &&
                is100Percent(parent.nodes.map(({ splitPercent }) => splitPercent)))
            );
          }),
      })}
      emptyData={{
        id: newNodeId(),
        displayName: '',
        type: NodeType.Material,
        flagged: false,
        edges: new Array<string>(),
        nodes: [],
        material: null,
        amount: null as any as Amount,
      }}
      data={
        props.data
          ? {
              ...props.data,
              nodes: props.data.nodes.map((node) => ({
                ...node,
                autoAdjustSplit: shouldAutoAdjustSplit(props.data!, node, formik, 'nodes', 'splitPercent'),
              })),
            }
          : undefined
      }
    >
      {props.children}
    </ModalForm>
  );
};

interface BodyProps {
  productFormik: FormikContextType<ProductV3>;
  formRef: RefObject<HTMLDivElement>;
  edit: boolean;
}

interface BodyApi {
  getSideEffects: () => StepInputAmountSideEffect[];
}

const Body = forwardRef<BodyApi, PropsWithChildren<BodyProps>>((props, ref) => {
  const lists = useLists();
  const formik = useFormikContext<MaterialNode>();
  const originalAmountValue = useRef(formik.values.amount?.value);
  const updateStepInputCheckboxId = useId();
  const [updateStepInput, setUpdateStepInput] = useState(true);
  const [showSupplierForm, setShowSupplierForm] = useState(false);
  const [newSupplierName, setNewSupplierName] = useState('');
  const [materialFinalName, setMaterialFinalName] = useState('New material');

  console.log(formik.errors);

  const getSubTypes = (materialId: string) =>
    lists.materialSubTypes.filter((subType) =>
      lists.packagingProductMaterials.find(({ id }) => id === materialId)!.materialSubTypes.includes(subType.id),
    );

  const getSingleStepInputUsingPackaging = () => {
    const inputs = getProductionFacilities(props.productFormik as unknown as FormikContextType<ProductV3>)
      .flatMap(({ steps }) => steps)
      .flatMap((step) => step.inputs.map((input) => ({ step, input })))
      .filter(({ input: { id } }) => id === formik.values.id);
    return inputs.length === 1 ? inputs[0] : undefined;
  };

  const canUpdateStepInput =
    props.edit &&
    typeof originalAmountValue.current === 'number' &&
    typeof formik.values.amount?.value === 'number' &&
    formik.values.amount.unit &&
    originalAmountValue.current !== formik.values.amount?.value &&
    getSingleStepInputUsingPackaging();

  useEffect(() => {
    if (formik.values.displayName) {
      setMaterialFinalName(formik.values.displayName);
    } else {
      setMaterialFinalName(formik.values.material?.subType?.name ?? 'New material');
    }
  }, [formik.values.displayName, formik.values.material?.subType?.name]);

  useImperativeHandle(ref, () => ({
    getSideEffects: () => {
      if (canUpdateStepInput && updateStepInput) {
        const { step, input } = getSingleStepInputUsingPackaging()!;
        return [{ stepId: step.id, inputId: input.id, value: formik.values.amount.value }];
      }

      return [];
    },
  }));

  useEffectOnNextRenders(() => {
    formik.setValues((values) => {
      const newValues = cloneDeep(values);
      delete newValues.index;
      newValues.displayName = '';
      return newValues;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.material?.id]);

  useEffectOnNextRenders(() => {
    if ((formik.values.nodes as SupplierNode[]).some(({ autoAdjustSplit }) => autoAdjustSplit)) {
      adjustSupplierSplits(formik);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.nodes.length]);

  useEffectOnNextRenders(() => {
    formik.setValues((values) => {
      const newValues = cloneDeep(values);
      delete newValues.index;
      newValues.displayName = '';
      newValues.material = null;
      return newValues;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.id]);

  return (
    <TaggableFieldsContainer pathPrefix='nodes'>
      <ExtractedData {...props} />
      <div className='flex flex-col gap-y-6'>
        <div className='grid grid-cols-3 gap-4'>
          <div className='flex flex-col gap-1'>
            <div className='pl-1.5'>Material</div>
            <Field name='material'>
              {(model: FieldProps<PackagingNodeMaterial>) => (
                <SelectV3
                  model={model}
                  menuPortalTarget={props.formRef.current}
                  options={uniqBy(lists.packagingProductMaterials, 'name').map(
                    (material) =>
                      ({
                        ...material,
                        id: newId('id'),
                        materialId: material.id,
                        ...(getSubTypes(material.id).length === 1 && !material.placeholder ? { subType: getSubTypes(material.id)[0] } : {}),
                        compositionPercent: null as any as number,
                        autoAdjustSplit: true,
                      } as PackagingNodeMaterial),
                  )}
                />
              )}
            </Field>
          </div>

          {!formik.values.material?.placeholder && (
            <div className='flex flex-col gap-1'>
              <div className='pl-1.5'>Type</div>
              <div>
                <Field name='material.subType'>
                  {(model: FieldProps<Entity>) => (
                    <SelectV3
                      model={model}
                      menuPortalTarget={props.formRef.current}
                      options={formik.values.material?.materialId ? getSubTypes(formik.values.material?.materialId) : undefined}
                    />
                  )}
                </Field>
              </div>
            </div>
          )}
          {formik.values.material?.placeholder && (
            <div className='flex flex-col gap-1'>
              <div className='pl-1.5'>Comment for type</div>
              <div className='flex flex-col'>
                <Field name='material.comment'>
                  {(model: FieldProps<string>) => <InputV3 model={model} placeholder='Describe the type…' />}
                </Field>
              </div>
            </div>
          )}

          <div className='flex flex-col gap-1'>
            <div className='pl-1.5'>Amount</div>
            <div>
              <Field name='amount.value'>
                {(model: FieldProps<number>) => (
                  <UnitInputV3
                    model={model}
                    unit={{
                      options: [{ id: '', name: 'g' }],
                    }}
                  />
                )}
              </Field>
            </div>
          </div>

          {canUpdateStepInput && (
            <div className='col-span-3 flex gap-2 ml-2'>
              <input
                id={updateStepInputCheckboxId}
                type='checkbox'
                checked={updateStepInput}
                onChange={() => setUpdateStepInput((value) => !value)}
              />
              <label htmlFor={updateStepInputCheckboxId} className='select-none'>
                Automatically change input amount of the production step ({getSingleStepInputUsingPackaging()!.step.displayName}) to{' '}
                {formik.values.amount!.value}
                {formik.values.amount!.unit.name}
              </label>
            </div>
          )}

          <div className='col-span-3 flex flex-col gap-1'>
            <div className='pl-1.5'>Display name</div>
            <div className='flex flex-col'>
              <Field name='displayName'>
                {(model: FieldProps<string>) => (
                  <InputV3 model={model} placeholder={formik.values.material?.subType?.name ?? 'Defaults to type name if left empty…'} />
                )}
              </Field>
            </div>
          </div>
        </div>

        <div className='flex flex-col gap-y-4 p-4 rounded-2xl shadow-[0px_0px_10px_0px_rgba(0,0,0,0.15)] border border-zinc-200'>
          <div className='flex flex-col gap-y-2'>
            <div className='flex gap-2 items-center'>
              <div className='flex items-center justify-center size-6 bg-yellow-50 rounded'>
                <FontAwesomeIcon className='text-yellow-900' icon={duotone('sheet-plastic')} />
              </div>
              <div className='font-semibold'>{materialFinalName}</div>
            </div>
            <div>
              Please fill out the following details by specifying the supplier(s) of this material. You can also create a new supplier by
              selecting the option “create new” from the suppliers list.
            </div>
          </div>

          <TouchedAwareFieldArray name='nodes'>
            {(arrayModel) => (
              <>
                <div className='col-span-2 flex flex-col gap-1'>
                  <div className='pl-1.5'>Suppliers</div>
                  <Field name={arrayModel.name}>
                    {(model: FieldProps<MaterialSupplierNode[]>) => (
                      <SelectV3<MaterialSupplierNode>
                        multi
                        multiRepeated
                        model={model}
                        getOptionValue={({ supplier }) => supplier.id}
                        getOptionLabel={({ supplier }) => supplier.name}
                        menuPortalTarget={props.formRef.current}
                        loadOptions={(input, callback) => {
                          setNewSupplierName(input);
                          getSuppliers({
                            contains: input,
                            service: SupplierService.Packaging,
                          }).ok(({ suppliers }) => callback(suppliers.map((supplier) => toSupplierOption(supplier, { noId: true }))));
                        }}
                        adjustChange={(value: PackagingSupplierNode[]) =>
                          value.map((option) => ({ ...option, id: option.id || newNodeId() }))
                        }
                        renderOptionBadge={({ supplier }) => (supplier.default ? <DefaultBadge /> : <></>)}
                        menuFooter={
                          !showSupplierForm && (
                            <SelectFooterAddButton onClick={() => setShowSupplierForm(true)} name={newSupplierName} label='new provider' />
                          )
                        }
                      />
                    )}
                  </Field>
                </div>
                {showSupplierForm && (
                  <div className='bg-[#F5F7FA] col-span-2 p-3 rounded-xl shadow-regular mt-3'>
                    <NewSupplierForm
                      name={newSupplierName}
                      formRef={props.formRef}
                      requiredServices={[SupplierService.Packaging]}
                      onCancel={() => setShowSupplierForm(false)}
                      onCreated={(newSupplier) => {
                        formik.setFieldValue('nodes', [...formik.values.nodes, toSupplierOption(newSupplier)]);
                        setShowSupplierForm(false);
                      }}
                    />
                  </div>
                )}
                {formik.values.nodes.length > 0 && (
                  <div className='flex flex-col gap-y-2'>
                    <div className='grid grid-cols-3 gap-x-4 gap-y-2 divide-y'>
                      <div className='col-span-3 grid grid-cols-subgrid gap-x-4 px-2'>
                        <div>Supplier</div>
                        <div>Country</div>
                        <div>Split</div>
                      </div>
                      <div className='col-span-3 grid grid-cols-subgrid gap-x-4 gap-y-2 py-2 bg-slate-50 px-2'>
                        {formik.values.nodes.map((node, index) => (
                          <SupplierRow key={node.id} index={index} data={node as SupplierNode} arrayModel={arrayModel} {...props} />
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </TouchedAwareFieldArray>
        </div>
      </div>
    </TaggableFieldsContainer>
  );
});

const SupplierRow = (
  props: {
    index: number;
    data: SupplierNode;
    arrayModel: FieldArrayRenderProps;
  } & BodyProps,
) => {
  const formik = useFormikContext<PackagingNode>();
  const splitPercentRef = useRef<HTMLInputElement>(null);

  useEffectOnNextRenders(() => {
    if (document.activeElement === splitPercentRef.current) {
      adjustSupplierSplits(formik, props.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.splitPercent]);

  return (
    <Fragment key={props.data.id}>
      <Field name={`${props.arrayModel.name}.${props.index}.supplier`}>
        {(model: FieldProps<Entity>) => (
          <div className='flex flex-col gap-y-1.5' title={model.field.value.name}>
            <SelectV3
              model={model}
              menuPortalTarget={props.formRef.current}
              loadOptions={(input, callback) => {
                getSuppliers({
                  contains: input,
                  service: SupplierService.Packaging,
                }).ok(({ suppliers }) => callback(suppliers));
              }}
              noClear
              hideInputBorder
            />
            {props.data.supplier.default ? (
              <div className='flex'>
                <DefaultBadge />
              </div>
            ) : (
              renderBadges(props.data, props.productFormik as any as FormikContextType<ProductV3>, { target: 'supplier/id' })
            )}
          </div>
        )}
      </Field>
      <LocationSelect
        noLabel
        name={`${props.arrayModel.name}.${props.index}.location`}
        includeOption={(option) =>
          !formik.values.nodes
            .filter(({ id }) => id !== props.data.id)
            .filter(({ supplier }) => supplier.id === props.data.supplier.id)
            .some((node) => node?.location?.id === option.id)
        }
        formRef={props.formRef}
      />
      <div className='grid grid-cols-[2fr_1fr]'>
        <TaggableField name={`${props.arrayModel.name}.${props.index}.splitPercent`} card>
          {(model: FieldProps<number>) => (
            <UnitInputV3 inputRef={splitPercentRef} model={model} unit={{ options: [{ id: '', name: '%' }] }} />
          )}
        </TaggableField>
        <button
          type='button'
          className='flex justify-self-end justify-center items-center rounded-sm size-8'
          onClick={props.arrayModel.handleRemove(props.index)}
        >
          <FontAwesomeIcon className='text-base text-zinc-400 p-2' icon={regular('times')} />
        </button>
      </div>
    </Fragment>
  );
};
