import { Facility, Grade, OwnershipStatusType, Pagination, request, SharedProduct, Supplier } from '.';
import { Amount, CreateResponse, Entity } from './shared';

export interface ListKey {
  key?: string;
}

export interface Process extends Entity {
  type: ProcessType;
}

export interface ProductsPagination<T> extends Pagination {
  products: T;
}

export enum ProcessType {
  Industrial = 'industrial',
  Household = 'household',
  PackagingProduction = 'packaging_production',
}

export enum ProductState {
  Draft = 'draft',
  Complete = 'complete',
}

export enum Scope {
  Customer = 'customer',
  Workspace = 'workspace',
}

export enum ProductType {
  Final = 'final_product',
  Intermediate = 'intermediate_product',
  Internal = 'internal_product',
}

export enum ProductStage {
  Development = 'development',
  Production = 'production',
}

export enum Tag {
  Default = 'default',
  ExactMatch = 'exact_match',
  BestMatch = 'best_match',
  LowQualityMatch = 'low_quality_match',
  Unmatched = 'unmatched',
  UserInput = 'user_input',
}

export const visibleExtractedDataTags = [Tag.ExactMatch, Tag.BestMatch, Tag.LowQualityMatch, Tag.Unmatched];

export const extractedDataTags = [...visibleExtractedDataTags, Tag.UserInput];

export const bulkRemovableTags = [Tag.ExactMatch, Tag.BestMatch, Tag.LowQualityMatch, Tag.Default];

export const visibleTags = [...bulkRemovableTags, Tag.Unmatched];

export enum ProductWarning {
  InternalDraft = 'product-draft-internal',
}

export enum ProductClass {
  Food = 'food',
  Packaging = 'packaging',
}

export interface ProductGeneral extends Entity {
  author: string;
  state: ProductState;
  productType: ProductType;
  stage: ProductStage;
  reportCalculable: boolean;
  skuId: string;
  gtin?: string;
  amount: Amount;
  bruttoAmount: Amount;
  economicValue?: EconomicValue | null;
  servings?: number | null;
  category?: Entity /* optional on internal/intermediate product type */;
  foodType: StaticEntity;
  imageUrl: string | null;
  updatedAt: string;
  firstPartyDataPercentage: number;
  totalImpact: number;
  totalImpactNormalised: number;
  totalGrade: Grade | '';
  impactPoints: number;
  impactPointsNormalised: number;
  createdAt: string;
  modelCount: number;
  rawToCookedRatio: number;
  workspaceSid: string;
  warnings: { id: ProductWarning }[];
  stats: {
    countsByLifeCycleStage: { stage: LifeCycleStage; count: number }[];
  };
}

export interface ValidationItem {
  nodeId?: string;
  text: string;
  showAlways?: boolean;
}

export interface PackagingProduct extends Entity {
  placeholder: false;
  //packagingMaterials: string[];
  //packagingProcesses: string[];
}

export interface ProductV3 extends ProductGeneral {
  productClass: ProductClass;
  packagingType?: PackagingProduct;
  material: MaterialNode;
  conservation: {
    requirement: Entity;
  };
  layoutGrids: {
    production: Size;
    distribution: Size;
  };
  nodes: PrimaryNode[];
  validation: {
    errors: ValidationItem[];
    warnings: ValidationItem[];
  };
  metadata: Metadata;
}

export interface Metadata {
  system: MetadataItem[];
  user: MetadataItem[];
}

export interface MetadataItem {
  path: string;
  tags: Tag[];
  mappingId?: string;
}

export enum NodeType {
  Material = 'material' /* new one */,
  MaterialSupplier = 'material_supplier' /* new one */,
  Transport = 'transport',
  Ingredient = 'ingredient',
  IngredientSupplier = 'ingredient_supplier',
  Packaging = 'packaging',
  PackagingSupplier = 'packaging_supplier',
  Production = 'production',
  ProductionIngredient = 'production_ingredient',
  ProductionPackaging = 'production_packaging',
  ProductionMaterial = 'production_material' /* new one */,
  ProductionWarehouse = 'production_warehouse',
  Warehouse = 'warehouse',
  Store = 'store',
  FinalDestination = 'final_destination',
  Consumption = 'consumption',
  ConsumptionPreparation = 'consumption_preparation',
  Disposal = 'disposal',
}

const nodeTypes = [
  NodeType.Transport,
  NodeType.Material,
  NodeType.MaterialSupplier,
  NodeType.Ingredient,
  NodeType.IngredientSupplier,
  NodeType.Packaging,
  NodeType.PackagingSupplier,
  NodeType.Production,
  NodeType.ProductionIngredient,
  NodeType.ProductionPackaging,
  NodeType.ProductionWarehouse,
  NodeType.Warehouse,
  NodeType.Store,
  NodeType.FinalDestination,
  NodeType.Consumption,
  NodeType.ConsumptionPreparation,
  NodeType.Disposal,
];

export enum LifeCycleStage {
  RawMaterials = 'raw_materials',
  Production = 'production',
  Distribution = 'distribution',
  Use = 'use',
  EndOfLife = 'end_of_life',
}

export const getLifeCycleForNodeType = (type: NodeType) => {
  switch (type) {
    case NodeType.Material:
    case NodeType.MaterialSupplier:
    case NodeType.Ingredient:
    case NodeType.IngredientSupplier:
    case NodeType.Packaging:
    case NodeType.PackagingSupplier:
      return LifeCycleStage.RawMaterials;
    case NodeType.Production:
    case NodeType.ProductionIngredient:
    case NodeType.ProductionPackaging:
    case NodeType.ProductionWarehouse:
      return LifeCycleStage.Production;
    case NodeType.Warehouse:
    case NodeType.Store:
    case NodeType.FinalDestination:
      return LifeCycleStage.Distribution;
    case NodeType.Consumption:
    case NodeType.ConsumptionPreparation:
      return LifeCycleStage.Use;
    case NodeType.Disposal:
      return LifeCycleStage.EndOfLife;
    default:
      throw new Error(`Unsupported: ${type}`);
  }
};

export const getNodeTypesForLifecycleStage = (stage: LifeCycleStage) =>
  nodeTypes.filter((type) => type !== NodeType.Transport).filter((type) => getLifeCycleForNodeType(type) === stage);

export const facilityNodeTypes = [
  ...getNodeTypesForLifecycleStage(LifeCycleStage.Production),
  ...getNodeTypesForLifecycleStage(LifeCycleStage.Distribution),
  ...getNodeTypesForLifecycleStage(LifeCycleStage.Use),
];

export const storageNodeTypes = [NodeType.ProductionWarehouse, NodeType.Warehouse, NodeType.Store];

export enum OutputType {
  IntermediateProduct = 'intermediate_product',
  ByProduct = 'by_product',
  CoProduct = 'co_product',
  Emission = 'emission',
  Waste = 'waste',
}

export enum WasteType {
  Solid = 'solid',
  Liquid = 'liquid',
  Packaging = 'packaging',
}

export enum WasteSubType {
  Wastewater = 'wastewater',
}

export enum AgreementType {
  Direct = 'direct',
  Indirect = 'indirect',
}

export interface Position {
  x: number;
  y: number;
}

export interface Size {
  width: number;
  height: number;
}

export interface EconomicValue {
  price: number;
  currency: Entity;
}

interface NodeBase<T extends NodeType> {
  id: string;
  type: T;
  index?: number;
  displayName: string;
  flagged: boolean;
}

export type GenericNode = NodeBase<NodeType>;

interface NodeWithEdgesBase<T extends NodeType> extends NodeBase<T> {
  edges: string[];
}

export type GenericNodeWithEdges = NodeWithEdgesBase<NodeType>;

interface FacilityNodeBase<T extends NodeType> extends NodeWithEdgesBase<T> {
  facility: FacilityWithSelectedAgreement;
}

export type GenericFacilityNode = FacilityNodeBase<NodeType>;

interface MovableFacilityNodeBase<T extends NodeType> extends FacilityNodeBase<T> {
  layoutPosition?: Position;
}

export type GenericMovableFacilityNode = MovableFacilityNodeBase<NodeType>;

interface FacilityWithStepsNodeBase<T extends NodeType, S extends StepNodeBase<NodeType>> extends MovableFacilityNodeBase<T> {
  layoutGrid: Size;
  steps: S[];
}

export type GenericFacilityWithStepsNode = FacilityWithStepsNodeBase<NodeType, StepNodeBase<NodeType>>;

interface SupplierNodeBase<T extends NodeType> extends NodeWithEdgesBase<T> {
  facility?: Facility;
  ingredientId?: string;
  supplier: Supplier;
  location: Entity;
  splitPercent: number;
}

export type GenericSupplierNode = SupplierNodeBase<NodeType>;

interface RawMaterialNodeBase<T extends NodeType, SN extends SupplierNodeBase<NodeType>> extends NodeWithEdgesBase<T> {
  amount: Amount;
  nodes: SN[];
}

export type GenericRawMaterialNode = RawMaterialNodeBase<NodeType, SupplierNodeBase<NodeType>>;

export interface LiquidWaste extends StaticEntity<WasteType.Liquid> {
  subType: StaticEntity<WasteSubType>;
}

export interface SolidWaste extends StaticEntity<WasteType.Solid> {
  destination: StaticEntity;
}

export interface PackagingWaste extends StaticEntity<WasteType.Packaging> {
  destination: StaticEntity;
  packaging: Entity;
}

export interface StepOutput {
  id: string;
  outputType: StaticEntity<OutputType>;
  name: string;
  amount?: Amount;
  economicValue?: EconomicValue;
  emission?: Entity & {
    destination: StaticEntity;
    subType: StaticEntity;
  };
  waste?: LiquidWaste | SolidWaste | PackagingWaste;
}

export interface StepInput extends NodeReference {
  item?: EntityWithUnit;
}

interface StepNodeBase<T extends NodeType> extends NodeBase<T> {
  process: ProductionProcess;
  inputs: StepInput[];
  outputs: StepOutput[];
  finalStep: boolean;
  layoutPosition?: Position;
}

export type GenericStepNode = StepNodeBase<NodeType>;

export type RawMaterialNode = IngredientNode | PackagingNode | MaterialNode;

export type PrimaryNode = TransportNode | RawMaterialNode | FacilityNode | DisposalNode;

export type PrimaryOrMaterialSupplierNode = PrimaryNode | RawMaterialSupplierNode;

export type RawMaterialSupplierNode = IngredientSupplierNode | PackagingSupplierNode | MaterialSupplierNode;

export type StorageNode = ProductionWarehouseNode | WarehouseNode | StoreNode;

export type FacilityNode = ProductionNode | StorageNode | FinalDestinationNode | ConsumptionNode;

export type ProductionStepNode = ProductionIngredientNode | ProductionPackagingNode | ProductionMaterialNode;

export type ConsumptionStepNode = ConsumptionPreparationNode;

export type StepNode = ProductionStepNode | ConsumptionStepNode;

export interface StaticEntity<T = string> {
  type: T;
  name: string;
}

export interface StaticEntityWithUnit<T = string> extends StaticEntity<T> {
  unit: Entity;
}

export interface Agreement extends StaticEntity<AgreementType> {
  inherited?: boolean;
}

export interface EntityWithUnit extends Entity {
  unit: Entity;
}

interface ObjectWithSelectedAgreement {
  selectedAgreement?: Agreement & { inherited: boolean };
}

export interface EntityWithSelectedAgreement extends Entity, ObjectWithSelectedAgreement {}

export interface FacilityWithSelectedAgreement extends Facility, ObjectWithSelectedAgreement {}

export interface SupplierWithSelectedAgreement extends Supplier, ObjectWithSelectedAgreement {}

export interface NodeReference {
  id: string;
  amountValue: number;
}

export interface Value {
  value: number;
}

export interface TransportNode extends NodeWithEdgesBase<NodeType.Transport> {
  conservation: Entity;
  supplier: SupplierWithSelectedAgreement;
  legs: TransportLeg[];
  items: NodeReference[];
}

export interface TransportLeg {
  id: string;
  mode: Entity;
  supplier: Entity;
  distance: number;
}

export type MaterialNode = RawMaterialNodeBase<NodeType.Material, MaterialSupplierNode> & {
  material: (PackagingNodeMaterial & { displayName: string }) | null;
  localSupply: boolean;
};

export interface MaterialSupplierNode extends SupplierNodeBase<NodeType.MaterialSupplier> {}

// Ingredient

export interface IngredientNode extends RawMaterialNodeBase<NodeType.Ingredient, IngredientSupplierNode> {
  ingredient: IngredientV3;
  localSupply: boolean;
}

export interface IngredientSupplierNode extends SupplierNodeBase<NodeType.IngredientSupplier> {}

// Packaging

export interface PackagingNode extends RawMaterialNodeBase<NodeType.Packaging, PackagingSupplierNode> {
  packaging: Entity & {
    conservation: Entity;
  };
  materials: PackagingNodeMaterial[];
}

export interface PackagingNodeMaterial extends Entity {
  materialId: string;
  subType: Entity;
  compositionPercent: number;
}

export interface PackagingSupplierNode extends SupplierNodeBase<NodeType.PackagingSupplier> {}

// Production

export interface ProductionNode extends FacilityWithStepsNodeBase<NodeType.Production, ProductionStepNode> {
  finalFacility: boolean;
}

export interface ProductionIngredientNode extends StepNodeBase<NodeType.ProductionIngredient> {}

export interface ProductionPackagingNode extends StepNodeBase<NodeType.ProductionPackaging> {}

export interface ProductionMaterialNode extends StepNodeBase<NodeType.ProductionMaterial> {}

export interface ProductionProcessAuxiliary extends Value, StaticEntity {}

export interface ProductionProcessElectricityType extends StaticEntity {
  percent: number;
}

export interface ProductionProcess extends Entity {
  overrides: {
    electricity: Value & {
      types: ProductionProcessElectricityType[];
    };
    gas: Value;
    water: {
      input: Value;
      output: Value;
    };
    auxiliaries: ProductionProcessAuxiliary[];
  };
}

export interface StoredItem {
  id: string;
  name: string;
  type: string;
  conservation: Entity;
  durationWeeks: number;
  amount: Amount;
}

interface StoresItems {
  items: StoredItem[];
}

export interface ProductionWarehouseNode extends MovableFacilityNodeBase<NodeType.ProductionWarehouse>, StoresItems {}

// Distribution

export interface WarehouseNode extends MovableFacilityNodeBase<NodeType.Warehouse>, StoresItems {}

export interface StoreNode extends MovableFacilityNodeBase<NodeType.Store>, StoresItems {}

export interface FinalDestinationNode extends MovableFacilityNodeBase<NodeType.FinalDestination> {}

// Use

export interface ConsumptionNode extends FacilityWithStepsNodeBase<NodeType.Consumption, ConsumptionStepNode> {}

export interface ConsumptionPreparationNode extends StepNodeBase<NodeType.ConsumptionPreparation> {}

// Disposal

export interface DisposalNode extends NodeWithEdgesBase<NodeType.Disposal> {
  disposalType: StaticEntity;
  packagingNodeId: string;
}

export interface RevisionPreview {
  author: string;
  comment: string;
  createdAt: string;
  id: string;
  revision: number;
}

export interface Revision extends RevisionPreview {
  product: ProductV3;
}

export interface SearchProductsParams {
  pageSize?: number;
  pageToken?: string;
  state?: ProductState;
  type?: ProductType;
  stage?: ProductStage;
  sortBy: 'name' | 'amount' | 'updatedAt' | 'firstPartyDataPercentage' | 'totalImpact';
  sortAscending: boolean;
  contains: string;
  actualEligible?: boolean;
  forecastEligible?: boolean;
  scope?: Scope /* todo: optional for backwards compatibility*/;
}

enum ProductDependencyType {
  DownstreamDirect = 'downstream_terminal',
  UpstreamDirect = 'upstream_terminal',
}

export interface DependentProduct {
  dependencyType: ProductDependencyType;
  productModel: boolean;
  id: string;
  name: string;
  type: ProductType.Final;
  netAmount: string;
  skuId: string;
  workspaceSid: string;
  reportCalculable: boolean;
  parentId?: string;
}

export interface PaginatedProductDependencies extends Pagination {
  dependencies: DependentProduct[];
}

export const getProductDependencies = (id: string, payload: { [key: string]: string | number }) =>
  request<PaginatedProductDependencies>('GET', `/v3/products/${id}/dependencies`, { search: payload });

export const getSharedSupplierProducts = (id: string, payload: { [key: string]: string | number }) =>
  request<ProductsPagination<SharedProduct[]>>('GET', `/v3/suppliers/${id}/products`, { search: payload });

export const getSharedFacilityProducts = (id: string, payload: { [key: string]: string | number }) =>
  request<ProductsPagination<SharedProduct[]>>('GET', `/v3/facilities/${id}/products`, { search: payload });

export const searchProducts = (search: SearchProductsParams, workspaceSid?: string | null) =>
  request<ProductsPagination<ProductGeneral[]>>('GET', '/v3/products', { search: search, workspaceSid });

export const getProductV3 = (id: string) => request<ProductV3>('GET', `/v3/products/${id}`);
export const createProductV3 = (payload: Partial<ProductV3>) =>
  request<CreateResponse<ProductV3>>('POST', '/v3/products', { body: payload });
export const updateProductV3 = (payload: ProductV3) => request<ProductV3>('PUT', `/v3/products/${payload.id}`, { body: payload });
export const saveProductV3 = (payload: ProductV3, workspaceSid?: string | null) =>
  request<ProductV3>('POST', `/v3/products/${payload.id}`, { body: payload, workspaceSid });
export const validateProduct = (payload: ProductV3) => request<ProductV3>('POST', '/v3/product-validation', { body: payload });
export const importProductDistribution = (id: string, payload: { product: ProductV3; fromProductId: string }) =>
  request<ProductV3>('POST', `/v3/products/${id}/import-distribution`, { body: payload });
export const exportProductDistribution = (id: string, payload: { toProductIds: string[] }) =>
  request('POST', `/v3/products/${id}/export-distribution`, { body: payload });
export const deleteProductV3 = (id: string, workspaceSid?: string | null) =>
  request<{
    errorCode: string;
    message: string;
  }>('DELETE', `/v3/products/${id}`, { workspaceSid });
export const duplicateProduct = (id: string, workspaceSid?: string | null) =>
  request<CreateResponse<ProductV3>>('POST', `/v3/products?copyFrom=${id}`, { workspaceSid });

export const getListOfRevisionsV3 = (productId: string, workspaceSid?: string | null) =>
  request<{ revisions: RevisionPreview[] }>('GET', `/v3/products/${productId}/revisions`, { workspaceSid });
export const getRevisionV3 = (productId: string, revisionId: number, workspaceSid?: string | null) =>
  request<Revision>('GET', `/v3/products/${productId}/revisions/${revisionId}`, { workspaceSid });

export const applyDefaults = (id: string) =>
  request<{ product: ProductV3; error?: { code: string; message: string } }>('POST', `/v3/products/${id}/defaults-graph`);

export interface ProductMapping {
  original: { name: string; value: string }[];
  comment?: string;
}

export const getProductMapping = (productId: string, nodeId: string) =>
  request<{ mappings: ProductMapping[] }>('GET', `/v3/products/${productId}/mappings?node=${nodeId}`);

export interface ProcessV3 extends Entity {
  type: ProcessType;
  overrides?: {
    electricity?: {
      unit: Entity;
      types: StaticEntity[];
    };
    gas?: {
      unit: Entity;
    };
    water?: {
      input: {
        unit: Entity;
      };
      output: {
        unit: Entity;
      };
    };
    auxiliaries?: StaticEntityWithUnit[];
  };
  duration?: number;
}

export enum Methodology {
  FoundationEarth = 'foundation_earth',
  Sustained = 'sustained',
}

interface PackagingType extends Entity {
  packagingMaterials: string[];
  packagingProcesses: string[];
}

interface SystemMaterial extends Entity {
  materialSubTypes: string[];
}

export interface Lists {
  packagingTypes: PackagingType[];
  packagingMaterials: SystemMaterial[];
  packagingProductMaterials: SystemMaterial[];
  materialSubTypes: Entity[];
  conservationRequirements: Entity[];
  processes: ProcessV3[];
  transportTypes: Entity[];
  disposalTypes: StaticEntity[];
  outputTypes: StaticEntity<OutputType>[];
  wasteTypes: (StaticEntityWithUnit<WasteType> & {
    subTypes: StaticEntity[];
    destinations: StaticEntity[];
  })[];
  emissionDestinations: (StaticEntity & {
    subTypes?: StaticEntity[];
  })[];
  foodTypes: StaticEntityWithUnit[];
  categories: Entity[];
  units: Entity[];
  currencies: Entity[];
  countries: Entity[];
  regions: Entity[];
  brands: StaticEntity[];
  methodologies: StaticEntity<Methodology>[];
  agreements: StaticEntity<AgreementType>[];
  facilityOwnerStatuses: StaticEntity<OwnershipStatusType>[];
}

export const getLists = () => request<Lists>('GET', '/v3/lists');

export enum IngredientType {
  Generic = 'generic_ingredient',
  Consumer = 'consumer_ingredient',
  IntermediateProduct = 'intermediate_product',
  GroupProduct = 'group_product',
  PrivateProduct = 'private_product',
}

interface RestrictedSupplier {
  supplier: Supplier;
  splitPercent: number;
  location: Entity;
  facility: Facility;
  ingredientId: string;
}

export interface IngredientV3 extends EntityWithUnit {
  type: IngredientType;
  skuId: string;
  conservation: Entity;
  restrictedSuppliers: boolean;
  suppliers?: RestrictedSupplier[];
}

export const searchIngredientsV3 = (input: string, options?: { consumption?: boolean }) =>
  request<{ customer: IngredientV3[]; sustained: IngredientV3[] }>(
    'GET',
    `/v3/ingredients?contains=${input}${options?.consumption ? '&phase=consumption' : ''}`,
  );

export const searchEmissions = (input: string) => request<{ emissions: EntityWithUnit[] }>('GET', `/v3/emissions?contains=${input}`);

export enum CountryBadge {
  SpecificData = 'specific_data',
  SameImpact = 'same_impact',
}

export interface Country extends Entity {
  badge: CountryBadge;
}

export const searchCountries = (referenceCountry?: Entity, referenceIngredient?: Entity) =>
  request<{ countries: Country[] }>('GET', `/v3/countries`, {
    search: { referenceCountry: referenceCountry?.id, referenceIngredient: referenceIngredient?.id },
  });
