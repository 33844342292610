import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { differenceInDays } from 'date-fns';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import * as yup from 'yup';
import {
  BillingModeType,
  createWorkspace,
  Customer,
  defaultRequiredMessage,
  getWorkspace,
  StaticEntity,
  updateWorkspace,
  Workspace,
  WorkspaceProductTypes,
} from '../../../../api';
import { CheckboxInputs } from '../../../../components/CheckboxInputs';
import { convertUTCDateToLocal } from '../../../../components/datepicker/utils';
import { InputV3 } from '../../../../components/InputV3';
import { ModalApi, ModalV3 } from '../../../../components/ModalV3';
import { SelectV3 } from '../../../../components/SelectV3';
import { useApiQuery } from '../../../../hooks/useApiQuery';
import { useLists } from '../../../../hooks/useLists';

interface Props {
  title: string;
  new?: boolean;
  customer: Customer;
}

export const EditWorkspace = (props: Props) => {
  const { state } = useLocation() as { state: Customer | null };
  const showTip = state;

  const customer = props.customer ?? state;
  const { workspaceId, customerId } = useParams<{ workspaceId: string; customerId: string }>();
  const workspaceResp = useApiQuery(getWorkspace(workspaceId!), { cancel: props.new === true });
  const [hasError, setHasError] = useState(false);
  const modalRef = useRef<ModalApi>(null);

  const initialValues: Partial<Workspace> = {
    customerSid: customerId,
    billingMode: null as any,
    trialDays: null as any,
    limits: {
      products: null as any,
      modelsPerProduct: null as any,
      manufacturingReports: null as any,
      specUploads: null as any,
    },
    productTypes: {
      food: true,
      packaging: false,
    },
  };

  const navigate = useNavigate();
  const [triedToSubmit, setTriedToSubmit] = useState(false);
  const lists = useLists();

  useEffect(() => {
    if (hasError && modalRef.current) {
      modalRef.current.open();
    }
  }, [hasError]);

  const billingOptions = [
    {
      type: BillingModeType.Free,
      name: 'No billing (free)',
    },
    {
      type: BillingModeType.Standalone,
      name: 'Stand-alone billing',
    },
    {
      type: BillingModeType.Volume,
      name: 'Volume billing',
    },
  ];

  const renderError = (error?: string) => error && <div className='absolute -bottom-1 translate-y-full text-red-500 text-xs'>{error}</div>;

  if (!props.new && !workspaceResp.data) {
    return <></>;
  }

  const dateToDays = (date: string | null) => {
    if (!date) {
      return undefined;
    }
    return differenceInDays(new Date(convertUTCDateToLocal(date)), new Date()) + 1;
  };

  const productTypeOptions: StaticEntity<string>[] = Object.values(WorkspaceProductTypes).map((type) => ({
    type,
    name:
      {
        [WorkspaceProductTypes.Food]: 'Food',
        [WorkspaceProductTypes.Packaging]: 'Packaging',
      }[type] || type,
  }));

  return (
    <Formik<Partial<Workspace>>
      validateOnBlur={triedToSubmit}
      validateOnChange={triedToSubmit}
      validationSchema={yup.object().shape({
        name: yup.string().nullable().required(defaultRequiredMessage),
        targetSkuCount: yup.number().nullable().required(defaultRequiredMessage),
        methodology: yup.object().nullable().required(defaultRequiredMessage),
        brand: yup.object().nullable().required(defaultRequiredMessage),
        trialDays: yup.number().positive().nullable(),
        billingMode: yup.object().nullable().required(defaultRequiredMessage),
        limits: yup.object().shape({
          products: yup.number().nullable().required(defaultRequiredMessage),
          modelsPerProduct: yup.number().nullable().required(defaultRequiredMessage),
          manufacturingReports: yup.number().nullable().required(defaultRequiredMessage),
          specUploads: yup.number().nullable().required(defaultRequiredMessage),
        }),
        productTypes: yup
          .object()
          .test('at-least-one', 'At least one product type must be enabled', (value) => {
            return value ? Object.values(value).some((enabled) => enabled === true) : false;
          })
          .required(defaultRequiredMessage),
      })}
      initialValues={
        props.new
          ? {
              ...initialValues,
              productTypes: {
                [WorkspaceProductTypes.Food]: true,
                [WorkspaceProductTypes.Packaging]: false,
              },
            }
          : {
              ...workspaceResp.data!,
              productTypes: {
                [WorkspaceProductTypes.Food]: workspaceResp.data!.productTypes?.food ?? false,
                [WorkspaceProductTypes.Packaging]: workspaceResp.data!.productTypes?.packaging ?? false,
              },
              trialDays: dateToDays(workspaceResp.data!.trialUntil),
            }
      }
      onSubmit={(values, { setSubmitting }) => {
        (props.new ? createWorkspace : updateWorkspace)(values).call({
          ok: ({ errorCode }) => {
            if (errorCode) {
              setSubmitting(false);
              setHasError(true);
              return;
            } else {
              setSubmitting(false);
              navigate('../workspaces');
            }
          },
          fail: () => {
            setSubmitting(false);
          },
        });
      }}
    >
      {(formik) => (
        <Form
          noValidate
          className='flex flex-col justify-between h-[calc(100vh-80px)] xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.12))] bg-neutral-50'
        >
          <div>
            <ModalV3
              hideConfirm
              preventDismiss
              cancelLabel='Close'
              onClose={() => setHasError(false)}
              ref={modalRef}
              title={<div>Methodology cannot be changed</div>}
              body={
                <div className='-mt-6'>
                  This workspace is not empty and as such we cannot change its methodology. Delete all products or{' '}
                  <a className='hover:text-brand underline text-black font-semibold' href='mailto:help@sustained.com'>
                    contact us
                  </a>{' '}
                  to discuss a way to migrate everything to your desired methodology
                </div>
              }
            />
            <div className='flex flex-col w-full items-center justify-center border-b border-zinc-200 bg-white'>
              <div className='flex items-center gap-x-4 py-6 w-full xl:w-[theme(screens.xl)] px-12 text-black'>
                <NavLink to='../workspaces' className='size-8 rounded-lg bg-slate-200 flex items-center justify-center'>
                  <FontAwesomeIcon className='text-lg' icon={regular('chevron-left')} />
                </NavLink>
                <div className='flex items-center gap-x-2 text-lg font-semibold bg-white'>
                  <div>{customer.companyName} Organisation</div>
                  <FontAwesomeIcon className='text-xs' icon={solid('chevron-right')} />
                  <div>{props.title}</div>
                </div>
              </div>
            </div>
            <div className='flex flex-col h-full w-full items-center pt-8'>
              <div className='flex flex-col gap-y-6 w-full xl:w-[theme(screens.xl)] px-12'>
                {showTip && (
                  <div className='flex items-center gap-x-2 p-2 border rounded-lg bg-white'>
                    <div className='size-8 flex items-center justify-center rounded-full bg-[#D6FF00]'>
                      <FontAwesomeIcon className='size-5 p-1' icon={light('lightbulb')} />
                    </div>
                    <div>A default workspace was created alongside the new organisation. All default properties can be edited below.</div>
                  </div>
                )}

                <div className='grid grid-cols-4 gap-6 *:text-black *:flex *:flex-col *:gap-1.5'>
                  <Field name='name'>
                    {(model: FieldProps<string>) => (
                      <div className='flex flex-col gap-1.5 relative'>
                        <div className='font-semibold text-sm'>Workspace name</div>
                        <InputV3 autoFocus model={model} />
                        {renderError(model.meta.error)}
                      </div>
                    )}
                  </Field>
                  <Field name='targetSkuCount'>
                    {(model: FieldProps<number>) => (
                      <div className='relative'>
                        <div className='font-semibold text-sm'>Target product count</div>
                        <InputV3 integer positive model={model} />
                        {renderError(model.meta.error)}
                      </div>
                    )}
                  </Field>
                  <Field name='methodology'>
                    {(model: FieldProps<StaticEntity>) => (
                      <div className='relative'>
                        <div className='font-semibold text-sm'>Methodology</div>
                        <SelectV3 model={model} options={lists.methodologies} />
                        {renderError(model.meta.error)}
                      </div>
                    )}
                  </Field>
                  <Field name='productTypes'>
                    {(model: FieldProps<Record<string, boolean>>) => (
                      <div className='relative'>
                        <div className='font-semibold text-sm'>Product Types</div>
                        <CheckboxInputs model={model} options={productTypeOptions} inline />
                        {renderError(model.meta.error)}
                      </div>
                    )}
                  </Field>
                  <Field name='brand'>
                    {(model: FieldProps<StaticEntity>) => (
                      <div className='relative'>
                        <div className='font-semibold text-sm'>Brand</div>
                        <SelectV3 model={model} options={lists.brands} />
                        {renderError(model.meta.error)}
                      </div>
                    )}
                  </Field>
                  <Field name='trialDays'>
                    {(model: FieldProps<number>) => (
                      <div>
                        <div className='font-semibold text-sm'>Trial days</div>
                        <InputV3 integer positive model={model} placeholder='Leave empty if not needed…' />
                      </div>
                    )}
                  </Field>
                  <Field name='billingMode'>
                    {(model: FieldProps<{ type: BillingModeType; name: string }>) => (
                      <div className='relative'>
                        <div className='font-semibold text-sm'>Billing mode</div>
                        <SelectV3 model={model} options={billingOptions} />
                        {renderError(model.meta.error)}
                      </div>
                    )}
                  </Field>
                  <div />
                </div>

                <div className='mt-6 flex flex-col p-6 bg-white rounded-2xl shadow border *:text-black'>
                  <div className='font-semibold'>Workspace limits</div>
                  <div className='mt-2'>
                    The following limits will be applied to this workspace. Users will be prevented from creating new
                    products/models/reports when the limit is reached.
                  </div>
                  <div className='mt-6 grid grid-cols-4 gap-x-6 *:font-semibold'>
                    <Field name='limits.products'>
                      {(model: FieldProps<number>) => (
                        <div className='flex flex-col gap-y-2'>
                          <div>Products</div>
                          <InputV3 integer positive model={model} />
                          {renderError(model.meta.error)}
                        </div>
                      )}
                    </Field>
                    <Field name='limits.modelsPerProduct'>
                      {(model: FieldProps<number>) => (
                        <div className='flex flex-col gap-y-2'>
                          <div>Models per product</div>
                          <InputV3 integer positive model={model} />
                          {renderError(model.meta.error)}
                        </div>
                      )}
                    </Field>
                    <Field name='limits.manufacturingReports'>
                      {(model: FieldProps<number>) => (
                        <div className='flex flex-col gap-y-2'>
                          <div>Volume reports</div>
                          <InputV3 integer positive model={model} />
                          {renderError(model.meta.error)}
                        </div>
                      )}
                    </Field>
                    <Field name='limits.specUploads'>
                      {(model: FieldProps<number>) => (
                        <div className='flex flex-col gap-y-2'>
                          <div>Spec Uploads</div>
                          <InputV3 integer positive model={model} />
                          {renderError(model.meta.error)}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='border-t py-4 flex items-center justify-center bg-white'>
            <div className='flex justify-end w-full xl:w-[theme(screens.xl)] px-12'>
              <button
                disabled={formik.isSubmitting}
                onClick={() => setTriedToSubmit(true)}
                className='rounded-full py-2 px-3 bg-brand text-white font-semibold text-sm'
              >
                {props.new ? 'Create workspace' : 'Update workspace'}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
