import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { FieldProps } from 'formik';

interface Props {
  model: FieldProps<Record<string, boolean>>;
  options: { type: string; name: string }[];
  inline?: boolean;
}

export const CheckboxInputs = ({ model, options, inline }: Props) => {
  return (
    <div className={cn(inline ? 'flex items-center gap-x-4' : 'flex flex-col gap-y-2')}>
      {options.map((option) => {
        const checked = model.field.value?.[option.type] || false;

        return (
          <label key={option.type} className='flex items-center gap-2'>
            <div
              className={cn(
                'flex items-center justify-center size-4 border rounded-sm',
                checked ? 'border-brand bg-brand' : 'border-zinc-500 bg-white',
              )}
            >
              {checked && <FontAwesomeIcon className='h-3 text-white' icon={solid('check')} />}
              <input
                type='checkbox'
                className='absolute appearance-none'
                checked={checked}
                onChange={() => {
                  model.form.setFieldValue(model.field.name, {
                    ...model.field.value,
                    [option.type]: !checked,
                  });
                }}
              />
            </div>
            <div>{option.name}</div>
          </label>
        );
      })}
    </div>
  );
};
