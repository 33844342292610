import cn from 'classnames';
import { Field, FieldProps, FormikContextType, useFormikContext } from 'formik';
import { PropsWithChildren, RefObject, useRef, useState } from 'react';
import * as yup from 'yup';
import {
  FacilityType,
  FacilityWithSelectedAgreement,
  ImpactDelta,
  ModellingPayload,
  NodeType,
  ProductModelV3,
  StoredItem,
  WarehouseNode,
  facilityOptions,
  getFacilities,
} from '../../../../api';
import { ModalForm, ModalFormSaveCallback } from '../../../../components/ModalForm';
import { SelectFooterAddButton } from '../../../../components/SelectFooterAddButton';
import { SelectV3 } from '../../../../components/SelectV3';
import { NewFacilityForm } from '../../Manage/Facilities/NewFacilityForm';
import { InteractiveImpactBadge } from './InteractiveImpactBadge';
import { getWarehouses, newNodeId } from './dataModel';
import { useInteractiveImpact } from './useInteractiveImpact';

type Props = PropsWithChildren<{
  payload: ModellingPayload;
  data?: WarehouseNode;
  readOnlyMode: boolean;
  onSave: ModalFormSaveCallback<WarehouseNode[]>;
  onOpenChange?: (open: boolean) => void;
}>;

const toNode = (facility: FacilityWithSelectedAgreement): WarehouseNode => ({
  id: newNodeId(),
  displayName: '',
  type: NodeType.Warehouse,
  flagged: false,
  edges: new Array<string>(),
  items: new Array<StoredItem>(),
  facility,
});

export const WarehouseDetails = (props: Props) => {
  const formRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext<ProductModelV3>();
  const [impactDelta, setImpactDelta] = useState<ImpactDelta | undefined>();
  const [calculating, setCalculating] = useState(false);

  return (
    <ModalForm<WarehouseNode | { facility: FacilityWithSelectedAgreement[] }>
      size='narrow'
      formRef={formRef}
      title={props.data ? `Editing ${props.data.displayName}` : 'Add storage'}
      body={
        <Body
          payload={props.payload}
          productFormik={formik}
          formRef={formRef}
          edit={!!props.data}
          onImpactDelta={setImpactDelta}
          onCalculating={setCalculating}
        />
      }
      onOpenChange={props.onOpenChange}
      headerRight={props.readOnlyMode ? undefined : <InteractiveImpactBadge data={impactDelta} calculating={calculating} />}
      instructions={
        <div className='flex flex-col gap-4 p-2'>
          <div>
            Does your product get stored in various storage facilities on its way to the end consumer? It’s important to know as storing has
            an impact too.
          </div>
          <div>
            Add as many of those as your product requires, and don’t forget others’ storage facilities too, your transport provider or
            retailers may also be storing this product along the way, all contributing to this product’s overall environmental impact!
          </div>
        </div>
      }
      emptyData={{
        facility: new Array<FacilityWithSelectedAgreement>(),
      }}
      data={props.data}
      validationSchema={yup.object().shape({
        facility: props.data ? yup.object().required() : yup.array().min(1),
      })}
      entityName={props.data ? 'storage' : undefined}
      onSave={({ values, closeModal }) => {
        if (props.data) {
          props.onSave({ values: [values as WarehouseNode], closeModal });
        } else {
          props.onSave({ values: (values.facility as FacilityWithSelectedAgreement[]).map(toNode), closeModal });
        }
      }}
      hideSave={props.readOnlyMode}
    >
      {props.children}
    </ModalForm>
  );
};

interface BodyProps {
  payload: ModellingPayload;
  productFormik: FormikContextType<ProductModelV3>;
  formRef: RefObject<HTMLDivElement>;
  edit: boolean;
  onImpactDelta: (value?: ImpactDelta) => void;
  onCalculating: (value: boolean) => void;
}

const Body = (props: BodyProps) => {
  const { payload, productFormik } = props;
  const formik = useFormikContext<WarehouseNode>();
  const [newFacilityForm, setNewFacilityForm] = useState(false);
  const [newFacilityName, setNewFacilityName] = useState('');

  useInteractiveImpact<WarehouseNode>({
    payload,
    productFormik,
    toNode: props.edit ? undefined : (toNode as any),
    onChange: props.onImpactDelta,
    onCalculating: props.onCalculating,
  });

  return (
    <div className='flex flex-col gap-1'>
      <div className='pl-1.5'>
        Select {props.edit ? 'a ' : ''}storage{props.edit ? '' : 's'}
      </div>
      <div className={cn({ 'w-2/3': props.edit })}>
        <Field name='facility'>
          {(model: FieldProps<FacilityWithSelectedAgreement>) => (
            <SelectV3<FacilityWithSelectedAgreement>
              noClear
              autoFocus
              multi={!props.edit}
              model={model}
              menuPortalTarget={props.formRef.current}
              loadOptions={(input, callback) => {
                setNewFacilityName(input);
                getFacilities({
                  contains: input,
                  type: FacilityType.Warehouse,
                }).ok(({ facilities }) =>
                  callback(
                    facilities.filter(
                      ({ id }) =>
                        !getWarehouses(props.productFormik)
                          .filter(({ id }) => id !== formik.values.id)
                          .some(({ facility }) => id === facility.id),
                    ),
                  ),
                );
              }}
              menuFooter={
                !newFacilityForm && (
                  <SelectFooterAddButton
                    onClick={() => setNewFacilityForm(true)}
                    name={newFacilityName}
                    label={facilityOptions.find(({ id }) => id === FacilityType.Warehouse)!.name.toLowerCase()}
                  />
                )
              }
            />
          )}
        </Field>
      </div>
      {newFacilityForm && (
        <div className='mt-6'>
          <NewFacilityForm
            enableFacilityOwnerForm
            name={newFacilityName}
            typeOfFacility={FacilityType.Warehouse}
            formRef={props.formRef}
            onCancel={() => setNewFacilityForm(false)}
            onCreated={(newFacility) => {
              formik.setFieldValue('facility', props.edit ? newFacility : [...(formik.values.facility as any), newFacility]);
              setNewFacilityForm(false);
            }}
          />
        </div>
      )}
    </div>
  );
};
