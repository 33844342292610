import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldProps, FormikContextType, useFormikContext } from 'formik';
import { PropsWithChildren, RefObject, useRef, useState } from 'react';
import * as yup from 'yup';
import {
  Facility,
  facilityOptions,
  FacilityType,
  getFacilities,
  NodeType,
  ProductionNode,
  ProductionStepNode,
  ProductState,
  ProductV3,
} from '../../../../api';
import { ModalForm, ModalFormSaveCallback } from '../../../../components/ModalForm';
import { SelectFooterAddButton } from '../../../../components/SelectFooterAddButton';
import { SelectV3 } from '../../../../components/SelectV3';
import { Toggle } from '../../../../components/Toggle';
import { NewFacilityForm } from '../../Manage/Facilities/NewFacilityForm';
import { DefaultBadge } from './Badges';
import { ExtractedData } from './ExtractedData';
import { ModalHeaderRightBar } from './ModalHeaderRightBar';
import { TaggableField, TaggableFieldsContainer } from './TaggableFields';
import { defaultGrids, getProductionFacilities, newNodeId } from './dataModel';

type Props = PropsWithChildren<{
  data?: ProductionNode;
  onSave: ModalFormSaveCallback<ProductionNode>;
  onOpenChange?: (open: boolean) => void;
}>;

export const ProductionFacilityDetails = (props: Props) => {
  const formRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext<ProductV3>();

  return (
    <ModalForm
      formRef={formRef}
      onOpenChange={props.onOpenChange}
      title={props.data ? `Editing ${props.data.displayName}` : 'Add production facility'}
      body={<Body productFormik={formik} formRef={formRef} edit={!!props.data} />}
      headerRight={<ModalHeaderRightBar />}
      instructions={
        <div className='flex flex-col gap-4 p-2'>
          {/* TODO: change sentence for material */}
          <div>
            Where is your product being made? Production facilities are the places where ingredients are assembled into your end product and
            everything is then packaged for distribution. You may have one or many for different steps of the production process.
          </div>
          <div>
            Make sure you specify which facility is the ‘final production facility’ or the one resulting in your all packaged and ready for
            distribution. You could have many of course, that works too.
          </div>
        </div>
      }
      emptyData={{
        id: newNodeId(),
        displayName: '',
        type: NodeType.Production,
        edges: new Array<string>(),
        flagged: false,
        steps: new Array<ProductionStepNode>(),
        facility: undefined as any as Facility,
        finalFacility: false,
        layoutGrid: defaultGrids.productionFacility,
      }}
      data={props.data}
      metadata={formik.values.metadata}
      validationSchema={yup.object().shape({
        facility: yup.object().required(),
      })}
      entityName='facility'
      saveLabel={formik.values.state === ProductState.Complete ? 'Confirm changes' : undefined}
      onSave={props.onSave}
    >
      {props.children}
    </ModalForm>
  );
};

const Body = (props: { productFormik: FormikContextType<ProductV3>; formRef: RefObject<HTMLDivElement>; edit: boolean }) => {
  const formik = useFormikContext<ProductionNode>();
  const [showFacilityForm, setShowFacilityForm] = useState(false);
  const [newFacilityName, setNewFacilityName] = useState('');

  return (
    <TaggableFieldsContainer pathPrefix='nodes'>
      <div className='flex flex-col gap-6'>
        <ExtractedData {...props} />
        <div className='flex flex-col gap-1'>
          <div className='pl-1.5'>Select a facility</div>
          <div className='w-2/3'>
            <TaggableField name='facility'>
              {(model: FieldProps<Facility>) => (
                <SelectV3<Facility>
                  autoFocus
                  model={model}
                  menuPortalTarget={props.formRef.current}
                  loadOptions={(input, callback) => {
                    setNewFacilityName(input);
                    getFacilities({
                      contains: input,
                      type: FacilityType.Production,
                    }).ok(({ facilities }) =>
                      callback(
                        facilities.filter(
                          ({ id }) =>
                            !getProductionFacilities(props.productFormik)
                              .filter(({ id }) => id !== formik.values.id)
                              .some(({ facility }) => id === facility.id),
                        ),
                      ),
                    );
                  }}
                  renderOptionBadge={(facility) => (facility.default ? <DefaultBadge /> : <></>)}
                  menuFooter={
                    !showFacilityForm && (
                      <SelectFooterAddButton
                        onClick={() => setShowFacilityForm(true)}
                        name={newFacilityName}
                        label={facilityOptions.find(({ id }) => id === FacilityType.Production)!.name.toLowerCase()}
                      />
                    )
                  }
                />
              )}
            </TaggableField>
          </div>
        </div>
        {showFacilityForm && (
          <NewFacilityForm
            enableFacilityOwnerForm
            name={newFacilityName}
            typeOfFacility={FacilityType.Production}
            formRef={props.formRef}
            onCancel={() => setShowFacilityForm(false)}
            onCreated={(newFacility) => {
              formik.setFieldValue('facility', newFacility);
              setShowFacilityForm(false);
            }}
          />
        )}
        {!props.edit && (
          <div className='p-4 rounded-lg bg-[#E8EAF5] text-neutral-900 border border-brand'>
            Please remember to fill out the Production Facility Board by adding all the processes happening in this facility.
          </div>
        )}
        <TaggableField name='finalFacility'>
          {(model: FieldProps) => (
            <div className='flex flex-col gap-4 text-neutral-900'>
              <div className='flex flex-col gap-1'>
                <div className='font-semibold'>Final production facility</div>
                <div>
                  Please let us know if this is the last facility in your entire production process, the one resulting in the final ready to
                  be distributed to retailers and consumers.
                </div>
              </div>
              <Toggle model={model} />
            </div>
          )}
        </TaggableField>
        <div className='self-start flex items-center gap-2 bg-amber-50 border-amber-400 rounded-lg border p-2'>
          <FontAwesomeIcon className='text-amber-400' size='lg' icon={regular('triangle-exclamation')} />
          Please note that only facilities set to “final” can be linked to facilities in the distribution section.
        </div>
      </div>
    </TaggableFieldsContainer>
  );
};
