import cn from 'classnames';
import { format } from 'date-fns';
import { Fragment, PropsWithChildren, useState } from 'react';
import { getSuppliers, Supplier, SupplierService, supplierServices } from '../../../../../api';
import { ModalV3 } from '../../../../../components/ModalV3';
import { SelectV3 } from '../../../../../components/SelectV3';

interface Props {
  title: string;
}

export const Suppliers = (props: PropsWithChildren<Props>) => {
  const [full, setFull] = useState(false);
  const [selectedSupplierType, setSelectedSupplierType] = useState<{ id: string; name: string }>({ id: '', name: 'All' });

  const [suppliersData, setSuppliersData] = useState<{
    list: Supplier[];
    searchParams: { pageSize: number };
  }>({
    list: [],
    searchParams: { pageSize: 500 },
  });

  const fetchSuppliers = (payload: { id: string; name: string }) => {
    setSelectedSupplierType(payload);
    getSuppliers({
      ...suppliersData.searchParams,
      service: payload.id,
    }).ok(({ suppliers }) =>
      setSuppliersData((current) => ({
        ...current,
        list: suppliers,
      })),
    );
  };

  return (
    <ModalV3
      fixedHeight
      hideConfirm
      size='wide+'
      cancelLabel='Close'
      onOpenChange={(open) => {
        if (open) {
          getSuppliers({
            ...suppliersData.searchParams,
          }).ok(({ suppliers, nextPageToken }) =>
            setSuppliersData((current) => ({
              ...current,
              list: suppliers,
              nextPageToken,
            })),
          );
        }
      }}
      title={
        <div className='flex flex-col gap-y-6 -mb-6'>
          <div className='flex justify-between items-center'>
            <div>Winners and loosers, {props.title}</div>
            <div className='flex gap-2.5'>
              <div className='text-sm font-normal w-52'>
                <SelectV3
                  placeholder='Provider type'
                  value={selectedSupplierType}
                  onActions={{
                    add: fetchSuppliers,
                    clear: () => fetchSuppliers({ id: '', name: 'All' }),
                    remove: () => fetchSuppliers({ id: '', name: 'All' }),
                  }}
                  options={[
                    { id: '', name: 'All' },
                    { id: SupplierService.BrandOwner, name: 'Brand owners' },
                    { id: SupplierService.Manufacturer, name: 'Manufacturers' },
                    { id: SupplierService.Ingredient, name: 'Ingredient suppliers' },
                    { id: SupplierService.Packaging, name: 'Packaging suppliers' },
                    { id: SupplierService.Retailer, name: 'Retailers' },
                    { id: SupplierService.Warehouse, name: 'Storage suppliers' },
                    { id: SupplierService.Transport, name: 'Transport suppliers' },
                    { id: SupplierService.Material, name: 'Material suppliers' },
                  ]}
                />
              </div>
              <button
                type='button'
                className='flex items-center whitespace-nowrap border-2 border-transparent rounded-full px-4 py-1 leading-none text-sm font-semibold text-white bg-brand active:scale-95'
                onClick={() => setFull((current) => !current)}
              >
                {full ? 'Collapse list' : 'See full list'}
              </button>
              <button
                type='button'
                className='flex items-center whitespace-nowrap border-2 border-transparent rounded-full px-4 py-1 leading-none text-sm font-semibold text-brandDarkPurple2 bg-[#E8EAF5] active:scale-95'
              >
                Download CSV
              </button>
            </div>
          </div>

          <div className='sticky top-6 grid grid-cols-[2fr_1fr_3fr_1fr_1fr] gap-x-2 col-span-5 text-sm font-semibold bg-white py-3 border-y border-zinc-200 text-zinc-800 whitespace-nowrap items-center'>
            <div className='pl-6'>Name</div>
            <div>ID</div>
            <div>Service/location provided</div>
            <div>Controlled</div>
            <div>Last updated</div>
          </div>
        </div>
      }
      body={
        <>
          {(() => {
            const best = suppliersData.list.slice(0, 10);
            const worst = suppliersData.list.slice(-10);

            return (
              <>
                {full ? (
                  suppliersData.list.map((item, i, arr) => (
                    <div
                      key={i}
                      className={cn(
                        '*:py-2 mx-6 col-span-5 grid grid-cols-[2fr_1fr_3fr_1fr_1fr] gap-x-2 border-y-4 border-white text-zinc-600',
                        { 'bg-emerald-50': i <= 10 },
                        { 'bg-red-50': i > 10 && arr.length < i + 10 },
                      )}
                    >
                      <div className='text-zinc-700 font-semibold pl-6'>{item.name}</div>
                      <div className='truncate'>{item.id}</div>
                      <div className='flex gap-2 truncate flex-wrap'>
                        {item.services.map((service, i) => (
                          <div key={i} className='self-start rounded-full text-gray-500 px-2 border border-zinc-300 pb-0.5 bg-white'>
                            {supplierServices.find(({ id }) => id === service)!.name}
                          </div>
                        ))}
                      </div>
                      <div>{item.controlledByCustomer ? 'Yes' : 'No'}</div>
                      <div>{format(new Date(item.updatedAt), 'dd/MM/yyyy')}</div>
                    </div>
                  ))
                ) : (
                  <div className='flex flex-col h-full mx-6'>
                    {[best, worst].map((item, index) => (
                      <Fragment key={index}>
                        <div className='grid grid-cols-[2fr_1fr_3fr_1fr_1fr] gap-x-2 col-span-5 overflow-y-auto'>
                          <div
                            className={cn(
                              'sticky top-0 col-span-5 flex justify-center items-center font-semibold bg-white py-2',
                              { 'text-emerald-700': index === 0 },
                              { 'text-red-500': index === 1 },
                            )}
                          >
                            {index === 0 ? 'Top 10' : 'Worst 10'}
                          </div>
                          {item.map((item, j) => (
                            <>
                              <div key={j} className='grid grid-cols-subgrid gap-x-2 col-span-5 *:py-2'>
                                <div
                                  className={cn(
                                    'grid grid-cols-subgrid col-span-5 border-y-4 border-white text-zinc-600',
                                    { 'bg-emerald-50': index === 0 },
                                    { 'bg-red-50': index === 1 },
                                  )}
                                >
                                  <div className='pl-6 text-zinc-700 font-semibold'>{item.name}</div>
                                  <div className='truncate'>{item.id}</div>
                                  <div className='flex gap-2 truncate flex-wrap'>
                                    {item.services.map((service, i) => (
                                      <div
                                        key={i}
                                        className='self-start rounded-full text-gray-500 px-2 border border-zinc-300 pb-0.5 bg-white'
                                      >
                                        {supplierServices.find(({ id }) => id === service)!.name}
                                      </div>
                                    ))}
                                  </div>
                                  <div>{item.controlledByCustomer ? 'Yes' : 'No'}</div>
                                  <div>{format(new Date(item.updatedAt), 'dd/MM/yyyy')}</div>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </Fragment>
                    ))}
                  </div>
                )}
              </>
            );
          })()}
        </>
      }
    >
      {props.children}
    </ModalV3>
  );
};
