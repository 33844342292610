import { MutableRefObject, SetStateAction } from 'react';
import { Props as TitleBadgesProps } from '../../TitleBadges';
import { Food } from './Food';
import { ProductClass, ProductV3 } from '../../../../../../api';
import { useFormikContext } from 'formik';
import { Packaging } from './Packaging';

interface Props extends TitleBadgesProps {
  title: string;
  ignoreValuesChange: MutableRefObject<boolean>;
}

export const Step2 = (
  props: Props & { triedSubmitting: () => void; skuIdInUse: boolean; setSkuIdInUse: (v: SetStateAction<boolean>) => void },
) => {
  const formik = useFormikContext<ProductV3>();

  return formik.values.productClass === ProductClass.Food ? <Food {...props} /> : <Packaging {...props} />;
};
