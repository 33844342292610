import cn from 'classnames';
import { FieldProps, FormikContextType, useFormikContext } from 'formik';
import { PropsWithChildren, RefObject, useRef, useState } from 'react';
import * as yup from 'yup';
import {
  Facility,
  facilityOptions,
  FacilityType,
  getFacilities,
  NodeType,
  ProductionWarehouseNode,
  ProductState,
  ProductV3,
  StoredItem,
} from '../../../../api';
import { ModalForm, ModalFormApi, ModalFormSaveCallback } from '../../../../components/ModalForm';
import { SelectFooterAddButton } from '../../../../components/SelectFooterAddButton';
import { SelectV3 } from '../../../../components/SelectV3';
import { NewFacilityForm } from '../../Manage/Facilities/NewFacilityForm';
import { DefaultBadge } from './Badges';
import { ExtractedData } from './ExtractedData';
import { ModalHeaderRightBar } from './ModalHeaderRightBar';
import { TaggableField, TaggableFieldsContainer } from './TaggableFields';
import { getProductionWarehouses, newNodeId } from './dataModel';

type Props = PropsWithChildren<{
  modalRef?: RefObject<ModalFormApi>;
  data?: ProductionWarehouseNode;
  onSave: ModalFormSaveCallback<ProductionWarehouseNode[]>;
  onOpenChange?: (open: boolean) => void;
}>;

const toNode = (facility: Facility): ProductionWarehouseNode => ({
  id: newNodeId(),
  displayName: '',
  type: NodeType.ProductionWarehouse,
  flagged: false,
  edges: new Array<string>(),
  items: new Array<StoredItem>(),
  facility,
});

export const ProductionWarehouseDetails = (props: Props) => {
  const formRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext<ProductV3>();

  return (
    <ModalForm<ProductionWarehouseNode | { facility: Facility[] }>
      size='narrow'
      ref={props.modalRef}
      formRef={formRef}
      onOpenChange={props.onOpenChange}
      title={props.data ? `Editing ${props.data.displayName}` : 'Add storage'}
      body={<Body productFormik={formik} formRef={formRef} edit={!!props.data} />}
      headerRight={<ModalHeaderRightBar />}
      instructions={
        <div className='flex flex-col gap-4 p-2'>
          <div>
            Is anything getting stored between the moment you receive your raw materials and the moment your product goes out to
            distribution?
          </div>
          <div>
            Add any storage to your production life cycle stage and specify what gets stored there by adding transport to/from them. It’s
            important to know as storing has an impact too.
          </div>
        </div>
      }
      emptyData={{
        id: newNodeId(),
        displayName: '',
        type: NodeType.ProductionWarehouse,
        edges: new Array<string>(),
        flagged: false,
        facility: undefined as any as Facility[],
      }}
      data={props.data}
      metadata={formik.values.metadata}
      validationSchema={yup.object().shape({
        facility: props.data ? yup.object().required() : yup.array().min(1),
      })}
      entityName={props.data ? 'storage' : undefined}
      saveLabel={formik.values.state === ProductState.Complete ? 'Confirm changes' : undefined}
      onSave={({ values, ...rest }) => {
        if (props.data) {
          props.onSave({ values: [values as ProductionWarehouseNode], ...rest });
        } else {
          props.onSave({ values: (values.facility as Facility[]).map(toNode), ...rest });
        }
      }}
    >
      {props.children}
    </ModalForm>
  );
};

const Body = (props: { productFormik: FormikContextType<ProductV3>; formRef: RefObject<HTMLDivElement>; edit: boolean }) => {
  const formik = useFormikContext<ProductionWarehouseNode>();
  const [newFacilityForm, setNewFacilityForm] = useState(false);
  const [newFacilityName, setNewFacilityName] = useState('');

  return (
    <TaggableFieldsContainer pathPrefix='nodes'>
      <div className='flex flex-col gap-6'>
        <ExtractedData {...props} />
        <div className='flex flex-col gap-1'>
          <div className='pl-1.5'>
            Select {props.edit ? 'a ' : ''}storage{props.edit ? '' : 's'}
          </div>
          <div className={cn({ 'w-2/3': props.edit })}>
            <TaggableField name='facility'>
              {(model: FieldProps<Facility>) => (
                <SelectV3<Facility>
                  autoFocus
                  multi={!props.edit}
                  model={model}
                  menuPortalTarget={props.formRef.current}
                  loadOptions={(input, callback) => {
                    setNewFacilityName(input);
                    getFacilities({
                      contains: input,
                      type: FacilityType.Warehouse,
                    }).ok(({ facilities }) =>
                      callback(
                        facilities.filter(
                          ({ id }) =>
                            !getProductionWarehouses(props.productFormik)
                              .filter(({ id }) => id !== formik.values.id)
                              .some(({ facility }) => id === facility.id),
                        ),
                      ),
                    );
                  }}
                  renderOptionBadge={(facility) => (facility.default ? <DefaultBadge /> : <></>)}
                  menuFooter={
                    !newFacilityForm && (
                      <SelectFooterAddButton
                        onClick={() => setNewFacilityForm(true)}
                        name={newFacilityName}
                        label={facilityOptions.find(({ id }) => id === FacilityType.Warehouse)!.name.toLowerCase()}
                      />
                    )
                  }
                />
              )}
            </TaggableField>
          </div>
        </div>
        {newFacilityForm && (
          <NewFacilityForm
            enableFacilityOwnerForm
            name={newFacilityName}
            typeOfFacility={FacilityType.Warehouse}
            formRef={props.formRef}
            onCancel={() => setNewFacilityForm(false)}
            onCreated={(newFacility) => {
              formik.setFieldValue('facility', props.edit ? newFacility : [...(formik.values.facility as any), newFacility]);
              setNewFacilityForm(false);
            }}
          />
        )}
      </div>
    </TaggableFieldsContainer>
  );
};
