import { duotone, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { FormikContextType, useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import { Fragment, PropsWithChildren, ReactNode, RefObject, createRef, useEffect, useRef, useState } from 'react';
import { ConsumptionNode, ConsumptionStepNode, OutputType, Position, ProductV3 } from '../../../../../api';
import { renderBadges } from '../Badges';
import { GridSize } from '../GridSize';
import { StepLink } from '../StepLink';
import { StepOutputsPill } from '../StepOutputsPill';
import {
  addStep,
  getConsumptionLocationsFromAll,
  getCookedAmount,
  getInputNode,
  removeStep,
  replaceStep,
  sizeToPositions,
} from '../dataModel';
import { getBorderStyle } from '../shared';
import { StepDetails } from './StepDetails';

interface DragState {
  mouseDown: { x: number; y: number };
  stepId: string;
  inProgress: boolean;
}

type Props = PropsWithChildren<{
  data: ConsumptionNode;
  scrollableRef: RefObject<HTMLDivElement>;
  waiting: boolean;
  ignoreLessImportantValidation: boolean;
  onBack: () => void;
}>;

export const ConsumptionLocationGraph = (props: Props) => {
  const formik = useFormikContext<ProductV3>();
  const { waiting } = props;
  const refs = useRef<Record<string, RefObject<HTMLDivElement>>>({});
  const slotsRefs = useRef<Record<string, RefObject<HTMLDivElement>>>({});
  const [dragState, setDragState] = useState<DragState | null>(null);

  props.data.steps.forEach((step) => {
    step.outputs.forEach((output) => {
      refs.current[output.id] = refs.current[output.id] ?? createRef();
    });

    refs.current[step.id] = refs.current[step.id] ?? createRef();
    refs.current[`${step.id}.process`] = refs.current[`${step.id}.process`] ?? createRef();
  });

  sizeToPositions(props.data.layoutGrid).forEach((position) => {
    slotsRefs.current[`${position.x}.${position.y}`] = slotsRefs.current[`${position.x}.${position.y}`] ?? createRef();
  });

  useEffect(() => {
    const onMouseUp = () => {
      setDragState(null);
    };

    window.addEventListener('mouseup', onMouseUp);

    return () => window.removeEventListener('mouseup', onMouseUp);
  }, [dragState]);

  useEffect(() => {
    if (dragState) {
      const onMouseMove = (event: MouseEvent) => {
        if (!waiting && dragState.inProgress) {
          const hoveredSlot = Object.keys(slotsRefs.current)
            .map((position) => ({
              position,
              ref: slotsRefs.current[position].current,
            }))
            .filter(({ ref }) => ref)
            .find(({ ref }) => {
              const rect = ref!.getBoundingClientRect();
              return event.clientX >= rect.left && event.clientX <= rect.right && event.clientY >= rect.top && event.clientY <= rect.bottom;
            });

          if (hoveredSlot) {
            formik.setValues((oldValues) => {
              const newValues = cloneDeep(oldValues);
              getConsumptionLocationsFromAll(newValues.nodes)
                .find(({ id }) => id === props.data.id)!
                .steps.find((step) => step.id === dragState.stepId)!.layoutPosition = {
                x: parseInt(hoveredSlot.position.split('.')[0]),
                y: parseInt(hoveredSlot.position.split('.')[1]),
              };
              return newValues;
            });
          }
        } else if (Math.max(Math.abs(event.clientX - dragState.mouseDown.x), Math.abs(event.clientY - dragState.mouseDown.y)) > 5) {
          setDragState((oldState) => (oldState ? { ...oldState, inProgress: true } : oldState));
        }
      };

      window.addEventListener('mousemove', onMouseMove);

      return () => window.removeEventListener('mousemove', onMouseMove);
    }
  }, [dragState, formik, waiting, props.data.id]);

  return (
    <div
      className={cn('text-xs flex-1 mx-12 flex flex-col rounded-lg bg-white shadow-[0_0_6px_rgba(0,0,0,0.1)]', {
        'cursor-move': dragState?.inProgress,
      })}
    >
      <div className='flex items-center gap-4 p-4 bg-[#E8EAF5] rounded-t-lg'>
        <button
          type='button'
          onClick={props.onBack}
          className='h-8 aspect-square flex justify-center items-center rounded-lg text-lg my-0.5 bg-cyan-50 text-cyan-900 shadow-[0_0_2px_0_rgba(0,0,0,0.2)] active:scale-90'
        >
          <FontAwesomeIcon icon={solid('times')} />
        </button>
        <div className='flex rounded-lg p-1 pr-3 bg-white shadow-[0_0_2px_0_rgba(0,0,0,0.2)]'>
          <div className='flex justify-center items-center gap-2'>
            <div className='flex justify-center items-center bg-cyan-50 text-cyan-900 rounded-md h-6 aspect-square'>
              <FontAwesomeIcon icon={duotone('house-user')} />
            </div>
            <div className='font-semibold'>{props.data.facility.name}</div>
          </div>
        </div>
        <div className='flex mx-5 border-r h-full w-px border-neutral-300' />
        <ConsumptionLocationAddStepButtons facility={props.data} productFormik={formik} />
        <div className='flex mx-5 border-r h-full w-px border-neutral-300' />
        <GridSize modelName={`nodes.${formik.values.nodes.indexOf(props.data)}.layoutGrid`}>
          {() => (
            <button
              type='button'
              className={cn(
                'flex justify-center items-center rounded-lg h-8 my-0.5 aspect-square',
                'text-[#220066] bg-[#DACEFD] shadow-[0_0_2px_0_rgba(0,0,0,0.2)]',
                'active:scale-95',
              )}
            >
              <FontAwesomeIcon size='lg' icon={regular('table')} />
            </button>
          )}
        </GridSize>
      </div>
      <div
        className='grid gap-x-16 gap-y-8 p-6'
        style={{
          gridTemplateColumns: `repeat(${props.data.layoutGrid.width}, min(14.5rem))`,
        }}
      >
        {(() => {
          const openDetails = (step: ConsumptionStepNode | undefined, children: ReactNode) =>
            step ? (
              <StepDetails
                key={step.id}
                parentNode={props.data}
                data={step as ConsumptionStepNode}
                onSave={({ values, metadata, sideEffects, closeModal }) => {
                  replaceStep(values, metadata!, sideEffects, props.data, formik);
                  closeModal();
                }}
              >
                {children}
              </StepDetails>
            ) : (
              children
            );

          const render = (position?: Position, step?: ConsumptionStepNode) =>
            openDetails(
              step,
              <div key={position ? `${position.x}.${position.y}` : step!.id} className={cn({ 'pointer-events-none': waiting })}>
                <div
                  ref={step ? refs.current[step.id] : slotsRefs.current[`${position!.x}.${position!.y}`]}
                  className={cn('select-none text-zinc-700 flex flex-col rounded-xl p-2 min-h-12 h-full transition', {
                    'cursor-pointer hover:bg-cyan-50/40': step && !dragState?.inProgress,
                    'bg-cyan-50/40': dragState?.inProgress && dragState.stepId === step?.id,
                    'bg-neutral-50': !step,
                  })}
                  onMouseDown={
                    step
                      ? (event) => {
                          setDragState({
                            mouseDown: { x: event.clientX, y: event.clientY },
                            stepId: step.id,
                            inProgress: false,
                          });
                        }
                      : undefined
                  }
                >
                  {step && (
                    <>
                      <div className='flex flex-col gap-2 whitespace-nowrap'>
                        {step.inputs
                          /*
                          TODO: not sure why need that filter
                          .filter(
                            (input) =>
                              !getOutputs(formik).some(
                                ({ id, outputType: { type } }) =>
                                  id === input.id &&
                                  type === OutputType.IntermediateProduct &&
                                  props.data.steps.flatMap(({ outputs }) => outputs).some((output) => output.id === id),
                              ),
                          )*/
                          .map((input, i, array) => {
                            const node = getInputNode(input, formik);
                            return (
                              <Fragment key={input.id}>
                                <div
                                  title={`${node.name} ${input.amountValue}${node.amount!.unit.name}`}
                                  key={input.id}
                                  className={cn(
                                    'grid grid-cols-[auto_50px] bg-gray-100 px-2 py-1 rounded-lg shadow-[0_0_2px_0_rgba(0,0,0,0.2)] truncate',
                                  )}
                                >
                                  <div className='truncate py-1.5'>{node.name}</div>
                                  <div className='flex items-center justify-center border-l border-neutral-400 ml-1 pl-1 py-1.5'>
                                    {input.amountValue}
                                    {node.amount!.unit.name}
                                  </div>
                                </div>
                                {i === array.length - 1 && (
                                  <div className='relative flex flex-col items-center w-full mt-1'>
                                    <div className='bg-cyan-900 h-5 w-px' />
                                    <div className='border-t-cyan-900 border-b-cyan-900 w-0 h-0 border-l-[3px] border-r-[3px] border-t-[5px] border-transparent -mt-1' />
                                  </div>
                                )}
                              </Fragment>
                            );
                          })}
                      </div>
                      <div
                        ref={refs.current[`${step.id}.process`]}
                        className={cn(
                          'relative flex flex-col gap-3 px-3.5 py-2.5 -mx-1 rounded-xl bg-cyan-50',
                          getBorderStyle(step, formik, props),
                        )}
                      >
                        <div className='flex items-center gap-2'>
                          <FontAwesomeIcon icon={duotone('pan-frying')} className='text-cyan-900 h-5 aspect-square' />
                          <div className='font-semibold truncate'>{step.displayName}</div>
                        </div>
                        {renderBadges(step, formik)}
                        {step.flagged && (
                          <FontAwesomeIcon
                            icon={solid('flag')}
                            className='absolute -top-1 -right-6 text-brandDarkPurple2 pointer-events-none'
                          />
                        )}
                        {step.flagged && (
                          <FontAwesomeIcon
                            icon={solid('flag')}
                            className='absolute -top-1 -right-5 text-brandDarkPurple2 pointer-events-none'
                          />
                        )}
                        <div
                          className='flex justify-center items-center absolute -top-1 -right-1 text-neutral-400 hover:text-f transition bg-white border border-neutral-200 rounded-full text-xs h-5 aspect-square'
                          onClick={(event) => {
                            event.stopPropagation();
                            removeStep(step, props.data, formik);
                          }}
                        >
                          <FontAwesomeIcon size='sm' icon={solid('times')} />
                        </div>
                        {step.finalStep && (
                          <div className='text-[8px] px-1 py-0.5 leading-none tracking-wider font-semibold text-cyan-900 uppercase absolute top-[calc(theme(spacing.1)*6.5)] right-1.5'>
                            Final
                          </div>
                        )}
                      </div>
                      {(step.outputs.length > 0 || step.finalStep) && (
                        <div className='relative flex flex-col items-center w-full mt-3'>
                          <div className='flex justify-center absolute top-2'>
                            <StepOutputsPill
                              placement='right'
                              step={step}
                              finalProduct={
                                step.finalStep
                                  ? {
                                      type: 'Ready to eat',
                                      name: formik.values.name,
                                      amount: getCookedAmount(formik, props.data),
                                    }
                                  : undefined
                              }
                            />
                          </div>
                          <div className='bg-cyan-900 h-10 w-px' />
                          <div className='border-t-cyan-900 border-b-cyan-900 w-0 h-0 border-l-[3px] border-r-[3px] border-t-[5px] border-transparent -mt-1' />
                        </div>
                      )}
                      {step.finalStep ? (
                        <div
                          title={`${formik.values.name} ${getCookedAmount(formik, props.data).value}${
                            getCookedAmount(formik, props.data).unit.name
                          }`}
                          className='grid grid-cols-[auto_50px] bg-cyan-700 font-semibold text-white px-2 py-1 rounded-lg shadow-[0_0_2px_0_rgba(0,0,0,0.2)]'
                        >
                          <div className='truncate py-1.5'>Ready to eat</div>
                          <div className='flex items-center justify-center border-l border-white ml-1 pl-1 py-1.5 truncate'>
                            {getCookedAmount(formik, props.data).value}
                            {getCookedAmount(formik, props.data).unit.name}
                          </div>
                        </div>
                      ) : (
                        <div className='flex flex-col'>
                          {step.outputs
                            .filter(({ outputType }) => outputType.type === OutputType.IntermediateProduct)
                            .map((output) => (
                              <div
                                key={output.id}
                                ref={refs.current[output.id]}
                                className='text-center text-cyan-900 flex items-center font-semibold px-1'
                              >
                                <div className='flex-1' />
                                <div title={output.name} className='truncate p-2'>
                                  {output.name}
                                </div>
                                <div className='flex-1 flex justify-end'>
                                  {output.amount!.value}
                                  {output.amount!.unit.name}
                                </div>
                              </div>
                            ))}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>,
            );

          return (
            <>
              {sizeToPositions(props.data.layoutGrid).map((position) =>
                render(
                  position,
                  props.data.steps
                    .filter(({ displayName }) => displayName)
                    .find((step) => step.layoutPosition?.x === position.x && step.layoutPosition?.y === position.y),
                ),
              )}
              {props.data.steps
                .filter(
                  (step) =>
                    !step.layoutPosition ||
                    step.layoutPosition.x >= props.data.layoutGrid.width ||
                    step.layoutPosition.y >= props.data.layoutGrid.height,
                )
                .map((node) => render(undefined, node))}
            </>
          );
        })()}
      </div>
      {props.data.steps.flatMap((step) =>
        step.inputs
          .filter((input) =>
            props.data.steps
              .flatMap(({ outputs }) => outputs)
              .some(({ id, outputType }) => id === input.id && outputType.type === OutputType.IntermediateProduct),
          )
          .map((input) => {
            const output = props.data.steps.flatMap((step) => step.outputs).find(({ id }) => id === input.id)!;
            return (
              <StepLink
                key={`${step.id}.${input.id}`}
                scrollableRef={props.scrollableRef}
                fromStepRef={
                  refs.current[
                    props.data.steps
                      .flatMap((step) => step.outputs.map((output) => ({ step, output })))
                      .find(({ output }) => output.id === input.id)!.step.id
                  ]
                }
                toStepRef={refs.current[step.id]}
                fromRef={refs.current[input.id]}
                toRef={refs.current[`${step.id}.process`]}
                amount={input.amountValue + output.amount!.unit.name}
              />
            );
          }),
      )}
    </div>
  );
};

export const ConsumptionLocationAddStepButtons = (props: { facility: ConsumptionNode; productFormik: FormikContextType<ProductV3> }) => (
  <StepDetails
    parentNode={props.facility}
    onSave={({ values, closeModal }) => {
      addStep(values, props.facility, props.productFormik);
      closeModal();
    }}
  >
    <button
      type='button'
      className='group relative flex justify-center items-center gap-2 rounded-md p-2 bg-cyan-50 shadow-[0_0_2px_0_rgba(0,0,0,0.2)] active:scale-95'
    >
      <FontAwesomeIcon className='group-hover:invisible text-cyan-900' icon={duotone('pan-frying')} />
      <div className='group-hover:invisible'>Add preparation step</div>
      <FontAwesomeIcon icon={solid('plus')} className='absolute text-base hidden group-hover:block' />
    </button>
  </StepDetails>
);
