import { AgreementType, StaticEntity } from '.';
import { Entity } from './shared';

export enum SupplierService {
  Transport = 'transport',
  Ingredient = 'ingredient',
  Packaging = 'packaging',
  Manufacturer = 'manufacturer',
  Retailer = 'retailer',
  BrandOwner = 'brand_owner',
  Warehouse = 'warehouse',
  Material = 'material',
}

export const supplierServices = [
  { id: SupplierService.Transport, name: 'Transport services supplier' },
  { id: SupplierService.Ingredient, name: 'Ingredient supplier' },
  { id: SupplierService.Packaging, name: 'Packaging supplier' },
  { id: SupplierService.Manufacturer, name: 'Manufacturer' },
  { id: SupplierService.Retailer, name: 'Retailer' },
  { id: SupplierService.BrandOwner, name: 'Brand owner' },
  { id: SupplierService.Warehouse, name: 'Storage supplier' },
  { id: SupplierService.Material, name: 'Material supplier' },
];

export interface Supplier extends Entity {
  alias?: string;
  services: SupplierService[];
  agreement?: StaticEntity<AgreementType> & { default: boolean };
  controlledByCustomer: boolean;
  pinned: boolean;
  default: boolean;
  ownedFacilities: number;
  usedInProducts: number;
  updatedAt: string;
}

export enum FacilityType {
  Production = 'production',
  Warehouse = 'warehouse',
  Store = 'store',
  Consumption = 'consumption',
}

export const facilityOptions = [
  { id: FacilityType.Production, name: 'Production facility' },
  { id: FacilityType.Warehouse, name: 'Storage' },
  { id: FacilityType.Store, name: 'Store' },
  { id: FacilityType.Consumption, name: 'Consumption location' },
];
