import { TaggableField, TaggableFieldsContainer } from '../../TaggableFields';
import { Field, FieldProps, useFormikContext } from 'formik';
import { InputV3 } from '../../../../../../components/InputV3';
import { Entity, ProductType, ProductV3, StaticEntity } from '../../../../../../api';
import { UnitInputV3 } from '../../../../../../components/UnitInputV3';
import { useLists } from '../../../../../../hooks/useLists';
import { SelectV3 } from '../../../../../../components/SelectV3';
import { getProductUploadUrlsV3 } from '../../../../../../api/revision';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone, light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { MutableRefObject, SetStateAction, useState } from 'react';
import { Props as TitleBadgesProps } from '../../TitleBadges';
import cn from 'classnames';

interface Props extends TitleBadgesProps {
  title: string;
  ignoreValuesChange: MutableRefObject<boolean>;
}

export const Food = (
  props: Props & { triedSubmitting: () => void; skuIdInUse: boolean; setSkuIdInUse: (v: SetStateAction<boolean>) => void },
) => {
  const formik = useFormikContext<ProductV3>();
  const lists = useLists();

  const final = formik.values.productType === ProductType.Final;
  const internal = formik.values.productType === ProductType.Internal;

  const [uploadingImage, setUploadingImage] = useState(false);
  const [imageUploadError, setImageUploadError] = useState(false);
  const [showImage, setShowImage] = useState(false);

  return (
    <TaggableFieldsContainer>
      <div className='flex flex-col gap-y-6'>
        <div className='flex flex-col gap-y-3'>
          <div className='text-black font-semibold text-xl'>Tell us more about your product</div>
          <div className='text-base'>
            Product info will make your assessment accurate. Please fill out all the fields indicated with{' '}
            <span className='text-red-500 font-semibold'>*</span>
          </div>
        </div>
        <div className='grid grid-cols-3 gap-x-20 gap-y-6 items-start'>
          <TaggableField name='name'>
            {(model: FieldProps<string>) => (
              <div className='flex flex-col gap-1.5'>
                <div className='font-semibold text-xs'>
                  Product name <span className='text-red-500 font-semibold'>*</span>
                </div>
                <InputV3 autoFocus model={model} placeholder='Name your product…' />
              </div>
            )}
          </TaggableField>
          <TaggableField name='amount.value'>
            {(model: FieldProps<number>) => (
              <Field name='amount.unit'>
                {(unitModel: FieldProps<Entity>) => (
                  <div className='flex flex-col gap-1.5'>
                    <div className='font-semibold text-xs'>
                      Net amount <span className='text-red-500 font-semibold'>*</span>
                    </div>
                    <UnitInputV3 model={model} unit={{ model: unitModel, options: lists.units }} />
                  </div>
                )}
              </Field>
            )}
          </TaggableField>
          <TaggableField name='gtin'>
            {(model: FieldProps<string>) => (
              <div className='flex flex-col gap-1.5'>
                <div className='font-semibold text-xs'>GTIN</div>
                <InputV3 model={model} placeholder='Specify or leave empty…' />
              </div>
            )}
          </TaggableField>
          <TaggableField name='category'>
            {(model: FieldProps<Entity>) => (
              <div className='flex flex-col gap-1.5'>
                <div className='font-semibold text-xs'>Category {!internal && <span className='text-red-500 font-semibold'>*</span>}</div>
                <SelectV3<Entity> model={model} options={lists.categories} placeholder={internal ? 'Choose or leave empty…' : undefined} />
              </div>
            )}
          </TaggableField>
          <TaggableField name='skuId'>
            {(model: FieldProps<string>) => (
              <div className='flex flex-col gap-1.5'>
                <div className='font-semibold text-xs'>
                  Product ID <span className='text-red-500 font-semibold'>*</span>
                </div>
                <InputV3 model={model} error={props.skuIdInUse} placeholder='Your internal ID…' />
              </div>
            )}
          </TaggableField>
          <div className='flex flex-col gap-1.5'>
            <div className='font-semibold text-xs'>Image upload</div>
            <Field name='imageUrl'>
              {(model: FieldProps<string>) => (
                <label
                  className={cn(
                    'flex items-center rounded-lg bg-white border cursor-pointer',
                    imageUploadError ? 'border-f' : 'border-zinc-400',
                    {
                      'cursor-wait': uploadingImage,
                    },
                  )}
                >
                  <div className='w-full h-full px-3 py-1.5'>
                    {(() => {
                      if (imageUploadError) {
                        return <span className='text-f'>Maximum file size is 2MB</span>;
                      }

                      if (uploadingImage) {
                        return <span>Uploading…</span>;
                      }

                      if (!model.field.value) {
                        return <span className='text-neutral-400'>Upload file…</span>;
                      }

                      return <span className='text-dark'>File uploaded</span>;
                    })()}
                  </div>
                  <input
                    type='file'
                    accept='.jpg, .jpeg, .png'
                    disabled={uploadingImage}
                    className='invisible w-0 h-0'
                    onChange={(event) => {
                      const file = event.target.files![0];

                      if (file) {
                        formik.setFieldValue(model.field.name, null);
                        if (file.size > 1024 * 1024 * 2) {
                          setImageUploadError(true);
                          return;
                        }

                        setImageUploadError(false);
                        setUploadingImage(true);
                        const reader = new FileReader();
                        reader.readAsArrayBuffer(file);
                        reader.onload = () => {
                          getProductUploadUrlsV3(formik.values.id, file.name).call({
                            ok: async (urls) => {
                              await fetch(urls.uploadUrl, {
                                method: 'PUT',
                                body: reader.result,
                              });
                              formik.setFieldValue(model.field.name, urls.fileUrl);
                              setUploadingImage(false);
                            },
                            fail: () => {
                              setUploadingImage(false);
                            },
                          });
                        };
                      }
                    }}
                  />
                  <div className='flex items-center justify-center h-full w-14'>
                    {uploadingImage ? (
                      <FontAwesomeIcon className='h-4 aspect-square text-yellow-400' icon={duotone('loader')} />
                    ) : imageUploadError ? (
                      <FontAwesomeIcon className='h-4 aspect-square text-red-500' icon={light('triangle-exclamation')} />
                    ) : model.field.value ? (
                      <FontAwesomeIcon className='h-4 aspect-square text-green-600' icon={regular('circle-check')} />
                    ) : (
                      <FontAwesomeIcon className='h-4 aspect-square text-neutral-400' icon={regular('inbox-out')} />
                    )}
                  </div>
                </label>
              )}
            </Field>
          </div>
          <TaggableField name='foodType'>
            {(model: FieldProps<StaticEntity>) => (
              <div className='flex flex-col gap-1.5'>
                <div className='font-semibold text-xs'>Food type {!internal && <span className='text-red-500 font-semibold'>*</span>}</div>
                <SelectV3<StaticEntity>
                  model={model}
                  options={lists.foodTypes}
                  placeholder={internal ? 'Choose or leave empty…' : undefined}
                />
              </div>
            )}
          </TaggableField>
          <TaggableField name='conservation.requirement'>
            {(model: FieldProps<Entity>) => (
              <div className='flex flex-col gap-1.5'>
                <div className='font-semibold text-xs'>
                  Conservation requirements <span className='text-red-500 font-semibold'>*</span>
                </div>
                <SelectV3<Entity> model={model} options={lists.conservationRequirements} />
              </div>
            )}
          </TaggableField>
          <div className='row-span-2'>
            <Field name='imageUrl'>
              {(model: FieldProps<string>) =>
                model.field.value && (
                  <div
                    className={cn('flex items-center justify-center transition-opacity duration-300 opacity-0', {
                      'opacity-100': showImage,
                    })}
                  >
                    <div className='flex max-h-[144px] max-w-[144px] relative'>
                      <img
                        onLoad={() => setShowImage(true)}
                        src={model.field.value}
                        alt={formik.values.name ?? ''}
                        className={cn('rounded-xl h-[144px] w-[auto]', {
                          invisible: !showImage,
                        })}
                      />
                      <button
                        type='button'
                        className={cn(
                          'absolute -top-2 -right-2',
                          'flex justify-center items-center rounded-full w-5 h-5 aspect-square',
                          'text-white bg-brand active:scale-90',
                          { invisible: !showImage },
                        )}
                        onClick={() => {
                          formik.setFieldValue(model.field.name, null);
                        }}
                      >
                        <FontAwesomeIcon icon={regular('times')} />
                      </button>
                    </div>
                  </div>
                )
              }
            </Field>
          </div>
          {!internal ? (
            <TaggableField name='economicValue.price'>
              {(model: FieldProps<number>) => (
                <Field name='economicValue.currency'>
                  {(unitModel: FieldProps<Entity>) => (
                    <div className='flex flex-col gap-1.5'>
                      <div className='font-semibold text-xs'>Economic value</div>
                      <UnitInputV3 allowEmtpyOption model={model} unit={{ model: unitModel, options: lists.currencies }} />
                    </div>
                  )}
                </Field>
              )}
            </TaggableField>
          ) : (
            <div />
          )}
          {final ? (
            <TaggableField name='servings'>
              {(model: FieldProps<string>) => (
                <div className='flex flex-col gap-1.5'>
                  <div className='font-semibold text-xs'>Number of servings</div>
                  <InputV3 integer positive model={model} />
                </div>
              )}
            </TaggableField>
          ) : (
            <div />
          )}
        </div>
      </div>
    </TaggableFieldsContainer>
  );
};
