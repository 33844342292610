import { duotone, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import uniqBy from 'lodash/uniqBy';
import { Fragment, useState } from 'react';
import {
  IngredientNode,
  IngredientSupplierNode,
  Model,
  NodeType,
  PackagingNode,
  PackagingSupplierNode,
  ProductV3,
  ProductionNode,
  ProductionWarehouseNode,
  RawMaterialNode,
  TransportNode,
  MaterialSupplierNode,
} from '../../../../../../../api';
import {
  getIngredientsFromAll,
  getPackagingsFromAll,
  getProductionFacilitiesFromAll,
  getProductionWarehousesFromAll,
  getRawMaterialSuppliersFromAll,
  getTransportsFromAll,
} from '../../../../Details/dataModel';

interface Props {
  product: ProductV3;
  model: Model;
}

export const RawMaterials = (props: Props) => {
  const [expandedWrapper, setExpandedWrapper] = useState(true);
  const [expandedIngredients, setExpandedIngredients] = useState(true);
  const [expandedPackagings, setExpandedPackagings] = useState(true);
  const [expandedTransportLinks, setExpandedTransportLinks] = useState(true);

  const content = (
    <div className='flex flex-col gap-6'>
      <div className='flex flex-col gap-3'>
        <button onClick={() => setExpandedIngredients(!expandedIngredients)} className='flex items-center gap-2'>
          <FontAwesomeIcon className={cn('text-xs', { 'rotate-90': expandedIngredients })} icon={regular('chevron-right')} />
          <div className='h-7 aspect-square flex items-center justify-center bg-green-50 rounded-md border'>
            <FontAwesomeIcon icon={duotone('carrot')} />
          </div>
          <div>Ingredients</div>
        </button>

        {expandedIngredients && (
          <div className={cn('flex flex-col gap-3 rounded-xl shadow bg-neutral-50 p-3 border')}>
            {(() => {
              const allUniqEntries = uniqBy(
                [
                  ...props.product.nodes.filter(({ type }) => [NodeType.Ingredient, NodeType.IngredientSupplier].includes(type)),
                  ...props.model.nodes.filter(({ type }) => [NodeType.Ingredient, NodeType.IngredientSupplier].includes(type)),
                ],
                'id',
              );

              return allUniqEntries.map((record, index) => {
                const product = props.product.nodes.find(({ id }) => id === record.id) as IngredientNode;
                const model = props.model.nodes.find(({ id }) => id === record.id) as IngredientNode;

                return (
                  <div
                    key={index}
                    className={cn('grid grid-cols-3 border rounded-xl p-3', {
                      'border-emerald-700': !product && model,
                      'border-red-500': product && !model,
                    })}
                  >
                    <div className='flex flex-col text-sm col-span-2'>
                      <div className='grid grid-cols-3 items-center gap-y-3'>
                        <div className='col-span-3 grid grid-cols-3'>
                          <div className='col-span-2 flex items-center gap-x-2 self-center text-base'>
                            <div className='h-7 aspect-square flex items-center justify-center bg-green-50 rounded-md border'>
                              <FontAwesomeIcon icon={duotone('carrot')} />
                            </div>
                            {product?.displayName ?? model?.displayName}
                          </div>
                          {!product && model && <div className='text-emerald-600'>Added</div>}
                          {product && !model && <div className='text-red-500'>Removed</div>}
                        </div>

                        <div />
                        <div className='text-violet-900 text-sm font-semibold'>Original</div>
                        <div className='text-violet-900 text-sm font-semibold'>Modelled</div>

                        <div>Amount</div>
                        <div>
                          {product?.amount.value}
                          {product?.amount.unit.name}
                        </div>
                        <div className='flex'>
                          {(() => {
                            if ((!product && model) || (model && product && product.amount.value !== model.amount.value)) {
                              return (
                                <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold flex justify-self-start'>
                                  {model.amount.value}
                                  {model.amount.unit.name}
                                </div>
                              );
                            }

                            return '';
                          })()}
                        </div>

                        <div>Supplier(s)</div>
                        <div className='flex flex-wrap gap-2'>
                          {product?.nodes
                            .filter(({ type }) => type === NodeType.IngredientSupplier)
                            .map((item, i) => (
                              <div
                                key={i}
                                title={item.displayName}
                                className='rounded-full truncate py-0.5 px-1.5 bg-slate-200 font-semibold'
                              >
                                {item.displayName}
                              </div>
                            ))}
                        </div>

                        <div className='flex flex-wrap gap-2'>
                          {model?.nodes
                            .filter(({ type }) => type === NodeType.IngredientSupplier)
                            .filter(({ id }) => !product?.nodes.map(({ id }) => id).includes(id))
                            .map((item, i) => (
                              <div
                                key={i}
                                title={item.displayName}
                                className='rounded-full truncate py-0.5 px-1 bg-violet-900 text-white font-semibold'
                              >
                                {item.displayName}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div></div>

                    {(() => {
                      const allUniqSuppliers = uniqBy([...(product ? product.nodes : []), ...(model ? model.nodes : [])], 'id');

                      return (
                        <div className='flex flex-col gap-y-3 col-span-3 bg-white p-3 mt-3 rounded-lg shadow border'>
                          {allUniqSuppliers.map((supplier, i) => {
                            const productSupplierNode = product?.nodes.find(({ id }) => id === supplier.id);
                            const modelSupplierNode = model?.nodes.find(({ id }) => id === supplier.id);

                            return (
                              <div key={i}>
                                <div key={i} className='flex flex-col gap-2'>
                                  <div className='grid grid-cols-3'>
                                    <div className='grid grid-cols-3 gap-y-2 col-span-2 text-sm'>
                                      <div className='col-span-2'>{supplier.displayName}</div>
                                    </div>
                                  </div>

                                  <div className='grid grid-cols-3 gap-y-2'>
                                    <div className='grid grid-cols-3 col-span-2 items-center gap-y-1.5 text-sm'>
                                      <div>% Split</div>
                                      <div>{productSupplierNode ? productSupplierNode.splitPercent + '%' : ''}</div>

                                      {(() => {
                                        if (
                                          (!productSupplierNode && modelSupplierNode) ||
                                          (productSupplierNode &&
                                            modelSupplierNode &&
                                            productSupplierNode.splitPercent !== modelSupplierNode.splitPercent)
                                        ) {
                                          return (
                                            <div className='flex gap-2 items-center'>
                                              <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold flex justify-self-start'>
                                                <div>{modelSupplierNode.splitPercent}%</div>
                                              </div>
                                            </div>
                                          );
                                        }

                                        return '';
                                      })()}
                                    </div>
                                    <div />

                                    <div className='grid grid-cols-3 col-span-2 items-center gap-y-1.5 text-sm'>
                                      <div>Country</div>
                                      <div>{productSupplierNode?.location ? productSupplierNode?.location.name : ''}</div>

                                      {(() => {
                                        if (
                                          (!productSupplierNode && modelSupplierNode) ||
                                          (productSupplierNode &&
                                            modelSupplierNode &&
                                            productSupplierNode.location.id !== modelSupplierNode.location.id)
                                        ) {
                                          return (
                                            <div className='flex gap-2 items-center'>
                                              <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold flex justify-self-start'>
                                                <div>{modelSupplierNode?.location.name}</div>
                                              </div>
                                            </div>
                                          );
                                        }

                                        return '';
                                      })()}
                                    </div>
                                    <div />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })()}
                  </div>
                );
              });
            })()}
          </div>
        )}
      </div>

      {(() => {
        const allUniqEntries = uniqBy(
          [
            ...props.product.nodes.filter(({ type }) => [NodeType.Packaging, NodeType.PackagingSupplier].includes(type)),
            ...props.model.nodes.filter(({ type }) => [NodeType.Packaging, NodeType.PackagingSupplier].includes(type)),
          ],
          'id',
        );

        return (
          allUniqEntries.length > 0 && (
            <div className='flex flex-col gap-3'>
              <button onClick={() => setExpandedPackagings(!expandedPackagings)} className='flex items-center gap-2'>
                <FontAwesomeIcon className={cn('text-xs', { 'rotate-90': expandedPackagings })} icon={regular('chevron-right')} />
                <div className='h-7 aspect-square flex items-center justify-center bg-lime-50 rounded-md border'>
                  <FontAwesomeIcon icon={duotone('box-open')} />
                </div>
                <div>Packaging</div>
              </button>

              {expandedPackagings && (
                <div className='flex flex-col gap-3 rounded-xl shadow bg-neutral-50 p-3 border'>
                  {(() => {
                    return allUniqEntries.map((record, index) => {
                      const product = props.product.nodes.find(({ id }) => id === record.id) as PackagingNode | undefined;
                      const model = props.model.nodes.find(({ id }) => id === record.id) as PackagingNode | undefined;

                      const productItems = product?.materials;
                      const modelItems = model?.materials;

                      const allUniqItems = uniqBy([...(productItems ? productItems : []), ...(modelItems ? modelItems : [])], 'id');

                      const productPackagingSuppliers = product?.nodes.filter(({ type }) => type === NodeType.PackagingSupplier);
                      const modelPackagingSuppliers = model?.nodes.filter(({ type }) => type === NodeType.PackagingSupplier);

                      return (
                        <div
                          key={index}
                          className={cn('border p-3 rounded-xl', {
                            'border-emerald-700': !product && model,
                            'border-red-500': product && !model,
                          })}
                        >
                          <div className='grid grid-cols-3 gap-y-3 text-sm'>
                            <div className='grid grid-cols-3 col-span-3 items-center'>
                              <div className='grid grid-cols-3 col-span-2 gap-y-3'>
                                <div className='col-span-3 grid grid-cols-3'>
                                  <div className='col-span-2 flex items-center gap-x-2 self-center text-base'>
                                    <div className='h-7 aspect-square flex items-center justify-center bg-lime-50 rounded-md border'>
                                      <FontAwesomeIcon icon={duotone('box-open')} />
                                    </div>
                                    {product?.displayName ?? model?.displayName}
                                  </div>
                                  {!product && model && <div className='text-emerald-600 text-sm'>Added</div>}
                                  {product && !model && <div className='text-red-500 text-sm'>Removed</div>}
                                </div>

                                <div></div>
                                <div className='text-violet-900 text-sm font-semibold'>Original</div>
                                <div className='text-violet-900 text-sm font-semibold'>Modelled</div>

                                <div>Amount</div>

                                <div>
                                  {product?.amount.value}
                                  {product?.amount.unit.name}
                                </div>

                                <div className='flex'>
                                  {(() => {
                                    if ((!product && model) || (model && product && product.amount.value !== model.amount.value)) {
                                      return (
                                        <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold flex justify-self-start'>
                                          {model.amount.value}
                                          {model.amount.unit.name}
                                        </div>
                                      );
                                    }

                                    return '';
                                  })()}
                                </div>
                              </div>
                            </div>

                            {allUniqItems.map((item, i) => {
                              const productItem = productItems?.find(({ id }) => id === item.id);
                              const modelItem = modelItems?.find(({ id }) => id === item.id);

                              return (
                                <div key={i} className='flex flex-col gap-y-3 col-span-3 py-3 bg-white rounded-lg shadow border'>
                                  <div className='font-semibold text-sm pl-3'>{item.name}</div>
                                  <div className='grid grid-cols-3 gap-y-2'>
                                    <div className='grid grid-cols-3 col-span-2 items-center gap-y-1.5 text-sm'>
                                      <div className='pl-3'>% Composition</div>
                                      <div>{productItem && productItem.compositionPercent + '%'}</div>
                                      <div className='flex'>
                                        {(() => {
                                          if (
                                            (!productItem && modelItem) ||
                                            (productItem && modelItem && productItem.compositionPercent !== modelItem.compositionPercent)
                                          ) {
                                            return (
                                              <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold flex justify-self-start'>
                                                {modelItem.compositionPercent}%
                                              </div>
                                            );
                                          }

                                          return '';
                                        })()}
                                      </div>

                                      <div className='pl-3'>Sub-type</div>
                                      <div>{productItem?.subType.name}</div>
                                      <div className='flex'>
                                        {(() => {
                                          if (
                                            (!productItem && modelItem) ||
                                            (productItem && modelItem && productItem.subType.name !== modelItem.subType.name)
                                          ) {
                                            return (
                                              <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold flex justify-self-start'>
                                                {modelItem.subType.name}
                                              </div>
                                            );
                                          }

                                          return '';
                                        })()}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}

                            {(() => {
                              return (
                                <div className='col-span-2 mt-6'>
                                  <div className='grid grid-cols-3 items-center gap-y-3'>
                                    <div>Supplier(s)</div>
                                    <div className='flex flex-wrap gap-2'>
                                      {productPackagingSuppliers?.map((item, i) => (
                                        <div
                                          key={i}
                                          title={item.displayName}
                                          className='rounded-full truncate py-0.5 px-1.5 bg-slate-200 font-semibold'
                                        >
                                          {item.displayName}
                                        </div>
                                      ))}
                                    </div>

                                    <div className='flex flex-wrap gap-2'>
                                      {(() => {
                                        const modelUniqSuppliers = modelPackagingSuppliers?.filter(
                                          (supplier) => !productPackagingSuppliers?.find(({ id }) => supplier.id === id),
                                        );

                                        if (
                                          (!productPackagingSuppliers && modelUniqSuppliers) ||
                                          (productPackagingSuppliers && modelUniqSuppliers && modelUniqSuppliers.length > 0)
                                        ) {
                                          return modelUniqSuppliers.map((item, i) => (
                                            <div
                                              key={i}
                                              title={item.displayName}
                                              className='flex flex-start rounded-full truncate py-0.5 px-1 bg-violet-900 text-white font-semibold'
                                            >
                                              {item.displayName}
                                            </div>
                                          ));
                                        }

                                        return '';
                                      })()}
                                    </div>
                                  </div>
                                </div>
                              );
                            })()}
                          </div>

                          {(() => {
                            const allUniqSuppliers = uniqBy(
                              [
                                ...(productPackagingSuppliers ? productPackagingSuppliers : []),
                                ...(modelPackagingSuppliers ? modelPackagingSuppliers : []),
                              ],
                              'id',
                            );

                            return (
                              <div className='flex flex-col gap-y-3 bg-white p-3 mt-3 rounded-lg shadow border'>
                                {allUniqSuppliers.map((supplier, i) => {
                                  const productSupplierNode = productPackagingSuppliers?.find(({ id }) => id === supplier.id);
                                  const modelSupplierNode = modelPackagingSuppliers?.find(({ id }) => id === supplier.id);

                                  return (
                                    <div key={i} className='flex flex-col gap-2'>
                                      <div className='font-semibold text-sm'>
                                        {productSupplierNode?.displayName ?? modelSupplierNode?.displayName}
                                      </div>
                                      {(() => {
                                        return (
                                          <div className='grid grid-cols-3 gap-y-2'>
                                            <div className='grid grid-cols-3 col-span-2 items-center gap-y-1.5 text-sm'>
                                              <div>% Split</div>
                                              <div>{productSupplierNode ? productSupplierNode.splitPercent + '%' : ''}</div>

                                              {(() => {
                                                if (
                                                  (!productSupplierNode && modelSupplierNode) ||
                                                  (productSupplierNode &&
                                                    modelSupplierNode &&
                                                    productSupplierNode.splitPercent !== modelSupplierNode.splitPercent)
                                                ) {
                                                  return (
                                                    <div className='flex gap-2 items-center'>
                                                      <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold flex justify-self-start'>
                                                        <div>{modelSupplierNode ? modelSupplierNode.splitPercent + '%' : ''}</div>
                                                      </div>
                                                    </div>
                                                  );
                                                }

                                                return <div />;
                                              })()}
                                            </div>
                                            <div />

                                            <div className='grid grid-cols-3 col-span-2 items-center gap-y-1.5 text-sm'>
                                              <div>Country</div>
                                              <div>{productSupplierNode?.location ? productSupplierNode?.location.name : ''}</div>
                                              <div className='flex'>
                                                {(() => {
                                                  if (
                                                    (!productSupplierNode && modelSupplierNode) ||
                                                    (productItems &&
                                                      modelItems &&
                                                      productSupplierNode?.location.id !== modelSupplierNode?.location.id)
                                                  ) {
                                                    return (
                                                      <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold flex justify-self-start'>
                                                        <div>{modelSupplierNode?.location.name}</div>
                                                      </div>
                                                    );
                                                  }

                                                  return '';
                                                })()}
                                              </div>
                                            </div>
                                            <div />
                                          </div>
                                        );
                                      })()}
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          })()}
                        </div>
                      );
                    });
                  })()}
                </div>
              )}
            </div>
          )
        );
      })()}

      {(() => {
        const productRawMaterials = [...getIngredientsFromAll(props.product.nodes), ...getPackagingsFromAll(props.product.nodes)];

        const modelRawMaterials = [...getIngredientsFromAll(props.model.nodes), ...getPackagingsFromAll(props.model.nodes)];

        const productTransports = getTransportsFromAll(props.product.nodes);
        const modelTransports = getTransportsFromAll(props.model.nodes);

        const productSuppliers = getRawMaterialSuppliersFromAll(props.product.nodes);
        const modelSuppliers = getRawMaterialSuppliersFromAll(props.model.nodes);

        const productProductionsAndWarehouses = [
          ...getProductionFacilitiesFromAll(props.product.nodes),
          ...getProductionWarehousesFromAll(props.product.nodes),
        ];

        const modelProductionsAndWarehouses = [
          ...getProductionFacilitiesFromAll(props.model.nodes),
          ...getProductionWarehousesFromAll(props.model.nodes),
        ];

        const productTransportLinks = productRawMaterials.map((rawMaterial) => ({
          type: 'product',
          rawMaterial,
          suppliers: productSuppliers
            .filter(({ id }) => rawMaterial.nodes.map((item) => ({ ...item })).find((node) => node.id === id))
            .map((supplier) => ({
              ...supplier,
              consumers: productTransports
                .filter((item) => supplier.edges.find((id) => id === item.id))
                .map((transport) => ({
                  ...transport,
                  to: productProductionsAndWarehouses.filter((location) => transport.edges.includes(location.id)),
                })),
            })),
        }));

        const modelTransportLinks = modelRawMaterials.map((rawMaterial) => ({
          type: 'model',
          rawMaterial,
          suppliers: modelSuppliers
            .filter(({ id }) => rawMaterial.nodes.map((item) => ({ ...item })).find((node) => node.id === id))
            .map((supplier) => ({
              ...supplier,
              consumers: modelTransports
                .filter((item) => supplier.edges.find((id) => id === item.id))
                .map((transport) => ({
                  ...transport,
                  to: modelProductionsAndWarehouses.filter((location) => transport.edges.includes(location.id)),
                })),
            })),
        }));

        const allTransportLinks = [...productTransportLinks, ...modelTransportLinks];

        return (
          allTransportLinks.length > 0 && (
            <div className='flex flex-col gap-3'>
              <button onClick={() => setExpandedTransportLinks(!expandedTransportLinks)} className='flex items-center gap-2'>
                <FontAwesomeIcon className={cn('text-xs', { 'rotate-90': expandedTransportLinks })} icon={regular('chevron-right')} />
                <div className='h-7 aspect-square flex items-center justify-center bg-slate-200 rounded-md border'>
                  <FontAwesomeIcon icon={duotone('truck')} />
                </div>
                <div>Transport links</div>
              </button>
              {expandedTransportLinks && (
                <div className='flex flex-col gap-3'>
                  {(() => {
                    return uniqBy(allTransportLinks, 'rawMaterial.id').map((link, index) => {
                      const product = productTransportLinks.find(({ rawMaterial }) => rawMaterial.id === link.rawMaterial.id);
                      const model = modelTransportLinks.find(({ rawMaterial }) => rawMaterial.id === link.rawMaterial.id);

                      return (
                        <Fragment key={index}>
                          <RawMaterialsTransportLink
                            index={index}
                            allTransportLinks={allTransportLinks}
                            product={props.product}
                            model={props.model}
                            productTransport={product}
                            modelTransport={model}
                          />
                        </Fragment>
                      );
                    });
                  })()}
                </div>
              )}
            </div>
          )
        );
      })()}
    </div>
  );

  return (
    <div className='border print:border-none rounded-regular print:rounded-none shadow-regular print:shadow-none'>
      <button
        disabled
        onClick={() => {
          setExpandedWrapper(!expandedWrapper);
          if (expandedWrapper) {
            setExpandedIngredients(false);
            setExpandedPackagings(false);
            setExpandedTransportLinks(false);
          }
        }}
        className='grid grid-cols-3 items-center w-full px-8 print:px-0'
      >
        <div className='grid grid-cols-3 col-span-2 items-center'>
          <div className='text-xl font-semibold py-6 group-hover:text-brand'>Raw Materials</div>
        </div>
        <FontAwesomeIcon
          className='flex justify-self-end text-gray-500 text-xl print:hidden group-hover:text-brand invisible'
          icon={expandedWrapper ? regular('circle-minus') : regular('circle-plus')}
        />
      </button>
      {expandedWrapper && <div className='px-8 pb-8 text-dark print:hidden'>{content}</div>}
      <div className='px-8 print:px-0 pb-8 hidden print:block'>{content}</div>
    </div>
  );
};

type Destination = ProductionNode | ProductionWarehouseNode;

interface Consumers extends TransportNode {
  to: Destination[];
}

type RawMaterialsSupplier =
  | (IngredientSupplierNode & { consumers: Consumers[] })
  | (PackagingSupplierNode & { consumers: Consumers[] })
  | (MaterialSupplierNode & { consumers: Consumers[] });

type TransportPayload = {
  type: string;
  rawMaterial: RawMaterialNode;
  suppliers: RawMaterialsSupplier[];
};

interface RawMaterialsTransportLinksProps {
  index: number;
  product: ProductV3;
  model: Model;
  productTransport?: TransportPayload;
  modelTransport?: TransportPayload;
  allTransportLinks: TransportPayload[];
}

const RawMaterialsTransportLink = (props: RawMaterialsTransportLinksProps) => {
  const [expandedLink, setExpandedLink] = useState(true);
  const [expandedLegs, setExpandedLegs] = useState(true);

  const productSupplier = props.productTransport?.suppliers.find(({ id }) =>
    props.allTransportLinks
      .find((link) => props.productTransport?.rawMaterial.id === link.rawMaterial.id)
      ?.suppliers.find((currentSupplier) => currentSupplier.id === id),
  );
  const modelSupplier = props.modelTransport?.suppliers.find(({ id }) =>
    props.allTransportLinks
      .find((link) => props.modelTransport?.rawMaterial.id === link.rawMaterial.id)
      ?.suppliers.find((currentSupplier) => currentSupplier.id === id),
  );

  const productConsumers = props.allTransportLinks
    .find((item) => item.type === 'product' && item.rawMaterial.id === props.productTransport?.rawMaterial.id)
    ?.suppliers.find(({ id }) => id === productSupplier?.id)?.consumers;

  const modelConsumers = props.allTransportLinks
    .find((item) => item.type === 'model' && item.rawMaterial.id === props.modelTransport?.rawMaterial.id)
    ?.suppliers.find(({ id }) => id === modelSupplier?.id)?.consumers;

  return (
    <div
      className={cn('flex flex-col rounded-xl shadow bg-neutral-50 py-3 border', {
        'border-emerald-700': !productSupplier && modelSupplier,
        'border-red-500': productSupplier && !modelSupplier,
      })}
    >
      <button onClick={() => setExpandedLink(!expandedLink)} className='grid grid-cols-3 items-center'>
        <div className='flex flex-col text-sm col-span-2'>
          <div className='grid grid-cols-3 items-center gap-y-3'>
            <div className='flex items-center col-span-3 gap-x-2 self-center text-base pl-4 font-semibold'>
              <div className='h-7 aspect-square flex items-center justify-center bg-slate-200 rounded-md border'>
                <FontAwesomeIcon icon={duotone('truck')} />
              </div>
              Link {props.index + 1}
            </div>
          </div>
        </div>

        <FontAwesomeIcon
          className='flex justify-self-end text-gray-500 text-xl print:hidden group-hover:text-brand pr-3'
          icon={expandedLink ? regular('circle-minus') : regular('circle-plus')}
        />
      </button>

      {expandedLink && (
        <div className='grid grid-cols-3 text-sm px-6 pb-3'>
          <div className='grid grid-cols-3 col-span-2 gap-y-3'>
            <div className='col-span-2' />
            <div>
              {productSupplier && !modelSupplier && <div className='text-red-500'>Removed</div>}
              {!productSupplier && modelSupplier && <div className='text-emerald-600'>Added</div>}
            </div>

            <div></div>
            <div className='text-violet-900 text-sm font-semibold'>Original</div>
            <div className='text-violet-900 text-sm font-semibold'>Modelled</div>

            <div>From</div>
            <div>{productSupplier?.displayName}</div>
            <div className='flex'>
              {(() => {
                if (
                  (modelSupplier && !productSupplier) ||
                  (modelSupplier && productSupplier && modelSupplier.displayName !== productSupplier.displayName)
                ) {
                  return (
                    <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>{modelSupplier?.displayName}</div>
                  );
                }

                return '';
              })()}
            </div>

            <div>To</div>
            <div>
              {productConsumers &&
                productConsumers.map((consumer) => consumer.to.map((destination, i) => <div key={i}>{destination.displayName}</div>))}
            </div>
            <div className='flex'>
              {modelConsumers &&
                modelConsumers.map((consumer) =>
                  consumer.to
                    .filter((destination) => !productConsumers?.find((consumer) => !consumer.to.find((to) => to.id !== destination.id)))
                    .map((destination, i) => (
                      <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold' key={i}>
                        {destination.displayName}
                      </div>
                    )),
                )}
            </div>

            {(() => {
              const productTransportedItems = props.product.nodes.filter(({ id }) =>
                productConsumers?.find((consumer) => consumer.items.find((item) => item.id === id)),
              );

              const modelTransportedItems = props.model.nodes.filter(({ id }) =>
                modelConsumers?.find((consumer) => consumer.items.find((item) => item.id === id)),
              );

              return (
                <>
                  <div>Transported good</div>
                  <div>{productTransportedItems.map(({ displayName }) => displayName).join(', ')}</div>
                  <div className='flex'>
                    {modelTransportedItems
                      .filter((item) => !productTransportedItems.find(({ id }) => id === item.id))
                      .map(({ displayName }, i) => (
                        <div key={i} className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                          {displayName}
                        </div>
                      ))}
                  </div>
                </>
              );
            })()}

            <div>Supplier</div>
            <div>{productSupplier ? productSupplier?.consumers.map(({ displayName }) => displayName).join('') : ''}</div>
            <div className='flex'>
              {!productSupplier && modelSupplier
                ? modelSupplier?.consumers.map(({ displayName }, i) => (
                    <div key={i} className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                      {displayName}
                    </div>
                  ))
                : ''}
            </div>
          </div>
          <div />

          {(() => {
            const allLegs = [
              ...(productSupplier ? productSupplier.consumers : []).flatMap((consumer) => consumer.legs),
              ...(modelSupplier ? modelSupplier.consumers : []).flatMap((consumer) => consumer.legs),
            ];

            const productLegs = (productSupplier ? productSupplier.consumers : []).flatMap((consumer) => consumer.legs);
            const modelLegs = (modelSupplier ? modelSupplier.consumers : []).flatMap((consumer) => consumer.legs);

            const uniqLegs = uniqBy(allLegs, 'id');

            return (
              uniqLegs.length > 0 && (
                <Fragment>
                  <div className='col-span-3'>
                    <div className='flex items-center justify-between mt-3 font-semibold'>
                      <button
                        disabled={uniqLegs.length === 0}
                        onClick={() => setExpandedLegs(!expandedLegs)}
                        className='[&:not(:disabled)]:hover:text-violet-800'
                      >
                        Primary data
                      </button>
                      <FontAwesomeIcon size='sm' className={cn({ 'rotate-180': expandedLegs })} icon={solid('chevron-down')} />
                    </div>
                  </div>
                  {expandedLegs &&
                    uniqLegs.map((leg, i) => {
                      const productLeg = productLegs.find(({ id }) => leg.id === id);
                      const modelLeg = modelLegs.find(({ id }) => leg.id === id);

                      return (
                        <div
                          key={i}
                          className={cn('flex flex-col gap-y-3 col-span-3 bg-white py-3 mt-3 rounded-lg shadow border', {
                            'border-emerald-700': !productLeg && modelLeg,
                            'border-red-500': productLeg && !modelLeg,
                          })}
                        >
                          <div className='grid grid-cols-3'>
                            <div className='col-span-2 grid grid-cols-3 gap-y-2'>
                              <div className='col-span-2 pl-3 font-semibold'>Leg {i + 1}</div>
                              <div>
                                {!productLeg && modelLeg && <div className='text-emerald-600'>Added</div>}
                                {productLeg && !modelLeg && <div className='text-red-500'>Removed</div>}
                              </div>

                              <div className='pl-3'>Distance (km)</div>
                              <div>{productLeg?.distance}</div>
                              <div className='flex'>
                                {(() => {
                                  if ((!productLeg && modelLeg) || (productLeg && modelLeg && productLeg.distance !== modelLeg.distance)) {
                                    return (
                                      <div key={i} className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                        {modelLeg?.distance}
                                      </div>
                                    );
                                  }

                                  return '';
                                })()}
                              </div>

                              <div className='pl-3'>Mode of transport</div>
                              <div>{productLeg?.mode.name}</div>
                              <div className='flex'>
                                {(() => {
                                  if (productLeg && !modelLeg) {
                                    return '';
                                  } else if (
                                    (!productLeg && modelLeg) ||
                                    (productLeg && modelLeg && productLeg.mode.name !== modelLeg.mode.name)
                                  ) {
                                    return (
                                      <div key={i} className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                        {modelLeg?.mode.name}
                                      </div>
                                    );
                                  }

                                  return '';
                                })()}
                              </div>
                            </div>
                            <div />
                          </div>
                        </div>
                      );
                    })}
                </Fragment>
              )
            );
          })()}
        </div>
      )}
    </div>
  );
};
