import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import { useRef, useState } from 'react';
import { applyDefaults, ProductClass, ProductV3 } from '../../../../api';
import { TooltipV3 } from '../../../../components/TooltipV3';
import { canApplyDefaults } from './dataModel';

export enum Layout {
  Graph,
  List,
}

interface Props {
  canUndo: boolean;
  canRedo: boolean;
  onUndo: () => void;
  onRedo: () => void;
  onAppliedDefaults: () => void;
}

export const Controls = (props: Props) => {
  const formik = useFormikContext<ProductV3>();
  const [applyingDefaults, setApplyingDefaults] = useState(false);
  const [applyDefaultsError, setApplyDefaultsError] = useState('');
  const applyDefaultsErrorClearTimeoutId = useRef<number>();

  return (
    <>
      <div className='flex-1 flex items-center gap-4'>
        <div className='flex gap-2'>
          <button
            type='button'
            disabled={!props.canUndo}
            className='flex justify-center items-center size-8 rounded-lg bg-slate-100 text-[#220066] [&:active:not(:disabled)]:scale-90'
            onClick={props.onUndo}
          >
            <FontAwesomeIcon icon={regular('rotate-left')} />
          </button>
          <button
            type='button'
            disabled={!props.canRedo}
            className='flex justify-center items-center size-8 rounded-lg bg-slate-100 text-[#220066] [&:active:not(:disabled)]:scale-90'
            onClick={props.onRedo}
          >
            <FontAwesomeIcon icon={regular('rotate-right')} />
          </button>
        </div>
      </div>
      <div className='flex-1 flex h-full'>
        <TooltipV3
          placement='left'
          disabled={canApplyDefaults(formik) && !applyDefaultsError}
          parentControl={
            applyDefaultsError
              ? {
                  open: true,
                  onOpenChange: () => {},
                }
              : undefined
          }
          content={
            <div
              className={cn(
                'rounded-lg text-xs text-zinc-800 px-2 py-1 w-[420px] shadow-[0_0_4px_rgba(0,0,0,0.15)]',
                applyDefaultsError ? 'bg-red-50' : 'bg-[#DACEFD]',
              )}
            >
              {applyDefaultsError ||
                'Defaults can only be applied to products with raw materials and one final production facility. Remove anything else to be able to use this feature.'}
            </div>
          }
        >
          <button
            type='button'
            className={cn(
              'flex flex-1 h-full items-center rounded-lg bg-slate-100 gap-2 whitespace-nowrap px-2 font-semibold disabled:text-opacity-25',
              applyDefaultsError ? 'text-red-500' : 'text-violet-950',
              { hidden: formik.values.productClass === ProductClass.Packaging },
              { 'disabled:cursor-wait': applyingDefaults },
            )}
            disabled={!canApplyDefaults(formik) || applyingDefaults}
            onClick={() => {
              clearTimeout(applyDefaultsErrorClearTimeoutId.current);
              setApplyDefaultsError('');
              setApplyingDefaults(true);
              applyDefaults(formik.values.id).call({
                ok: ({ product, error }) => {
                  setApplyingDefaults(false);

                  if (error) {
                    setApplyDefaultsError(error.message);
                    applyDefaultsErrorClearTimeoutId.current = window.setTimeout(() => setApplyDefaultsError(''), 7_000);
                  } else {
                    formik.setValues(product);
                    props.onAppliedDefaults();
                  }
                },
                fail: () => {
                  setApplyingDefaults(false);
                },
              });
            }}
          >
            <FontAwesomeIcon className='size-5' icon={light('window-restore')} />
            Apply defaults
          </button>
        </TooltipV3>
      </div>
    </>
  );
};
