import { Dispatch, Fragment, PropsWithChildren, ReactNode, SetStateAction, useEffect, useReducer, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Filters } from '../../../../components/Filters';
import { TooltipV3 } from '../../../../components/TooltipV3';
import { RevisionsModal } from '../Report/RevisionsModal';
import { ModalApi, ModalV3 } from '../../../../components/ModalV3';
import { LimitTooltip } from '../../../../components/LimitTooltip';
import { Menu, MenuApi } from '../../../../components/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone, light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ReadOnlyWarning } from '../../../../components/ReadOnlyWarning';
import InfiniteScroll from '../../../../components/InfiniteScroll';
import { ProductRevisionPreview } from '../../../../api/revision';
import { useAppRoutes } from '../../../../hooks/useAppRoutes';
import { useLocation, useNavigate } from 'react-router';
import { useProfile } from '../../../../hooks/useProfile';
import { useEffectOnNextRenders } from '../../../../hooks/useEffectOnNextRenders';
import { useDebounce } from '../../../../hooks/useDebounce';
import { createProduct } from '../Details/dataModel';
import { roundToLong, simplify } from '../../shared';
import { formatInTimeZone } from 'date-fns-tz';
import orderBy from 'lodash/orderBy';
import { format, parseISO } from 'date-fns';
import cn from 'classnames';
import {
  createProductModelV3,
  deleteProductModelV3,
  deleteProductV3,
  duplicateProduct,
  getListOfRevisionsV3,
  getProductModelsV3,
  Grade,
  ImpactDeltaType,
  ProductGeneral,
  ProductModelListItem,
  ProductStage,
  ProductState,
  ProductType,
  ProductWarning,
  Scope,
  searchProducts,
  SearchProductsParams,
} from '../../../../api';
import isEqual from 'lodash/isEqual';

enum View {
  Production = 'production',
  Consumer = 'consumer',
}

interface ProductOrModel {
  product: ProductGeneral;
  model?: ProductModelListItem;
}

const types = [
  { value: ProductType.Final, label: 'Final' },
  { value: ProductType.Intermediate, label: 'Intermediate' },
  { value: ProductType.Internal, label: 'Internal' },
];

const stages = [
  { value: ProductStage.Production, label: 'Production' },
  { value: ProductStage.Development, label: 'Development' },
];

const defaultSearchParams: SearchProductsParams = {
  scope: Scope.Workspace,
  pageSize: 50,
  sortBy: 'updatedAt',
  sortAscending: false,
  contains: '',
  pageToken: '',
};

export const List = () => {
  const profile = useProfile();
  const navigate = useNavigate();
  const location = useLocation();
  const { routes } = useAppRoutes();

  const [selectedView, setSelectedView] = useState<View>(View.Production);

  const [loading, setLoading] = useState(true);

  const [creating, setCreating] = useState(false);
  const [createLimit, setCreateLimit] = useState(false);

  const [compare, setCompare] = useState(false);
  const [selectedForCompare, setSelectedForCompare] = useState(new Array<ProductOrModel>());

  const scrollableRef = useRef<HTMLDivElement>(null);

  const [reloadCounter, reload] = useReducer((state) => state + 1, 0);

  const [nextPageToken, setNextPageToken] = useState<string>('');

  const [results, setResults] = useState<{ list: ProductGeneral[]; totalCount: number; state?: ProductState }>({
    list: [],
    totalCount: 0,
    state: defaultSearchParams.state,
  });

  const [searchInputValue, setSearchInputValue] = useState('');
  const debouncedSearchInputValue = useDebounce(searchInputValue, 300);

  const [searchParams, setSearchParams] = useState(() => {
    const urlSearchParams = new URLSearchParams(location.search);

    if (urlSearchParams.get('state') && urlSearchParams.get('stage')) {
      return {
        ...defaultSearchParams,
        state: urlSearchParams.get('state') as ProductState,
        stage: urlSearchParams.get('stage') as ProductStage,
      };
    }

    if (urlSearchParams.get('stage') && urlSearchParams.get('sortAscending') && urlSearchParams.get('sortBy')) {
      return {
        ...defaultSearchParams,
        stage: urlSearchParams.get('stage') as ProductStage,
        sortBy: urlSearchParams.get('sortBy') as SearchProductsParams['sortBy'],
        sortAscending: urlSearchParams.get('sortAscending') === 'true',
      };
    }

    if (urlSearchParams.get('stage')) {
      return {
        ...defaultSearchParams,
        stage: urlSearchParams.get('stage') as ProductStage,
      };
    }

    if (urlSearchParams.get('state')) {
      return {
        ...defaultSearchParams,
        state: urlSearchParams.get('state') as ProductState,
      };
    }

    if (urlSearchParams.get('sortBy')) {
      return {
        ...defaultSearchParams,
        sortBy: urlSearchParams.get('sortBy') as SearchProductsParams['sortBy'],
        sortAscending: urlSearchParams.get('sortAscending') === 'true',
      };
    }

    return defaultSearchParams;
  });

  const filterFields = [
    {
      label: 'Type',
      items: [
        { label: 'All', value: '' },
        ...types.filter((type) => searchParams.scope === Scope.Workspace && type.value !== ProductType.Internal),
      ],
      field: 'type',
      value: searchParams.type,
      disabled: selectedView === View.Consumer || searchParams.scope === Scope.Customer,
    },
    {
      label: 'Status',
      items: [{ label: 'All', value: '' }, ...stages],
      field: 'stage',
      value: searchParams.stage,
      disabled: false,
    },
  ];

  useEffectOnNextRenders(() => {
    setSearchParams((oldParams) => ({ ...oldParams, contains: debouncedSearchInputValue }));
  }, [debouncedSearchInputValue]);

  useEffect(() => {
    setLoading(true);
    searchProducts(searchParams, searchParams.scope === Scope.Workspace ? undefined : null).ok(
      ({ products, totalResults, nextPageToken }) => {
        setNextPageToken(nextPageToken);
        setResults({ list: products, totalCount: totalResults, state: searchParams.state });
        setLoading(false);
      },
    );
  }, [searchParams, reloadCounter]);

  const onCreate = () => {
    setCreating(true);
    createProduct().call({
      ok: ({ id, errorCode }) => {
        setCreating(false);

        if (errorCode) {
          setCreateLimit(true);
        } else {
          navigate(routes.products.productGraph(id));
        }
      },
      fail: () => {
        setCreating(false);
      },
    });
  };

  const fetchNextPage = () => {
    searchProducts({ ...searchParams, pageToken: nextPageToken }, searchParams.scope === Scope.Workspace ? undefined : null).ok(
      ({ products, totalResults, nextPageToken }) => {
        setNextPageToken(nextPageToken);
        setResults((oldResults) => ({ ...oldResults, list: [...oldResults.list, ...products], totalCount: totalResults }));
      },
    );
  };

  const title = !searchParams.state ? 'All Products' : searchParams.state === ProductState.Complete ? 'Products' : 'Draft Products';

  const sortIcon = (field: SearchProductsParams['sortBy']) => (
    <FontAwesomeIcon
      className={cn('text-brand', {
        invisible: field !== searchParams.sortBy,
      })}
      icon={searchParams.sortAscending ? regular('arrow-up') : regular('arrow-down')}
    />
  );

  const onSortClick = (field: SearchProductsParams['sortBy']) =>
    setSearchParams((oldParams) =>
      field === searchParams.sortBy
        ? { ...oldParams, sortAscending: !oldParams.sortAscending }
        : { ...oldParams, sortBy: field, sortAscending: true },
    );

  return (
    <div className='px-6 flex flex-col justify-between h-[calc(100vh-theme(spacing.20))]'>
      <Helmet title={title} />
      <div className='flex flex-col items-center justify-center border-b border-zinc-200 -mx-12 px-12 xl:px-0 xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.12))]'>
        <div className='flex justify-between items-center py-3 w-full xl:w-[theme(screens.xl)] xl:px-12'>
          <div className='flex items-center h-full gap-x-6'>
            <div className='text-xl font-semibold'>Food products</div>
            <div className='w-px h-full bg-zinc-300' />
            <div className='h-10 flex gap-x-6 items-center text-xs -mb-5'>
              {[
                {
                  label: 'all',
                  state: undefined,
                  active: !searchParams.state,
                },
                { label: 'completed', state: ProductState.Complete, active: searchParams.state === ProductState.Complete },
                { label: 'drafts', state: ProductState.Draft, active: searchParams.state === ProductState.Draft },
              ].map((item, i) => (
                <Fragment key={i}>
                  <button
                    onClick={() =>
                      setSearchParams((oldParams) => {
                        const newParams = { ...oldParams };

                        if (item.state) {
                          newParams.state = item.state;
                        } else {
                          delete newParams.state;
                        }

                        return newParams;
                      })
                    }
                    className={cn('flex items-center gap-2 h-full border-b-2 px-1', item.active ? 'border-brand' : 'border-white', {
                      'opacity-60': !item.active,
                    })}
                  >
                    <div className='font-semibold uppercase pb-3'>{item.label}</div>
                    {item.state === results.state && (
                      <div className='text-brand bg-lightBg2 py-0.5 mb-3 px-2 rounded-full'>{results.totalCount}</div>
                    )}
                  </button>
                </Fragment>
              ))}
            </div>
          </div>
          <div className='flex items-center gap-x-4'>
            <button type='button' className='flex items-center gap-2' onClick={() => setCompare((value) => !value)}>
              <div className={cn('flex w-7 h-4 p-0.5 rounded-full', compare ? 'justify-end bg-brand' : 'bg-gray-300')}>
                <div className='bg-white rounded-full size-3'></div>
              </div>
              <div>Compare</div>
            </button>
            <LimitTooltip
              enabled={createLimit}
              entityName='products limit'
              valueKey='maxProductSkuCount'
              onDismiss={() => setCreateLimit(false)}
            >
              <div className='flex relative'>
                <Menu
                  zIndex={10}
                  placement='bottom-end'
                  items={[
                    {
                      label: 'Create manually',
                      icon: regular('file-pen'),
                      onClick: onCreate,
                    },
                    {
                      label: 'Import file',
                      icon: regular('file-import'),
                      onClick: () => navigate(`${routes.products.list}/import`),
                    },
                  ]}
                >
                  {() => (
                    <div>
                      <ReadOnlyWarning show={!profile.selectedWorkspace.permissions.productManagement}>
                        <button
                          type='button'
                          disabled={creating || !profile.selectedWorkspace.permissions.productManagement}
                          className={cn(
                            'flex self-center border-2 bg-brand rounded-full px-4 py-1 text-white font-semibold',
                            '[&:active:not(:disabled)]:scale-95',
                            createLimit ? 'border-f' : 'border-brand',
                            creating
                              ? 'disabled:cursor-wait'
                              : 'disabled:bg-neutral-300 disabled:border-transparent disabled:cursor-not-allowed',
                          )}
                        >
                          New Product
                        </button>
                      </ReadOnlyWarning>
                    </div>
                  )}
                </Menu>
              </div>
            </LimitTooltip>
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-y-4 w-full py-4'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-x-3'>
            {(() => {
              const button = (label: string, scope: Scope) => (
                <button
                  type='button'
                  className={cn(
                    'text-center text-sm py-2 px-4',
                    searchParams.scope === scope ? 'text-violet-950 bg-slate-100' : 'text-zinc-500',
                  )}
                  onClick={() => {
                    delete searchParams.type;
                    setSearchParams((current) => ({
                      ...current,
                      scope,
                    }));
                  }}
                >
                  {label}
                </button>
              );

              return (
                <div className='grid grid-cols-[194px_1px_194px] border rounded-full overflow-hidden'>
                  {button('Workspace products', Scope.Workspace)}
                  <div className='w-px h-full bg-zinc-200' />
                  {button('Shared internal products', Scope.Customer)}
                </div>
              );
            })()}
            {(() => {
              const button = (icon: ReactNode, view: View) => (
                <TooltipV3
                  content={
                    <div className='px-2 py-1 bg-violet-950 text-white rounded-lg shadow text-xs leading-3'>
                      Switch to {view === View.Production ? 'production' : 'consumer'} view
                    </div>
                  }
                >
                  <button
                    type='button'
                    className={cn(
                      'text-center text-sm py-2 px-4',
                      view === selectedView ? 'text-violet-950 bg-slate-100 font-semibold' : 'text-zinc-500',
                    )}
                    onClick={() => {
                      setSelectedView(view);
                      setSearchParams((oldParams) => {
                        const newParams = { ...oldParams };
                        if (selectedView === View.Consumer) {
                          delete newParams.type;
                        } else {
                          newParams.type = ProductType.Final;
                        }
                        return newParams;
                      });
                    }}
                  >
                    {icon}
                  </button>
                </TooltipV3>
              );
              return searchParams.scope === Scope.Workspace ? (
                <div className='grid grid-cols-[52px_1px_52px] border rounded-full overflow-hidden'>
                  {button(
                    <FontAwesomeIcon icon={selectedView === View.Production ? regular('conveyor-belt') : light('conveyor-belt')} />,
                    View.Production,
                  )}
                  <div className='w-px h-full bg-zinc-200' />
                  {button(
                    <FontAwesomeIcon icon={selectedView === View.Consumer ? regular('basket-shopping') : light('basket-shopping')} />,
                    View.Consumer,
                  )}
                </div>
              ) : (
                <></>
              );
            })()}
          </div>
          <div className='flex gap-x-4 h-full'>
            <Filters<ProductType | ProductStage | undefined, SearchProductsParams>
              count={[searchParams.stage, searchParams.type].filter((value) => value).length}
              filterFields={filterFields}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
            <div>
              <div className='flex items-center relative h-full text-sm'>
                <input
                  autoFocus
                  type='search'
                  className='rounded-full border [&:not(:disabled)]:border-neutral-600 h-full pl-12 pr-6'
                  placeholder='Find Product…'
                  value={searchInputValue}
                  onChange={({ target }) => setSearchInputValue(target.value)}
                />
                <FontAwesomeIcon icon={regular('magnifying-glass')} className='absolute left-6 text-light' />
              </div>
            </div>
          </div>
        </div>
        {(() => {
          const pill = (url: string = '', name: string) => (
            <div className='border rounded-full p-1 pr-4 flex shrink-0 max-w-96 gap-x-2 items-center'>
              <div className='rounded-full flex items-center justify-items-center shrink-0 overflow-hidden'>
                {url ? (
                  <img className='size-8 shrink-0 rounded-full' src={url} alt='_' />
                ) : (
                  <div className='p-1 flex items-center justify-items-center'>
                    <FontAwesomeIcon className='size-6 shrink-0' icon={regular('building')} />
                  </div>
                )}
              </div>
              <div title={name} className='whitespace-nowrap truncate font-semibold'>
                {name}
              </div>
            </div>
          );

          return searchParams.scope === Scope.Workspace ? (
            <div className='flex gap-x-6 items-center'>
              {pill(profile.selectedWorkspace.logoUrl, profile.selectedWorkspace.name)}
              <div className='text-sm'>
                {searchParams.state !== ProductState.Draft && selectedView === View.Production
                  ? 'The table below presents the total environmental impact of manufacturing the net amount of each of your product (eg. For a 250g product, the impact of making those 250g)'
                  : 'The table below presents the corresponding consumer grades (A to G) of each of your product, assessed on a per kg basis to allow for comparison'}
              </div>
            </div>
          ) : (
            <div className='flex gap-x-6 items-center'>
              {pill(profile.user.logoUrl, profile.user.company)}
              <div className='text-sm'>
                Internal products are products made internally for use as input to other products. All internal products are shared within
                your company, this means that the internal products created in your workspace can be used as an input from any other
                workspaces.
              </div>
            </div>
          );
        })()}
      </div>
      <div className='h-full border-t overflow-hidden'>
        {(() => {
          const showNetAmount = selectedView === View.Production || searchParams.scope === Scope.Customer;
          const gridSchema = showNetAmount
            ? 'grid-cols-[4fr_2fr_2fr_2fr_2fr_2fr_2fr_2fr_30px_50px_40px]'
            : 'grid-cols-[4fr_2fr_2fr_2fr_4fr_2fr_2fr_30px_50px_40px]';
          return (
            <div ref={scrollableRef} className='h-full text-sm overflow-y-auto divide-y'>
              <div className='relative'>
                <div
                  className={cn(
                    gridSchema,
                    'z-[1] *:py-1 *:font-semibold items-center grid gap-x-2 sticky top-0 bg-white border-b border-zinc-200',
                  )}
                >
                  <button type='button' onClick={() => onSortClick('name')}>
                    Name {sortIcon('name')}
                  </button>
                  <div>Type</div>
                  <div>Status</div>
                  <button onClick={() => onSortClick('updatedAt')}>Updated {sortIcon('updatedAt')}</button>
                  {showNetAmount && <button onClick={() => onSortClick('amount')}>Net amount {sortIcon('amount')}</button>}
                  <div>ID</div>
                  <button className='whitespace-nowrap' onClick={() => onSortClick('firstPartyDataPercentage')}>
                    First-party data {sortIcon('firstPartyDataPercentage')}
                  </button>
                  <button onClick={() => onSortClick('totalImpact')} className='flex items-center gap-x-1'>
                    {selectedView === View.Production || searchParams.scope === Scope.Customer ? (
                      <>
                        <div className='flex flex-col'>
                          <div>Total impact</div>
                          <div className='text-xs font-semibold'>(impact points)</div>
                        </div>
                        {sortIcon('totalImpact')}
                      </>
                    ) : (
                      <div>Grade</div>
                    )}
                  </button>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>

                {results.list.length === 0 && isEqual(searchParams, defaultSearchParams) && !loading && (
                  <div className='h-[calc(100vh/3)] flex justify-center items-center'>
                    <div className='flex flex-col gap-6 rounded-2xl border py-6 px-12'>
                      <div className='flex align-middle self-center items-center justify-center h-10 aspect-square rounded-full bg-brandLime'>
                        <FontAwesomeIcon className='h-5 aspect-square' icon={light('lightbulb')} />
                      </div>
                      <div className='flex flex-col gap-2 items-center justify-center'>
                        <div className='text-xl font-semibold'>Next steps</div>
                        {searchParams.state === ProductState.Draft
                          ? 'Once you create a few products, you’ll be able to see and manage all of them on this table.'
                          : 'Complete your product drafts first before you can see and manage them on this table.'}
                      </div>
                      <ReadOnlyWarning show={!profile.selectedWorkspace.permissions.productManagement}>
                        <button
                          type='button'
                          disabled={creating || !profile.selectedWorkspace.permissions.productManagement}
                          className={cn(
                            'flex self-center border-2 border-brand bg-brand rounded-full px-4 py-1 text-white font-semibold',
                            '[&:active:not(:disabled)]:scale-95',
                            creating
                              ? 'disabled:cursor-wait'
                              : 'disabled:bg-neutral-300 disabled:border-transparent disabled:cursor-not-allowed',
                          )}
                          onClick={onCreate}
                        >
                          Create your first product
                        </button>
                      </ReadOnlyWarning>
                    </div>
                  </div>
                )}
                {scrollableRef.current && (
                  <InfiniteScroll
                    hasMore={nextPageToken !== ''}
                    next={fetchNextPage}
                    dataLength={results.list.length}
                    className='*:text-wrap'
                    loader={
                      <div className='py-3 text-center'>
                        <FontAwesomeIcon size='2x' pulse icon={duotone('loader')} />
                      </div>
                    }
                    scrollableTarget={scrollableRef.current}
                  >
                    {!loading &&
                      results.list.map((product, i) => (
                        <Fragment key={i}>
                          <Row
                            index={i}
                            reload={reload}
                            product={product}
                            compare={compare}
                            gridSchema={gridSchema}
                            selectedView={selectedView}
                            showNetAmount={showNetAmount}
                            searchString={searchInputValue}
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                            selectedForCompare={selectedForCompare}
                            setSelectedForCompare={setSelectedForCompare}
                          />
                        </Fragment>
                      ))}
                  </InfiniteScroll>
                )}
              </div>
            </div>
          );
        })()}
      </div>
      {compare && (
        <div className='mt-32 bg-white flex flex-col items-center justify-center border-t border-zinc-200 -mx-12 px-12 xl:px-0 xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.12))]'>
          <div className='z-10 fixed bottom-0 inset-x-0 flex justify-center bg-slate-50 shadow-[0_1px_10px_rgba(0,0,0,0.15)]'>
            <div className='px-12 py-5 flex gap-x-6 justify-between w-full max-w-screen-xl'>
              <div className='grid grid-cols-5 w-full gap-4'>
                {[0, 1, 2, 3, 4]
                  .map((index) => selectedForCompare[index])
                  .map((item, i) => (
                    <div
                      key={i}
                      className='flex flex-col justify-between h-20 px-4 py-2 border border-zinc-200 text-zinc-800 bg-slate-100 rounded-lg'
                    >
                      {item && (
                        <>
                          <div title={item.model?.title ?? item.product.name} className='font-semibold truncate'>
                            {item.model?.title ?? item.product.name}
                          </div>
                          {!item.model && (
                            <div className='flex items-center gap-2'>
                              <div className='font-semibold text-lg'>{simplify(item.product.impactPoints)}</div>
                              <div className='uppercase tracking-wider text-xs mt-0.5'>impact points</div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ))}
              </div>
              <div className='flex shrink-0 items-center gap-4'>
                <div className='uppercase tracking-wider text-xs font-semibold text-neutral-700'>
                  {selectedForCompare.length} {selectedForCompare.length === 1 ? 'product' : 'products'} selected
                </div>
                <NavLink
                  to={`${routes.products.compare}/${selectedForCompare
                    .map(({ product, model }) => `${product.workspaceSid}/${product.id}/${product.workspaceSid}/${model?.id ?? '-'}`)
                    .join('/')}`}
                  className={cn(
                    'flex self-center rounded-full px-4 py-1.5 text-white text-sm font-semibold active:scale-95',
                    selectedForCompare.length < 2 ? 'bg-neutral-300 pointer-events-none' : 'bg-brand',
                  )}
                >
                  Compare products
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

interface RowProps {
  index: number;
  showNetAmount: boolean;
  product: ProductGeneral;
  gridSchema: string;
  reload: () => void;
  selectedView: View;
  searchString: string;
  compare: boolean;
  searchParams: SearchProductsParams;
  setSearchParams: Dispatch<SetStateAction<SearchProductsParams>>;
  selectedForCompare: ProductOrModel[];
  setSelectedForCompare: Dispatch<SetStateAction<ProductOrModel[]>>;
}

const Row = (props: RowProps) => {
  const profile = useProfile();
  const [waiting, setWaiting] = useState(false);
  const [creatingModel, setCreatingModel] = useState(false);
  const [modelsCreateLimit, setModelsCreateLimit] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const product = props.product;
  const [productsCreateLimit, setProductsCreateLimit] = useState(false);
  const [models, setModels] = useState<ProductModelListItem[]>([]);

  const menuRef = useRef<MenuApi>(null);
  const [revisions, setRevisions] = useState<ProductRevisionPreview[]>([]);
  const [selectedRevision, setSelectedRevision] = useState<{ id: string; updatedAt: string } | undefined>();
  const bgColor = props.index % 2 ? 'bg-slate-100/50' : 'bg-white';

  const revisionConfirmationModalRef = useRef<ModalApi>(null);
  const deleteConfirmationModalRef = useRef<ModalApi>(null);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
  const revisionsModalRef = useRef<ModalApi>(null);
  const navigate = useNavigate();
  const { routes } = useAppRoutes();
  const currentWorkspace = profile.selectedWorkspace;

  const disabled =
    props.selectedForCompare.some((item) => item.product.productType !== product.productType) ||
    product.state === ProductState.Draft ||
    !product.reportCalculable;
  const compareMaxSelected = props.selectedForCompare.length === 5;

  useEffect(() => {
    setModels([]);
  }, [product.id]);

  useEffect(() => {
    if (expanded) {
      getProductModelsV3(product.id, currentWorkspace.workspaceSid !== product.workspaceSid ? product.workspaceSid : undefined).ok(
        (response) => {
          setModels(response.models);
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.id, expanded]);

  const onDelete = () => {
    setWaiting(true);
    deleteProductV3(product.id, product.workspaceSid).ok((err) => {
      if (!err?.errorCode) {
        deleteConfirmationModalRef.current?.close();
        props.reload();
        setWaiting(false);
      } else {
        setDeleteErrorMessage(err.message);
        setWaiting(false);
      }
    });
  };

  return (
    <div className={cn(props.gridSchema, 'grid gap-x-2 items-center *:truncate odd:bg-slate-50')}>
      <div className='h-12 flex items-center justify-between gap-1.5 whitespace-nowrap'>
        <div className='flex gap-x-2 items-center truncate'>
          {props.compare && (
            <TooltipV3
              content={
                <div className='bg-[#330099] text-white text-center text-xs rounded-lg p-2 whitespace-normal w-44'>
                  You can only compare products of the same type.
                </div>
              }
              placement='top-start'
              offsetCross={-8}
              disabled={!disabled}
            >
              <input
                type='checkbox'
                className='ml-3'
                checked={props.selectedForCompare.some(({ product, model }) => !model && product.id === props.product.id)}
                disabled={
                  disabled ||
                  (compareMaxSelected && !props.selectedForCompare.some(({ product, model }) => !model && product.id === props.product.id))
                }
                onChange={() =>
                  props.setSelectedForCompare((oldValues) =>
                    oldValues.some(({ product }) => product.id === props.product.id)
                      ? oldValues.filter(({ product }) => product.id !== props.product.id)
                      : [...oldValues, { product: props.product }],
                  )
                }
              />
            </TooltipV3>
          )}
          <button
            type='button'
            className={cn('flex justify-center items-center px-1 size-6 hover:text-brandDark transition-colors', {
              invisible: product.modelCount === 0 || product.state === ProductState.Draft,
            })}
            onClick={() => setExpanded(!expanded)}
          >
            <FontAwesomeIcon className={cn('transition-all', { 'rotate-90': expanded })} icon={regular('chevron-right')} />
          </button>
          <div title={product.name} className={cn('truncate', { 'text-zinc-400': !product.name })}>
            {props.product.name || '(Unnamed)'}
          </div>
        </div>
        {product.state === ProductState.Draft && (
          <div className='text-xs text-rose-500 bg-rose-50 px-1.5 py-px rounded-md font-semibold'>Draft</div>
        )}
        {product.state === ProductState.Complete && product.warnings.some(({ id }) => id === ProductWarning.InternalDraft) && (
          <TooltipV3
            placement='bottom-start'
            content={
              <div className='w-52 leading-normal whitespace-normal px-2 py-1 rounded-lg text-[10px] text-white bg-brandDarkPurple2 shadow-[0_0_4px_2px_rgba(0,0,0,0.15)]'>
                Interim results - this product includes some incomplete internal product (draft).
              </div>
            }
          >
            <div className='text-amber-600 bg-amber-50 px-2 py-px rounded-lg'>
              <FontAwesomeIcon icon={regular('exclamation-triangle')} />
            </div>
          </TooltipV3>
        )}
      </div>
      <div>{types.find(({ value }) => value === product.productType)!.label}</div>
      <div>{stages.find(({ value }) => value === product.stage)!.label}</div>
      <div>{format(new Date(product.updatedAt), 'dd/MM/yyyy')}</div>
      {props.showNetAmount && (
        <div>{product.amount?.value && product.amount?.unit && `${product.amount.value}${product.amount.unit.name}`}</div>
      )}
      <div title={product.skuId}>{product.skuId}</div>
      <div>{product.state === ProductState.Complete && `${product.firstPartyDataPercentage}%`}</div>
      <div>
        {props.selectedView === View.Production || props.searchParams.scope === Scope.Customer ? (
          product.impactPoints !== 0 ? (
            <div title={roundToLong(product.impactPoints)} className='truncate'>
              {simplify(product.impactPoints)}
            </div>
          ) : (
            <div className='text-neutral-400'>Not assessed</div>
          )
        ) : (
          (() => {
            if (product.state === ProductState.Draft) {
              return '—';
            }

            return product.totalGrade !== '' ? (
              <div
                className={cn('size-7 flex justify-center items-center text-white font-semibold rounded-full', {
                  'bg-aa': product.totalGrade === Grade.AA,
                  'bg-a': product.totalGrade === Grade.A,
                  'bg-b': product.totalGrade === Grade.B,
                  'bg-c': product.totalGrade === Grade.C,
                  'bg-d': product.totalGrade === Grade.D,
                  'bg-e': product.totalGrade === Grade.E,
                  'bg-f': product.totalGrade === Grade.F,
                  'bg-g': product.totalGrade === Grade.G,
                })}
              >
                {product.totalGrade}
              </div>
            ) : (
              <div className='text-neutral-400'>Not assessed</div>
            );
          })()
        )}
      </div>
      <NavLink
        target={currentWorkspace.workspaceSid === product.workspaceSid ? '_self' : '_blank'}
        to={(() => {
          if (currentWorkspace.workspaceSid === product.workspaceSid) {
            switch (props.selectedView) {
              case View.Production:
                return `${routes.products.productOverview.production(product.id)}`;
              case View.Consumer:
                return `${routes.products.productOverview.consumer(product.id)}`;
              default:
                return `${routes.products.productOverview.production(product.id)}`;
            }
          } else {
            switch (props.selectedView) {
              case View.Production:
                return routes.products.productOverview.production(product.id, `/workspaces/${product.workspaceSid}`);
              case View.Consumer:
                return routes.products.productOverview.consumer(product.id, `/workspaces/${product.workspaceSid}`);
              default:
                return routes.products.productOverview.production(product.id, `/workspaces/${product.workspaceSid}`);
            }
          }
        })()}
        className={cn('px-2 text-brandDarkPurple2 text-sm font-semibold hover:underline ', {
          'text-zinc-400 pointer-events-none': !product.reportCalculable,
        })}
      >
        <FontAwesomeIcon icon={regular('file-chart-column')} />
      </NavLink>
      <LimitTooltip
        enabled={modelsCreateLimit}
        entityName='models limit'
        valueKey='maxProductModelCount'
        onDismiss={() => setModelsCreateLimit(false)}
      >
        <div>
          <button
            type='button'
            disabled={creatingModel || product.state === ProductState.Draft}
            className={cn(
              '[&:hover:not(:disabled)]:underline text-xs font-semibold',
              modelsCreateLimit ? 'text-f' : 'text-brandDarkPurple2',
              creatingModel ? 'disabled:cursor-wait' : 'disabled:cursor-not-allowed',
              { 'disabled:text-zinc-400': product.state === ProductState.Draft },
            )}
            onClick={() => {
              setCreatingModel(true);
              createProductModelV3(product.id, product.workspaceSid).call({
                ok: ({ model, errorCode }) => {
                  if (errorCode) {
                    setModelsCreateLimit(true);
                    setCreatingModel(false);
                  } else {
                    setCreatingModel(false);
                    if (currentWorkspace.workspaceSid === product.workspaceSid) {
                      navigate(routes.products.modelGraph(product.id, model.id, `/workspaces/${product.workspaceSid}`));
                    } else {
                      props.reload();
                      window.open(routes.products.modelGraph(product.id, model.id, `/workspaces/${product.workspaceSid}`), '_blank');
                    }
                  }
                },
                fail: () => setCreatingModel(false),
              });
            }}
          >
            Model
          </button>
        </div>
      </LimitTooltip>
      <LimitTooltip
        enabled={productsCreateLimit}
        entityName='products limit'
        valueKey='maxProductSkuCount'
        onDismiss={() => setProductsCreateLimit(false)}
      >
        <div>
          <ModalV3
            noCloseOnConfirm
            noCloseOnCancel
            size='narrow-'
            ref={revisionConfirmationModalRef}
            confirmLabel='View product'
            cancelLabel='Close'
            title='Restore complete'
            body={
              selectedRevision && (
                <div className='-mt-6'>
                  <span>
                    Your product was successfully restored to an earlier version from{' '}
                    <span className='font-semibold'>
                      {formatInTimeZone(parseISO(selectedRevision.updatedAt), 'UTC', 'd MMM yy HH:mm')}.
                    </span>
                  </span>
                </div>
              )
            }
            onClose={() => {
              setSelectedRevision(undefined);
              revisionConfirmationModalRef.current!.close();
              menuRef.current!.close();
              props.reload();
            }}
            onCancel={() => {
              setSelectedRevision(undefined);
              revisionConfirmationModalRef.current!.close();
              menuRef.current!.close();
              props.reload();
            }}
            onConfirm={() => {
              setSelectedRevision(undefined);
              if (currentWorkspace.workspaceSid === props.product.workspaceSid) {
                navigate(routes.products.productGraph(selectedRevision!.id));
              } else {
                window.open(routes.products.productGraph(selectedRevision!.id, `/workspaces/${props.product.workspaceSid}`), '_blank');
              }
            }}
          />

          {(() => {
            const menuEnabled = profile.selectedWorkspace.permissions.productManagement;
            const button = (
              <button
                className={cn(
                  'flex items-center justify-center rounded-md size-6 transition-colors',
                  bgColor === 'bg-white' ? 'hover:bg-slate-50' : 'hover:bg-white',
                )}
              >
                <FontAwesomeIcon className='text-dark' icon={solid('ellipsis-vertical')} />
              </button>
            );

            return menuEnabled ? (
              <Menu
                ref={menuRef}
                zIndex={10}
                onOpen={() => {
                  setWaiting(true);
                  getListOfRevisionsV3(product.id, product.workspaceSid).ok(({ revisions }) => {
                    setWaiting(false);
                    setRevisions(revisions);
                  });
                }}
                placement='bottom-end'
                items={[
                  {
                    label: 'Edit',
                    icon: regular('pen-to-square'),
                    disabled: !profile.workspaces.find(({ workspaceSid }) => workspaceSid === product.workspaceSid)?.permissions
                      .productManagement,
                    onClick: () => {
                      if (currentWorkspace.workspaceSid === product.workspaceSid) {
                        navigate(`${routes.products.productGraph(product.id)}`);
                      } else {
                        window.open(`${routes.products.productGraph(product.id, `/workspaces/${product.workspaceSid}`)}`, '_blank');
                      }
                    },
                  },
                  {
                    waiting,
                    label: 'Edit history',
                    icon: regular('clock-rotate-left'),
                    disabled: waiting || revisions.length === 0 || currentWorkspace.workspaceSid !== product.workspaceSid,
                    modal: (button, onOpenChange) => {
                      return (
                        <RevisionsModal
                          menuRef={menuRef}
                          modalRef={revisionsModalRef}
                          product={product}
                          onOpenChange={onOpenChange}
                          onViewClick={({ id, revisionNumber }) => {
                            if (revisionNumber === 0) {
                              if (currentWorkspace.workspaceSid === product.workspaceSid) {
                                window.open(`${routes.products.productGraph(id)}`, '_blank')!.open();
                              } else {
                                window.open(`${routes.products.productGraph(id, `/workspaces/${product.workspaceSid}`)}`, '_blank')!.open();
                              }
                            } else {
                              if (currentWorkspace.workspaceSid === product.workspaceSid) {
                                navigate(routes.products.revisions.overview(id, revisionNumber));
                              } else {
                                window.open(
                                  routes.products.revisions.overview(id, revisionNumber, `/workspaces/${product.workspaceSid}`),
                                  '_blank',
                                );
                              }
                            }
                          }}
                          revisionsList={revisions}
                          onRestored={(data) => {
                            setSelectedRevision(data);
                            revisionConfirmationModalRef.current!.open();
                          }}
                        >
                          {button}
                        </RevisionsModal>
                      );
                    },
                  },
                  {
                    label: 'Duplicate',
                    icon: regular('clone'),
                    disabled: !profile.workspaces.find(({ workspaceSid }) => workspaceSid === product.workspaceSid)?.permissions
                      .productManagement,
                    onClick: () =>
                      duplicateProduct(product.id, product.workspaceSid).call({
                        ok: ({ id, errorCode }) => {
                          props.reload();
                          if (errorCode) {
                            setProductsCreateLimit(true);
                          } else {
                            if (currentWorkspace.workspaceSid === product.workspaceSid) {
                              navigate(`${routes.products.productGraph(id)}/info?duplicated=true`);
                            } else {
                              window.open(
                                `${routes.products.productGraph(id, `/workspaces/${product.workspaceSid}`)}/info?duplicated=true`,
                                '_blank',
                              );
                            }
                          }
                        },
                      }),
                  },
                  {
                    label: 'Delete',
                    icon: regular('trash-can'),
                    disabled: !profile.workspaces.find(({ workspaceSid }) => workspaceSid === product.workspaceSid)?.permissions
                      .productManagement,
                    modal: (button, onOpenChange) => (
                      <ModalV3
                        confirmLabel='Delete'
                        onOpenChange={onOpenChange}
                        title={`Delete ${product.name || 'Draft'}?`}
                        ref={deleteConfirmationModalRef}
                        noCloseOnConfirm={deleteErrorMessage !== ''}
                        onConfirm={() => {
                          onDelete();
                        }}
                        cancelLabel={deleteErrorMessage ? 'OK' : undefined}
                        disableConfirm={waiting}
                        onCancel={() => {
                          deleteErrorMessage ? setDeleteErrorMessage('') : deleteConfirmationModalRef.current?.close();
                        }}
                        body={
                          <div className='text-base -mt-6'>
                            {deleteErrorMessage ? deleteErrorMessage : 'You will not be able to recover it.'}
                          </div>
                        }
                      >
                        {button}
                      </ModalV3>
                    ),
                  },
                ]}
              >
                {() => button}
              </Menu>
            ) : (
              <ReadOnlyWarning show>{button}</ReadOnlyWarning>
            );
          })()}
        </div>
      </LimitTooltip>

      {expanded && models.length > 0 && (
        <>
          <div
            className={cn(
              'ml-8 gap-x-2',
              props.showNetAmount ? 'col-span-11' : 'col-span-10',
              'py-2 font-semibold text-xs text-brand uppercase',
            )}
          >
            models
          </div>
          {orderBy(models, ({ updatedAt }) => updatedAt, 'desc').map((model, i) => (
            <Fragment key={i}>
              <div className='flex items-center gap-x-2 col-span-3 gap-1 truncate ml-8 py-2'>
                {props.compare && (
                  <TooltipV3
                    content={
                      <div className='bg-[#330099] text-white text-center text-xs rounded-lg p-2 w-44'>
                        You can only compare products of the same type.
                      </div>
                    }
                    placement='top-start'
                    offsetCross={-8}
                    disabled={!disabled}
                  >
                    <div>
                      <input
                        type='checkbox'
                        checked={props.selectedForCompare.some((selected) => selected.model?.id === model.id)}
                        disabled={
                          props.selectedForCompare.find(
                            (item) => item.model && item.model?.id !== model.id && item.product.id === product.id,
                          ) !== undefined ||
                          disabled ||
                          (compareMaxSelected && !props.selectedForCompare.some((selected) => selected.model?.id === model.id))
                        }
                        onChange={() =>
                          props.setSelectedForCompare((oldValues) =>
                            oldValues.some((selected) => selected.model?.id === model.id)
                              ? oldValues.filter((selected) => selected.model?.id !== model.id)
                              : [...oldValues, { product: props.product, model }],
                          )
                        }
                      />
                    </div>
                  </TooltipV3>
                )}
                <div className='flex flex-col gap-1 truncate'>
                  <div className='truncate' title={model.title}>
                    {model.title}
                  </div>
                  <div className='flex items-center text-zinc-500 gap-x-2 text-xs'>
                    <FontAwesomeIcon icon={light('user')} />
                    {model.author}
                  </div>
                </div>
              </div>
              <div>{format(new Date(model.updatedAt), 'dd/MM/yyyy')}</div>
              {props.showNetAmount && <div></div>}
              <div></div>
              <div>
                {model.proposedChanges} change{model.proposedChanges === 1 ? '' : 's'}
              </div>
              <div>
                {model.impactDelta.type === ImpactDeltaType.Zero ? (
                  <div className='text-neutral-400'>No change</div>
                ) : (
                  <div className={model.impactDelta.type === ImpactDeltaType.Lower ? 'text-emerald-700' : 'text-red-500'}>
                    {`${
                      {
                        [ImpactDeltaType.Higher]: '+',
                        [ImpactDeltaType.Lower]: '-',
                        [ImpactDeltaType.Zero]: '',
                      }[model.impactDelta.type]
                    }${model.impactDelta.formatted}% impact`}
                  </div>
                )}
              </div>
              <div className='px-2'>
                <NavLink
                  target={currentWorkspace.workspaceSid === product.workspaceSid ? '_self' : '_blank'}
                  to={(() => {
                    if (currentWorkspace.workspaceSid === product.workspaceSid) {
                      return routes.products.modelOverview(product.id, model.id);
                    } else {
                      return routes.products.modelOverview(product.id, model.id, `/workspaces/${product.workspaceSid}`);
                    }
                  })()}
                  className='text-brandDarkPurple2 text-sm font-semibold hover:underline'
                >
                  <FontAwesomeIcon icon={regular('file-chart-column')} />
                </NavLink>
              </div>
              <div></div>
              <div>
                <Menu
                  placement='bottom-end'
                  zIndex={10}
                  items={[
                    {
                      label: 'Edit',
                      icon: regular('pen-to-square'),
                      onClick: () => {
                        if (currentWorkspace.workspaceSid === product.workspaceSid) {
                          navigate(routes.products.modelGraph(product.id, model.id));
                        } else {
                          window.open(routes.products.modelGraph(product.id, model.id, `/workspaces/${product.workspaceSid}`), '_blank');
                        }
                      },
                    },
                    {
                      label: 'Delete',
                      icon: regular('trash-can'),
                      modal: (button, onOpenChange) => (
                        <DeleteModelConfirmationModal
                          productId={product.id}
                          modelId={model.id}
                          workspaceSid={product.workspaceSid}
                          setModels={setModels}
                          onOpenChange={onOpenChange}
                        >
                          {button}
                        </DeleteModelConfirmationModal>
                      ),
                    },
                  ]}
                >
                  {() => (
                    <button className='flex items-center justify-center rounded-md size-6 hover:bg-gray-50 transition-colors'>
                      <FontAwesomeIcon className='text-dark' icon={solid('ellipsis-vertical')} />
                    </button>
                  )}
                </Menu>
              </div>
              <div className={cn('h-px w-full bg-zinc-200 py-0', props.showNetAmount ? 'col-span-11' : 'col-span-10')}></div>
            </Fragment>
          ))}
        </>
      )}
    </div>
  );
};

interface DeleteModelConfirmationModalProps {
  productId: string;
  modelId: string;
  workspaceSid: string;
  setModels: Dispatch<SetStateAction<ProductModelListItem[]>>;
  onOpenChange: (open: boolean) => void;
}

const DeleteModelConfirmationModal = (props: PropsWithChildren<DeleteModelConfirmationModalProps>) => {
  const [waiting, setWaiting] = useState(false);

  return (
    <ModalV3
      size='narrow-'
      confirmLabel='Delete'
      disableConfirm={waiting}
      title={<div>Delete model?</div>}
      body={<div className='text-base -mt-6'>You will not be able to recover it.</div>}
      onOpenChange={props.onOpenChange}
      onConfirm={() => {
        setWaiting(true);
        deleteProductModelV3(props.productId, props.modelId, props.workspaceSid).ok(() =>
          getProductModelsV3(props.productId, props.workspaceSid).ok(({ models }) => {
            props.setModels(models);
            setWaiting(false);
          }),
        );
      }}
    >
      {props.children}
    </ModalV3>
  );
};
