import { duotone, light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Field, FieldArray, FieldProps, FormikContextType, useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import { PropsWithChildren, ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import {
  Amount,
  Entity,
  facilityNodeTypes,
  GenericNode,
  GenericNodeWithEdges,
  getLifeCycleForNodeType,
  getNodeTypesForLifecycleStage,
  getSuppliers,
  LifeCycleStage,
  NodeType,
  OutputType,
  ProductClass,
  ProductState,
  ProductType,
  ProductV3,
  RawMaterialSupplierNode,
  Supplier,
  SupplierService,
  SupplierWithSelectedAgreement,
  TransportLeg,
  TransportNode,
} from '../../../../api';
import { ModalForm, ModalFormSaveCallback } from '../../../../components/ModalForm';
import { SelectFooterAddButton } from '../../../../components/SelectFooterAddButton';
import { SelectV3 } from '../../../../components/SelectV3';
import { UnitInputV3 } from '../../../../components/UnitInputV3';
import { NewSupplierForm } from '../../Manage/Suppliers/NewSupplierForm';
import { useLists } from '../../../../hooks/useLists';
import { AgreementField } from './AgreementField';
import { DefaultBadge } from './Badges';
import { ModalHeaderRightBar } from './ModalHeaderRightBar';
import { TaggableField, TaggableFieldsContainer } from './TaggableFields';
import {
  getConsumptionLocations,
  getFacilityNodes,
  getIngredients,
  getMaterials,
  getOutputs,
  getPackagings,
  getPrimaryAndMaterialSuppliers,
  getProductionFacilities,
  getProductionWarehouses,
  getRawMaterials,
  getRawMaterialSuppliers,
  getStores,
  getTransports,
  newNodeId,
} from './dataModel';

interface Good extends Entity {
  amount: Amount;
}

interface FormData {
  id: string;
  type: NodeType.Transport;
  from: Entity;
  to: Entity;
  conservation: Entity;
  supplier: SupplierWithSelectedAgreement;
  goods: Good[];
  legs: TransportLeg[];
}

type Props = PropsWithChildren<{
  fromId?: string;
  data?: TransportNode;
  onSave: ModalFormSaveCallback<TransportNode, { fromId: string }>;
  onOpenChange?: (open: boolean) => void;
}>;

const getFromToNodeOptions = (formik: FormikContextType<ProductV3>) =>
  getFacilityNodes(formik).map((node) => ({ ...node, name: node.displayName }));

const isIntermediateProductionOption = (entity: Entity | undefined, formik: FormikContextType<ProductV3>) =>
  getProductionFacilities(formik).some(({ id, finalFacility }) => !finalFacility && id === entity?.id);

const isFinalProductionOption = (entity: Entity | undefined, formik: FormikContextType<ProductV3>) =>
  getProductionFacilities(formik).some(({ id, finalFacility }) => finalFacility && id === entity?.id);

const isProductionWarehouseOption = (entity: Entity | undefined, formik: FormikContextType<ProductV3>) =>
  getProductionWarehouses(formik).some(({ id }) => id === entity?.id);

const canAddLegs = (from: Entity | undefined, formik: FormikContextType<ProductV3>) => !getStores(formik).some(({ id }) => id === from?.id);

const isSupplierOption = (entity: Entity | undefined) => !!(entity as any as RawMaterialSupplierNode)?.supplier;

const toSupplierOptionId = (node: RawMaterialSupplierNode) => `${node.supplier.id}.${node.location.id}`;

const toSupplierOption = (node: RawMaterialSupplierNode) => ({
  ...node,
  id: toSupplierOptionId(node),
  name: node.displayName,
  supplier: node.supplier,
});

const isSupplierRequired = (from: Entity | undefined, to: Entity | undefined, formik: FormikContextType<ProductV3>) =>
  !(getStores(formik).some(({ id }) => id === from?.id) && getConsumptionLocations(formik).some(({ id }) => id === to?.id));

const hasTransportedItems = (from: Entity | undefined, formik: FormikContextType<ProductV3>) =>
  isSupplierOption(from) || isIntermediateProductionOption(from, formik) || isProductionWarehouseOption(from, formik);

const getRawMaterialGoodsOptions = (formik: FormikContextType<ProductV3>) =>
  getRawMaterials(formik).map((node) => ({ ...node, name: node.displayName }));

const getFromNode = (transport: TransportNode, formik: FormikContextType<ProductV3>) =>
  getPrimaryAndMaterialSuppliers(formik).find(({ edges }) => edges.includes(transport.id));

const getToNode = (transport: TransportNode, formik: FormikContextType<ProductV3>) =>
  formik.values.nodes.find(({ id }) => id === transport.edges[0]);

const getFinalProduct = (formik: FormikContextType<ProductV3>) => ({
  ...formik.values,
  amount: formik.values.bruttoAmount,
  name: 'Final product',
});

const findFrom = (condition: (node: GenericNodeWithEdges) => boolean, formik: FormikContextType<ProductV3>) => {
  const supplierNode = getRawMaterialSuppliers(formik).find(condition);

  if (supplierNode) {
    return toSupplierOption(supplierNode);
  }

  return getFromToNodeOptions(formik).find(condition);
};

const toFormData = (node: TransportNode | undefined, formik: FormikContextType<ProductV3>) =>
  node
    ? (() => {
        const from = findFrom(({ edges }) => edges.includes(node.id), formik)!;
        return {
          ...node,
          from,
          to: getFromToNodeOptions(formik).find(({ id }) => id === node.edges[0])!,
          goods: hasTransportedItems(from, formik)
            ? node.items.map((item) => {
                const good = [...getRawMaterialGoodsOptions(formik), ...getOutputs(formik)].find(({ id }) => id === item.id)!;
                return {
                  ...good,
                  amount: {
                    ...good.amount!,
                    value: item.amountValue,
                  },
                };
              })
            : [getFinalProduct(formik)],
        };
      })()
    : undefined;

const toNode = (data: FormData) => ({
  ...data,
  displayName: '',
  flagged: false,
  edges: [data.to.id],
  items: data.goods.map((good) => ({ ...good, amountValue: good.amount.value })),
});

const toFromId = (data: FormData, formik: FormikContextType<ProductV3>) => {
  if (isSupplierOption(data.from)) {
    return [
      ...(getIngredients(formik).find(({ id }) => id === data.goods[0].id)?.nodes ?? []),
      ...(getPackagings(formik).find(({ id }) => id === data.goods[0].id)?.nodes ?? []),
      ...(getMaterials(formik).find(({ id }) => id === data.goods[0].id)?.nodes ?? []),
    ].find((supplierNode) => toSupplierOptionId(supplierNode) === data.from.id)!.id;
  }

  return data.from.id;
};

export const TransportDetails = (props: Props) => {
  const formRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext<ProductV3>();

  return (
    <ModalForm<FormData>
      formRef={formRef}
      title={props.data ? 'Editing transport link' : 'New transport link'}
      body={<Body productFormik={formik} formRef={formRef} fromId={props.fromId} edit={!!props.data} />}
      headerRight={<ModalHeaderRightBar noFlag />}
      instructions={
        <div className='flex flex-col gap-4 p-2'>
          <div>Your product and its raw materials get transported across from one place to another throughout this life cycle.</div>
          <div>
            To create a new transport link, simply select the origin of the transport (ie. which supplier in which country, or which
            facility), and where it is going to. For this to work, you need to have added your locations in your main graph before!
          </div>
          <div>
            Specify what is being transported between these two places and who is responsible for this transport (this is key information
            for us to gather primary data in the future).
          </div>
          <div>
            If you have collected more detailed data about this journey, you can use the primary data section of the modal and create an
            unlimited number of legs. Simply specify the distance travelled (in km) and select a mode of transport.
          </div>
          <div>
            Just make sure you don’t forget any leg as there is currently no in-built validation to make sure the data entered is realistic.
            Whatever you add in there will override our secondary distance assumptions based on the ‘From’ and ‘To’ countries selected. If
            you know, you can also specify the names of the suppliers who transport the goods across the various legs of the journey.
          </div>
        </div>
      }
      emptyData={{
        id: newNodeId(),
        type: NodeType.Transport,
        from: findFrom(({ id }) => {
          const fromRawMaterial = getRawMaterials(formik).find(({ id }) => id === props.fromId);

          if (fromRawMaterial) {
            return fromRawMaterial.nodes.some((node) => node.edges.length === 0)
              ? fromRawMaterial.nodes.some((node) => id === node.id && node.edges.length === 0)
              : fromRawMaterial.nodes[0].id === id;
          }

          return id === props.fromId;
        }, formik) as any as Entity,
        to: undefined as any as Entity,
        conservation:
          getIngredients(formik).find(({ id }) => id === props.fromId)?.ingredient?.conservation ??
          (getPackagings(formik).find(({ id }) => id === props.fromId)?.packaging?.conservation as Entity),
        supplier: undefined as any as SupplierWithSelectedAgreement,
        goods: new Array<Good>(),
        legs: new Array<TransportLeg>(),
      }}
      data={toFormData(props.data, formik)}
      metadata={formik.values.metadata}
      validationSchema={yup.object().shape({
        from: yup.object().required(),
        to: yup.object().required(),
        conservation: yup.object().when('from', {
          is: (from: Entity) => hasTransportedItems(from, formik) && formik.values.productClass === ProductClass.Food,
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.nullable(),
        }),
        supplier: yup.object().when(['from', 'to'], {
          is: (from: Entity, to: Entity) => isSupplierRequired(from, to, formik),
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.nullable(),
        }),
        goods: yup
          .array()
          .when('from', {
            is: (from: Entity) => hasTransportedItems(from, formik),
            then: (schema) => schema.min(1),
          })
          .of(
            yup.object().shape({
              id: yup.string().required(),
              amount: yup.object().shape({
                value: yup.number().positive().required(),
              }),
            }),
          ),
        legs: yup.array().of(
          yup.object().shape({
            distance: yup.number().min(0).required(),
            mode: yup.object().required(),
            supplier: yup.object().nullable(),
          }),
        ),
      })}
      entityName='transport link'
      saveLabel={formik.values.state === ProductState.Complete ? 'Confirm changes' : undefined}
      onSave={({ values, metadata, ...rest }) => {
        metadata!.user = metadata!.user.filter(({ path }) => !path.startsWith(`nodes/${values.id}/edges`));
        props.onSave({ values: toNode(values), fromId: toFromId(values, formik), metadata, ...rest });
      }}
      onOpenChange={props.onOpenChange}
    >
      {props.children}
    </ModalForm>
  );
};

const Body = (props: {
  productFormik: FormikContextType<ProductV3>;
  formRef: RefObject<HTMLDivElement>;
  fromId?: string;
  edit: boolean;
}) => {
  const lists = useLists();
  const formik = useFormikContext<FormData>();
  const productFormik = props.productFormik;
  const firstRender = useRef(true);
  const [legsExpanded, setLegsExpanded] = useState(formik.values.legs.length > 0);
  const internal = productFormik.values.productType === ProductType.Internal;
  const [newSupplierForm, setNewSupplierForm] = useState(false);
  const [newSupplierName, setNewSupplierName] = useState('');

  const fromToTypes = [
    {
      from: [NodeType.Production],
      to: facilityNodeTypes,
    },
    { from: [NodeType.ProductionWarehouse], to: [NodeType.Production] },
    {
      from: [NodeType.Warehouse],
      to: [...getNodeTypesForLifecycleStage(LifeCycleStage.Distribution), ...getNodeTypesForLifecycleStage(LifeCycleStage.Use)],
    },
    { from: [NodeType.Store], to: [NodeType.Consumption] },
  ];

  const otherTransports = getTransports(productFormik).filter(({ id }) => id !== formik.values.id);
  const fromRawMaterial = getRawMaterials(productFormik).find(({ id }) => id === props.fromId);
  const from = formik.values.from;
  const to = formik.values.to;
  const fromOptions = [
    ...uniqBy(
      getRawMaterialSuppliers(productFormik)
        // 1. suppliers connect only to production
        .filter(() => !to || getNodeTypesForLifecycleStage(LifeCycleStage.Production).includes((to as any as GenericNode).type))
        // 2. only suppliers from selected raw material
        .filter((option) => !fromRawMaterial || fromRawMaterial.nodes.some(({ id }) => id === option.id))
        .map(toSupplierOption),
      ({ id }) => id,
    ),
    ...getFromToNodeOptions(productFormik)
      .filter(({ id }) => !to || id !== to.id)
      // 3. only from supported types
      .filter((option) => fromToTypes.flatMap((item) => item.from).includes(option.type))
      // 4. only to from supported types
      .filter(
        ({ type }) => !to || fromToTypes.some((item) => item.from.includes(type) && item.to.includes((to as any as GenericNode).type)),
      )
      // 5. exclude existing transports
      .filter(
        ({ id }) =>
          !to ||
          !otherTransports.some(
            (transport) =>
              (getFromNode(transport, productFormik)?.id === id && getToNode(transport, productFormik)?.id === to.id) ||
              (getFromNode(transport, productFormik)?.id === to.id && getToNode(transport, productFormik)?.id === id),
          ),
      )
      .filter((option) => {
        if (to) {
          // 6. internal productions and warehouses connect to productions
          if ([NodeType.Production].includes((to as any as GenericNode).type)) {
            return isIntermediateProductionOption(option, productFormik) || isProductionWarehouseOption(option, productFormik);
          }

          // 7. internal productions and final productions in internal products connect to production warehouses
          if ([NodeType.ProductionWarehouse].includes((to as any as GenericNode).type)) {
            return isIntermediateProductionOption(option, productFormik) || (isFinalProductionOption(option, productFormik) && internal);
          }

          // 8. only final productions connect to next stages
          if (!getNodeTypesForLifecycleStage(LifeCycleStage.Production).includes((to as any as GenericNode).type)) {
            return isFinalProductionOption(option, productFormik);
          }
        }

        return true;
      }),
  ];
  const toOptions = getFromToNodeOptions(productFormik)
    .filter(({ id }) => !from || id !== from.id)
    // 1. suppliers connect only to production
    .filter(({ type }) => !from || !isSupplierOption(from) || getNodeTypesForLifecycleStage(LifeCycleStage.Production).includes(type))
    // 2. only to supported types
    .filter((option) => fromToTypes.flatMap((item) => item.to).includes(option.type))
    // 3. only to from supported types
    .filter(
      ({ type }) =>
        !from ||
        isSupplierOption(from) ||
        fromToTypes.some((item) => item.from.includes((from as any as GenericNode).type) && item.to.includes(type)),
    )
    // 4. exclude existing non-supplier transports
    .filter(
      ({ id }) =>
        !from ||
        isSupplierOption(from) ||
        !otherTransports.some(
          (transport) =>
            (getFromNode(transport, productFormik)?.id === from.id && getToNode(transport, productFormik)?.id === id) ||
            (getFromNode(transport, productFormik)?.id === id && getToNode(transport, productFormik)?.id === from.id),
        ),
    )
    .filter((option) => {
      if (!isSupplierOption(from)) {
        // 5. production warehouses connect to production facilities
        if (isProductionWarehouseOption(from, productFormik)) {
          return [NodeType.Production].includes(option.type);
        }

        // 6. internal productions connect to productions
        if (isIntermediateProductionOption(from, productFormik)) {
          return getNodeTypesForLifecycleStage(LifeCycleStage.Production).includes(option.type);
        }

        // 7. final productions in internal products connect to production warehouses
        if (isFinalProductionOption(from, productFormik) && internal) {
          return [NodeType.ProductionWarehouse].includes(option.type);
        }

        // 8. only final productions connect to next stages
        if (isFinalProductionOption(from, productFormik) && !internal) {
          return !getNodeTypesForLifecycleStage(LifeCycleStage.Production).includes(option.type);
        }
      }

      return true;
    });
  const goodsOptions: Entity[] = (() => {
    if (from) {
      if (isSupplierOption(from)) {
        return to
          ? getRawMaterialGoodsOptions(productFormik)
              .filter(({ nodes }) => nodes.some((supplierNode) => toSupplierOptionId(supplierNode) === from.id))
              // exclude existing transports
              .filter(
                ({ nodes }) =>
                  !otherTransports.some((transport) =>
                    nodes.some(
                      (supplierNode) =>
                        toSupplierOptionId(supplierNode) === from.id && supplierNode.id === getFromNode(transport, productFormik)?.id,
                    ),
                  ),
              )
          : [];
      }

      if (isIntermediateProductionOption(from, productFormik)) {
        return getProductionFacilities(productFormik)
          .filter(({ id }) => id === formik.values.from?.id)
          .flatMap(({ steps }) =>
            steps
              .filter(({ finalStep }) => finalStep)
              .flatMap(({ outputs }) => outputs)
              .filter(({ outputType: { type } }) => type === OutputType.IntermediateProduct),
          );
      }

      if (isProductionWarehouseOption(from, productFormik)) {
        return getProductionWarehouses(productFormik)
          .filter(({ id }) => id === formik.values.from?.id)
          .flatMap(({ items }) => items)
          .map((item) => ({ ...item, amountValue: item.amount.value }));
      }

      return [getFinalProduct(productFormik)];
    }

    return [];
  })();

  useEffect(() => {
    if (!firstRender.current) {
      formik.setValues((oldValues) => {
        const newValues = cloneDeep(oldValues);
        newValues.goods = (isSupplierOption(from) ? (goodsOptions.length > 0 ? [goodsOptions[0]] : []) : goodsOptions) as Good[];
        newValues.legs = [];
        return newValues;
      });
    }

    firstRender.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from?.id, to?.id]);

  const renderAlert = (content: ReactNode) => (
    <div className='col-span-2 self-start flex items-center gap-2 bg-amber-50 border-amber-400 rounded-lg border p-2'>
      <FontAwesomeIcon className='text-amber-400' size='lg' icon={regular('triangle-exclamation')} />
      {content}
    </div>
  );

  useEffect(() => {
    if (!props.fromId && props.productFormik.values.productClass === ProductClass.Food) {
      if (formik.values.goods.length > 0) {
        const ingredientConservation = (formik.values.goods[0] as any)?.ingredient?.conservation;
        const packagingConservation = (formik.values.goods[0] as any)?.packaging?.conservation;

        formik.setFieldValue('conservation', ingredientConservation ?? packagingConservation);
      } else {
        formik.setFieldValue('conservation', undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.goods]);

  return (
    <TaggableFieldsContainer pathPrefix='nodes'>
      <div className='flex flex-col gap-4'>
        <div className='grid grid-cols-2 gap-4 p-4 rounded-xl bg-[#F5F7FA]'>
          <div className='flex flex-col gap-1'>
            <div className='pl-1.5'>From</div>
            <div>
              <Field name='from'>
                {(model: FieldProps<Entity>) => (
                  <SelectV3<Entity>
                    model={model}
                    disabled={props.edit}
                    menuPortalTarget={props.formRef.current}
                    options={fromOptions}
                    renderOptionInMenu={renderFromToOption}
                  />
                )}
              </Field>
            </div>
          </div>
          <div className='flex flex-col gap-1'>
            <div className='pl-1.5'>To</div>
            <div>
              <Field name='to'>
                {(model: FieldProps<Entity>) => (
                  <SelectV3<Entity>
                    model={model}
                    menuPortalTarget={props.formRef.current}
                    options={toOptions}
                    renderOptionInMenu={renderFromToOption}
                  />
                )}
              </Field>
            </div>
          </div>
          {!internal &&
            isIntermediateProductionOption(from, productFormik) &&
            renderAlert(
              <>Please note that only facilities set to “final” can be linked to facilities in the distribution life cycle stage.</>,
            )}
          {from &&
            to &&
            goodsOptions.length === 0 &&
            isSupplierOption(from) &&
            renderAlert(<>All transport links from {from.name} have been added already.</>)}
          {from &&
            to &&
            (() => {
              const storeNode = getStores(productFormik).find(({ id }) => id === from.id);
              const consumptionNode = getConsumptionLocations(productFormik).find(({ id }) => id === to.id);

              return (
                storeNode &&
                consumptionNode &&
                storeNode.facility.location.id !== consumptionNode.facility.location.id &&
                renderAlert(<>Store and Consumption location are in different countries.</>)
              );
            })()}
        </div>
        {from && to && goodsOptions.length > 0 && (
          <div className='flex flex-col gap-4 p-4 rounded-xl bg-[#F5F7FA]'>
            <FieldArray
              name='goods'
              render={(arrayModel) => (
                <>
                  {formik.values.goods.map((good, i) => {
                    const finalProduct = good.id === getFinalProduct(productFormik).id;
                    return (
                      <div key={good.id ?? i} className='flex gap-4'>
                        <div className='flex flex-col gap-1'>
                          {i === 0 && <div className='pl-1.5'>Transported good</div>}
                          <div className='w-80'>
                            <Field name={`${arrayModel.name}.${i}.id`}>
                              {(model: FieldProps<string>) => (
                                <SelectV3<Entity>
                                  disabled={finalProduct || (isSupplierOption(from) && goodsOptions.length === 1)}
                                  model={model}
                                  menuPortalTarget={props.formRef.current}
                                  options={goodsOptions.filter((option) => !formik.values.goods.some((good) => good.id === option.id))}
                                  onActions={{
                                    add: (option: Entity) => arrayModel.replace(i, option),
                                    remove: () => arrayModel.remove(i),
                                    clear: () => arrayModel.replace(i, {}),
                                  }}
                                  convertOptions={{
                                    fromModel: () => (good.id ? good : null),
                                    toModel: (value: Entity) => value?.id,
                                  }}
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                        {/*<div
                          className={cn('flex flex-col gap-1', {
                            invisible: finalProduct,
                          })}
                        >
                          {i === 0 && <div className='pl-1.5'>Quantity</div>}
                          <div className='w-44'>
                            <Field name={`${arrayModel.name}.${i}.amount.value`}>
                              {(model: FieldProps<number>) => (
                                <UnitInputV3 model={model} unit={{ options: good.amount ? [good.amount.unit] : [] }} />
                              )}
                            </Field>
                          </div>
                        </div>*/}
                        {!isSupplierOption(from) && !finalProduct && (
                          <button
                            type='button'
                            className='self-end flex bg-[#F5F7FA] rounded-lg justify-center items-center aspect-square h-8 p-0.5'
                            onClick={arrayModel.handleRemove(i)}
                          >
                            <FontAwesomeIcon className='text-zinc-400 hover:text-red-500' size='lg' icon={regular('trash-can')} />
                          </button>
                        )}
                      </div>
                    );
                  })}
                  {hasTransportedItems(from, productFormik) && !isSupplierOption(from) && formik.values.goods.length < goodsOptions.length && (
                    <Field name={arrayModel.name}>
                      {(model: FieldProps) => (
                        <button
                          type='button'
                          className={cn(
                            'pl-1.5 self-start flex items-center gap-2 hover:text-brand',
                            model.meta.error ? 'text-[#FA4D0A]' : 'text-brandDarkPurple2',
                          )}
                          onClick={arrayModel.handlePush({})}
                        >
                          <FontAwesomeIcon size='lg' icon={light('circle-plus')} />
                          <div className='font-semibold'>Add transported good</div>
                        </button>
                      )}
                    </Field>
                  )}
                </>
              )}
            />
          </div>
        )}
        {hasTransportedItems(from, productFormik) && props.productFormik.values.productClass === ProductClass.Food && (
          <div className='grid grid-cols-2 gap-4 p-4 rounded-xl bg-[#F5F7FA]'>
            <div className='flex flex-col gap-1'>
              <div className='pl-1.5'>Transport conditions</div>
              <div>
                <TaggableField name='conservation'>
                  {(model: FieldProps<Entity>) => (
                    <SelectV3<Entity> model={model} menuPortalTarget={props.formRef.current} options={lists.conservationRequirements} />
                  )}
                </TaggableField>
              </div>
            </div>
          </div>
        )}
        {isSupplierRequired(from, to, props.productFormik) && (
          <Field name='supplier'>
            {(model: FieldProps<SupplierWithSelectedAgreement>) => {
              const toOption = (supplier: Supplier) => ({
                ...supplier,
                selectedAgreement: supplier.agreement ? { ...supplier.agreement, inherited: true } : undefined,
              });
              return (
                <div className='grid grid-cols-2 gap-4 p-4 rounded-xl bg-[#F5F7FA]'>
                  <div className='flex flex-col gap-1'>
                    <div className='pl-1.5'>Transport services supplier</div>
                    <div>
                      <SelectV3<SupplierWithSelectedAgreement>
                        model={model}
                        menuPortalTarget={props.formRef.current}
                        loadOptions={(input, callback) => {
                          setNewSupplierName(input);
                          getSuppliers({
                            contains: input,
                            service: SupplierService.Transport,
                          }).ok(({ suppliers }) => callback(suppliers.map(toOption)));
                        }}
                        renderOptionBadge={(supplier) => (supplier.default ? <DefaultBadge /> : <></>)}
                        menuFooter={
                          !newSupplierForm && (
                            <SelectFooterAddButton onClick={() => setNewSupplierForm(true)} name={newSupplierName} label='new provider' />
                          )
                        }
                      />
                    </div>
                  </div>
                  <AgreementField for='transportSupplier' model={model} />
                  {newSupplierForm && (
                    <NewSupplierForm
                      name={newSupplierName}
                      formRef={props.formRef}
                      requiredServices={[SupplierService.Transport]}
                      onCancel={() => setNewSupplierForm(false)}
                      onCreated={(newSupplier) => {
                        formik.setFieldValue(model.field.name, toOption(newSupplier));
                        setNewSupplierForm(false);
                      }}
                    />
                  )}
                </div>
              );
            }}
          </Field>
        )}
        {canAddLegs(from, productFormik) && (
          <FieldArray
            name='legs'
            render={(arrayModel) => (
              <div className='flex flex-col gap-4 p-4 bg-neutral-50 shadow-[inset_0_0_6px_rgba(0,0,0,0.05)] rounded-lg'>
                <button
                  type='button'
                  className='px-1.5 flex justify-between items-center font-semibold text-base text-neutral-900'
                  onClick={() => setLegsExpanded(!legsExpanded)}
                >
                  <div className='font-semibold'>Add primary data</div>
                  <FontAwesomeIcon icon={legsExpanded ? regular('chevron-up') : regular('chevron-down')} size='lg' />
                </button>
                {legsExpanded && (
                  <>
                    {formik.values.legs.map((leg, i) => (
                      <div key={leg.id} className='flex flex-col gap-4 p-4 bg-white shadow-[0_0_2px_rgba(0,0,0,0.2)] rounded-lg'>
                        <div className='flex justify-between font-semibold text-base text-neutral-900'>
                          <div className='pl-1.5'>Leg {i + 1}</div>
                          <button
                            type='button'
                            className='flex justify-center items-center rounded-sm w-7 aspect-square'
                            onClick={arrayModel.handleRemove(i)}
                          >
                            <FontAwesomeIcon icon={regular('times')} size='lg' />
                          </button>
                        </div>
                        <div className='grid grid-cols-3 gap-4'>
                          <div className='flex flex-col gap-1'>
                            <div className='pl-1.5'>Distance</div>
                            <div>
                              <Field name={`${arrayModel.name}.${i}.distance`}>
                                {(model: FieldProps<number>) => <UnitInputV3 model={model} unit={{ options: [{ id: '', name: 'km' }] }} />}
                              </Field>
                            </div>
                          </div>
                          <div className='flex flex-col gap-1'>
                            <div className='pl-1.5'>Mode of transport</div>
                            <div>
                              <Field name={`${arrayModel.name}.${i}.mode`}>
                                {(model: FieldProps<Entity>) => (
                                  <SelectV3 model={model} menuPortalTarget={props.formRef.current} options={lists.transportTypes} />
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className='flex flex-col gap-1'>
                            <div className='pl-1.5'>Supplier</div>
                            <div>
                              <Field name={`${arrayModel.name}.${i}.supplier`}>
                                {(model: FieldProps<Entity>) => (
                                  <SelectV3
                                    model={model}
                                    menuPortalTarget={props.formRef.current}
                                    loadOptions={(input, callback) => {
                                      getSuppliers({
                                        contains: input,
                                        service: SupplierService.Transport,
                                      }).ok(({ suppliers }) => callback(suppliers));
                                    }}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <Field name={arrayModel.name}>
                      {(model: FieldProps) => (
                        <button
                          type='button'
                          className={cn(
                            'pl-1.5 self-start flex items-center gap-2 hover:text-brand',
                            model.meta.error ? 'text-[#FA4D0A]' : 'text-brandDarkPurple2',
                          )}
                          onClick={arrayModel.handlePush({
                            id: newNodeId(),
                          })}
                        >
                          <FontAwesomeIcon size='lg' icon={light('circle-plus')} />
                          <div className='font-semibold'>Add transport leg</div>
                        </button>
                      )}
                    </Field>
                  </>
                )}
              </div>
            )}
          />
        )}
      </div>
    </TaggableFieldsContainer>
  );
};

export const renderFromToOption = (option: Entity, states: { hovered: boolean; selected: boolean }) => {
  const type = (option as any as GenericNode).type;
  const stage = getLifeCycleForNodeType(type);
  return (
    <div className='p-1'>
      <div
        className={cn('flex items-center gap-2 px-2 py-1.5 rounded', {
          'bg-slate-100': states.hovered || states.selected,
        })}
      >
        <div
          className={cn('flex justify-center items-center rounded-md h-7 aspect-square', {
            'bg-yellow-50 text-yellow-900': type === NodeType.MaterialSupplier,
            'bg-green-50 text-green-900': type === NodeType.IngredientSupplier,
            'bg-lime-50 text-lime-900': type === NodeType.PackagingSupplier,
            'bg-rose-50 text-rose-900': type === NodeType.Production,
            'bg-fuchsia-50 text-fuchsia-900': [NodeType.ProductionWarehouse, NodeType.Warehouse].includes(type),
            'bg-blue-50 text-blue-900': [NodeType.Store, NodeType.FinalDestination].includes(type),
            'bg-cyan-50 text-cyan-900': type === NodeType.Consumption,
          })}
        >
          <FontAwesomeIcon
            icon={(() => {
              switch (type) {
                case NodeType.MaterialSupplier:
                  return duotone('sheet-plastic');
                case NodeType.IngredientSupplier:
                  return duotone('carrot');
                case NodeType.PackagingSupplier:
                  return duotone('box-open');
                case NodeType.Production:
                  return duotone('industry-windows');
                case NodeType.ProductionWarehouse:
                case NodeType.Warehouse:
                  return duotone('warehouse-full');
                case NodeType.Store:
                  return duotone('store');
                case NodeType.FinalDestination:
                  return duotone('flag-checkered');
                case NodeType.Consumption:
                  return duotone('house-user');
                default:
                  throw new Error('unexpected');
              }
            })()}
          />
        </div>
        <div className='truncate flex flex-col'>
          <div className='uppercase text-[10px] leading-none text-zinc-400'>
            {(() => {
              switch (stage) {
                case LifeCycleStage.RawMaterials:
                  return 'Raw Materials';
                case LifeCycleStage.Production:
                  return 'Production';
                case LifeCycleStage.Distribution:
                  return 'Distribution';
                case LifeCycleStage.Use:
                  return 'Use';
                default:
                  throw new Error('unexpected');
              }
            })()}
          </div>
          <div className='truncate text-zinc-900'>{option.name}</div>
        </div>
      </div>
    </div>
  );
};
